import React from 'react'
import AdminHeader from '../../Components/Headers/AdminHeader';
import AdminContainer from './AdminContainer';
import '../../Stylesheets/Admin/Admin.css';

function AdminApp() {
    return (
        <>
            <AdminHeader />
            <AdminContainer />
        </>
    )
}

export default AdminApp