import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';

import * as dashboardActions from '../../Store/Actions/DashboardActions';
import MetaDecorator from '../../Components/Util/MetaDecorator';
import DashboardFooter from '../../Components/Footer/DashboardFooter';
import DashboardHeader from '../../Components/Headers/DashboardHeader';
import DashboardContent from './DashboardContent';
import DashboardDrawersModals from '../../Components/Dashboard/DashboardDrawersModals';
import fixersLogo from '../../Assets/Logos/fixers-logo.png'

function DashboardContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: dashboardActions.GET_USER_DATA_REQUEST })
  }, [dispatch])

  return (
    <>
      <MetaDecorator title="Fixers" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
      <DashboardHeader />
      <DashboardContent />
      <DashboardFooter />
      <DashboardDrawersModals />
    </>
  )
}

export default DashboardContainer