import axios from 'axios'

const endpoint = "https://sissa-fixer.herokuapp.com/";

//get notes
export function getBlogNotesCALL(page) {
	return axios({
		method: 'get',
		url: endpoint + 'notas?page=' + page + '&offset=10&idTipoNota=1',
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get notes by category id
export function getBlogNotesByCategoryIdCALL(page, id) {
	return axios({
		method: 'get',
		url: endpoint + 'notas?page=' + page + '&offset=10&idCategoriaNota=' + id + '&idTipoNota=1',
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get notes by title
export function getBlogNotesByTitleCALL(page, title) {
	return axios({
		method: 'get',
		url: endpoint + 'notas/search?page=' + page + '&offset=10&titulo=' + title,
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get blog categories
export function getBlogCategoriesCALL() {
	return axios({
		method: 'get',
		url: endpoint + 'categoriasNotas',
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//get note info
export function getNoteInfoCALL(id) {
	return axios({
		method: "get",
		url: endpoint + "notas?page=0&offset=10&idNota=" + id + "&idTipoNota=1",
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}