import React, { Component } from 'react'
import { Row, Col } from 'antd'

import idemiaLogo from '../../Assets/Logos/logo_idemia.jpeg'
import honeywellLogo from '../../Assets/Logos/logo_honeywell.jpeg'
import axisLogo from '../../Assets/Logos/logo_axis.jpeg'
import ciscoLogo from '../../Assets/Logos/logo_cisco.jpeg'

export class DashboardTechnologies extends Component {
    render() {
        return (
            <div className='dashboard-technologies-container'>
                <div className='dashboard-technologies-content'>
                <h2>Tecnologías que utilizamos</h2>
                <Row justify='space-around' align='middle'>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <img src={idemiaLogo} alt="dashboard-tech" />
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <img src={honeywellLogo} alt="dashboard-tech" />
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <img src={axisLogo} alt="dashboard-tech" />
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <img src={ciscoLogo} alt="dashboard-tech" />
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}

export default DashboardTechnologies