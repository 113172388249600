import React from 'react'

import MetaDecorator from '../../Components/Util/MetaDecorator';
import LoginHeader from '../../Components/Headers/LoginHeader';
import CheckoutContent from './CheckoutContent';
import DashboardDrawersModals from '../../Components/Dashboard/DashboardDrawersModals';
import fixersLogo from '../../Assets/Logos/fixers-logo.png'

function CheckoutContainer() {
    return (
        <>
            <MetaDecorator title="Fixers" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
            <LoginHeader />
            <CheckoutContent />
            <DashboardDrawersModals />
        </>
    )
}

export default CheckoutContainer