import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Input, Row, Col, Button, notification } from 'antd'
import { EnvironmentFilled, WhatsAppOutlined } from '@ant-design/icons';

import locationIcon from '../../Assets/Icons/ubicacion_fixers.png';
import '../../Stylesheets/Dashboard/DashboardContent.css';
import * as DashboardActions from '../../Store/Actions/DashboardActions';
import * as LoginActions from '../../Store/Actions/LoginActions'

function DashboardHero() {
    const [autocomplete, setAutocomplete] = useState()
    const [inputValue, setInputValue] = useState()
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.LoginReducer.isAuthenticated);

    useEffect(() => {
        if (inputValue) {
            sessionStorage.setItem('address', inputValue)
            dispatch({ type: DashboardActions.CHANGE_VISTA_ADDRESS, vistaAddress: 'confirm' })
            dispatch({ type: DashboardActions.SHOW_ADDRESS_MODAL, showAddressModal: true });
        }
        notification.destroy();
        setTimeout(() => {
            notification.open({
                message: <a href="https://wa.me/+525535524831" rel="noreferrer" target="_blank" style={{ color: 'green' }}>Contáctanos por WhatsApp</a>,
                icon: <a href="https://wa.me/+525535524831" rel="noreferrer" target="_blank"><WhatsAppOutlined style={{ color: 'green' }} /></a>,
                placement: 'bottomRight',
                duration: 0,
            });
        }, 1000);
    }, [inputValue, dispatch, isAuthenticated])

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        setInputValue(autocomplete.getPlace().formatted_address);
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCtAae1VdKZ5h7m1CmyKQldt9A0UniM3Dk',
        libraries: ['places']
    })

    const onShowAddressModal = (showAddressModal) => {
        dispatch({ type: DashboardActions.CHANGE_VISTA_ADDRESS, vistaAddress: 'add-select' })
        dispatch({ type: DashboardActions.SHOW_ADDRESS_MODAL, showAddressModal });
    }

    const onChangeVistaLogin = (vistaLogin) => {
        dispatch({ type: LoginActions.CHANGE_VISTA_LOGIN, vistaLogin })
    }

    return (
        <div className='dashboard-content-hero'>
            <h1>Reparamos <b>cualquier falla</b> en tu casa.</h1>
            <Row justify='space-around' align='middle'>
                <Col span={22}>
                    <div className='hero-input'>
                        {isLoaded &&
                            <Autocomplete
                                onLoad={onLoad}
                                onPlaceChanged={onPlaceChanged}>
                                <Input allowClear prefix={<EnvironmentFilled className='hero-input-icon' />} size="large" placeholder='¿Dónde requieres el servicio?' />
                            </Autocomplete>}
                    </div>
                </Col>
            </Row>
            <Button type='text' size='middle' className='hero-location-btn' onClick={() => { onShowAddressModal(true) }} ><img alt="btn-icon" className='hero-location-btn-icon' src={locationIcon} />Ingresar mi ubicación</Button>
            <div className='hero-login-link'>
                {!isAuthenticated && <Link onClick={() => onChangeVistaLogin('login')} to="/login">Iniciar sesión</Link>}
            </div>
        </div>
    )
}

export default DashboardHero;