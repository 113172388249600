import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { Drawer, Button, Modal, Row, Col } from 'antd'
import { CloseCircleFilled, DeleteFilled, ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'

import FixersLogo from '../../Assets/Logos/fixers-logo.png'

import * as DashboardActions from '../../Store/Actions/DashboardActions';
import DashboardMainMenu from './Menus/DashboardMainMenu';
import CartContainer from './drawers content/CartContainer';
import ServiceDetailContainer from './drawers content/ServiceDetailContainer';
import AddressContainer from './drawers content/address/AddressContainer';
import '../../Stylesheets/Dashboard/DashboardDrawersModals.css';

export class DashboardDrawersModals extends Component {
    state = {
        width: 0,
        height: 0,
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        const { showMenuDrawer,
            showCartDrawer,
            showAddressModal,
            onShowMenuDrawer,
            onShowCartDrawer,
            onShowAddressModal,
            onChangeVistaDashboard,
            showServiceDetail,
            onShowServiceDetail,
            service,
            cart,
            onEmptyCart,
            isAuthenticated,
            vistaAddress,
            onChangeVistaAddress,
            saveAddress,
        } = this.props

        const { width } = this.state;

        const handleClick = () => {
            onShowMenuDrawer(false);
            onChangeVistaDashboard('dashboard');
        }

        const handleTotal = () => {
            const reducer = (accumulator, currentValue) => accumulator + currentValue.price;
            const sum = cart.reduce(reducer, 0);
            return sum;
        }

        const emptyCart = () => {
            onEmptyCart();
            onShowCartDrawer(false);
        }

        const handleChangeVista = vista => {
            onChangeVistaAddress(vista)
        }

        const handleSaveAddress = () => {
            // localStorage.setItem('address', sessionStorage.getItem('address'))
            saveAddress();
            onChangeVistaAddress('add-select');
            onShowAddressModal(false);
        }

        return (
            <div className='dashboard-drawers-modals-container'>
                <Drawer className='menu-drawer-container'
                    title={
                        <Link to="/" onClick={handleClick} >
                            <img src={FixersLogo} alt="menu-logo" className="menu-logo" />
                        </Link>
                    }
                    placement="left"
                    closable={false}
                    onClose={() => { onShowMenuDrawer(false) }}
                    open={showMenuDrawer}
                    width={320}
                    extra={
                        <Button onClick={() => { onShowMenuDrawer(false) }} type="text" icon={<CloseCircleFilled className='close-main-menu-icon' />} />
                    }
                >
                    <DashboardMainMenu />
                </Drawer>
                <Drawer className='cart-drawer-container'
                    title={<h2><b>Tu canasta</b></h2>}
                    placement="right"
                    closable={false}
                    onClose={() => { onShowCartDrawer(false) }}
                    open={showCartDrawer}
                    width={width <= 425 ? width : 425}
                    extra={
                        <Button onClick={() => { onShowCartDrawer(false) }} type="text" icon={<CloseCircleFilled className='close-main-menu-icon' />} />
                    }
                    footer={cart.length > 0 ?
                        <Row className='cart-footer' justify='space-between' align='bottom'>
                            <Col>
                                <Button className='grey-btn' icon={<DeleteFilled />} onClick={emptyCart}>
                                    Eliminar {cart.length} items
                                </Button>
                            </Col>
                            <Col>
                                <Link
                                    to={isAuthenticated ? '/checkout' : '/login'}
                                    onClick={() => { onShowCartDrawer(false) }}
                                >
                                    <Button className='green-btn'>
                                        {`Ir a pagar Subtotal: $${handleTotal()}`}
                                    </Button>
                                </Link>
                            </Col>
                        </Row> : false
                    }
                >
                    <CartContainer />
                </Drawer>
                {this.state.width >= 576 ?
                    <Modal className='address-container'
                        title={vistaAddress === 'confirm' ?
                            <Row align='middle' justify='space-between'>
                                <Col><Button onClick={() => handleChangeVista('add-select')} icon={<ArrowLeftOutlined className='icon-header-blog' />} type='text' /></Col>
                                <Col span={18}>Verifica la ubicación</Col>
                            </Row>
                            : vistaAddress === 'save' ?
                                <Row align='middle' justify='space-between'>
                                    <Col><Button onClick={() => handleChangeVista('confirm')} icon={<ArrowLeftOutlined className='icon-header-blog' />} type='text' /></Col>
                                    <Col span={18}>Agregar dirección</Col>
                                </Row>
                                : <>Agrega o selecciona una dirección</>}
                        open={showAddressModal}
                        onCancel={() => onShowAddressModal(false)}
                        footer={vistaAddress === 'confirm' ? <Button onClick={() => handleChangeVista('save')}>Confirmar dirección</Button> : vistaAddress === 'save' ?
                            <Button onClick={handleSaveAddress}>Guardar dirección</Button> : false
                        }
                        centered
                    >
                        <AddressContainer />
                    </Modal>
                    :
                    <Drawer className='address-container'
                        title={vistaAddress === 'confirm' ?
                            <Row align='middle' justify='space-between'>
                                <Col><Button onClick={() => handleChangeVista('add-select')} icon={<ArrowLeftOutlined className='icon-header-blog' />} type='text' /></Col>
                                <Col span={18}>Verifica la ubicación</Col>
                            </Row>
                            : vistaAddress === 'save' ?
                                <Row align='middle' justify='space-between'>
                                    <Col><Button onClick={() => handleChangeVista('confirm')} icon={<ArrowLeftOutlined className='icon-header-blog' />} type='text' /></Col>
                                    <Col span={18}>Agregar dirección</Col>
                                </Row>
                                : <>Agrega o selecciona una dirección</>}
                        placement='bottom'
                        closable={false}
                        onClose={() => onShowAddressModal(false)}
                        open={showAddressModal}
                        height={550}
                        extra={
                            <Button onClick={() => { onShowAddressModal(false) }} type="text" icon={<CloseCircleFilled className='close-main-menu-icon' />} />
                        }
                        footer={vistaAddress === 'confirm' ? <Button onClick={() => handleChangeVista('save')}>Confirmar dirección</Button> : vistaAddress === 'save' ?
                            <Button onClick={handleSaveAddress}>Guardar dirección</Button> : false
                        }
                    >
                        <AddressContainer />
                    </Drawer>
                }
                <Modal className='service-detail-container'
                    title={<b>{service.title}</b>}
                    open={showServiceDetail}
                    onCancel={() => onShowServiceDetail(false)}
                    footer={false}
                    centered
                    destroyOnClose
                    // forceRender={true}
                    width={1000}
                >
                    <ServiceDetailContainer service={service} />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        showMenuDrawer: state.DashboardReducer.showMenuDrawer,
        showCartDrawer: state.DashboardReducer.showCartDrawer,
        showAddressModal: state.DashboardReducer.showAddressModal,
        showServiceDetail: state.DashboardReducer.showServiceDetail,
        service: state.DashboardReducer.service,
        cart: state.DashboardReducer.cart,
        isAuthenticated: state.LoginReducer.isAuthenticated,
        vistaAddress: state.DashboardReducer.vistaAddress,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowMenuDrawer: (showMenuDrawer) => {
            dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer: showMenuDrawer });
        },
        onShowCartDrawer: (showCartDrawer) => {
            dispatch({ type: "SHOW_CART_DRAWER", showCartDrawer: showCartDrawer });
        },
        onShowServiceDetail: (showServiceDetail) => {
            dispatch({ type: "SHOW_SERVICE_DETAIL", showServiceDetail: showServiceDetail, service: [] });
        },
        onShowAddressModal: (showAddressModal) => {
            dispatch({ type: "SHOW_ADDRESS_MODAL", showAddressModal });
        },
        onChangeVistaDashboard: (vistaDashboard) => {
            dispatch({ type: "CHANGE_VISTA_DASHBOARD", vistaDashboard })
        },
        onEmptyCart: () => {
            dispatch({ type: "EMPTY_CART" })
        },
        onChangeVistaAddress: (vistaAddress) => {
            dispatch({ type: "CHANGE_VISTA_ADDRESS", vistaAddress })
        },
        saveAddress: () => {
            dispatch({ type: DashboardActions.SAVE_ADDRESS, address: sessionStorage.getItem('address') })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDrawersModals);