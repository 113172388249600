import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Row, Col, Card, Button, Spin, List, Empty } from 'antd';
import { SettingOutlined, HistoryOutlined, WhatsAppOutlined } from '@ant-design/icons'

import * as dashboardActions from '../../Store/Actions/DashboardActions'
import logo from '.././../Assets/Logos/fixers-logo.png';
import '../../Stylesheets/User Client/AccountContent.css';

function OrdersContent() {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.DashboardReducer.userData);
    const orders = useSelector(state => state.DashboardReducer.orders)
    const fetchingGetUserData = useSelector(state => state.DashboardReducer.fetchingGetUserData)
    const fetchingGetUserOrders = useSelector(state => state.DashboardReducer.fetchingGetUserOrders)

    useEffect(() => {
        dispatch({ type: dashboardActions.GET_USER_DATA_REQUEST })

    }, [dispatch])

    return (
        <div className='account-container'>
            <Spin spinning={fetchingGetUserData || fetchingGetUserOrders}>
                <Row justify='space-between' align='top' gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <Card
                            className='menu-content'
                            cover={
                                <Row justify='space-around' align='middle'>
                                    <Col span={8}>
                                        <img src={logo} alt="profile-img" />
                                    </Col>
                                    <Col span={10}>
                                        <p><span>Mi perfil</span></p>
                                        <p><b>{userData.name}</b></p>
                                    </Col>
                                </Row>
                            }
                        >
                            <Link to='/account/profile'><Button icon={<SettingOutlined />}>Ajustes de cuenta</Button></Link>
                            <Link to='/account/orders' className='last'><Button icon={<HistoryOutlined />}>Últimas órdenes</Button></Link>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
                        <Card
                            className='orders-content'
                            title="Mis últimos pedidos"
                        >
                            {orders && orders.length > 0 ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={orders}
                                    footer={<>Contacta a un
                                        <a href="https://wa.me/+525535524831" rel="noreferrer" target="_blank" style={{color: 'green'}}> <WhatsAppOutlined />  administrador </a>
                                        si tienes alguna fecha, hora o fixer por agendar. O cualquier duda sobre alguno de tus pedidos.
                                    </>}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<p>Número de pedido: #{item.orderID}</p>}
                                                description={<>
                                                    <p>Pago con {item.paymentMethod}</p>
                                                    <p>Creado el {item.creationDate} </p>
                                                    <p>Fecha asignada: {item.scheduleDate}</p>
                                                    <p>Hora asignada: {item.scheduleHour}</p>
                                                    <p>Total: ${item.price}</p>
                                                    <p>Estado: {item.status}</p>
                                                    <p>Fixer asignado: {item.fixer}</p>
                                                    <p>Dirección: {item.address}</p>
                                                </>}
                                            />
                                        </List.Item>
                                    )}
                                /> :
                                <Empty
                                    description={
                                        <span>
                                            No hay ordenes ni pedidos registrados.
                                        </span>
                                    }
                                />
                            }
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

export default OrdersContent