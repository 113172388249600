import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

import { Spin } from 'antd';
import { LoadingOutlined, TagsFilled, ClockCircleOutlined } from '@ant-design/icons';

import BlogMetaDecorator from '../../Components/Util/BlogMetaDecorator';
import DashboardFooter from '../../Components/Footer/DashboardFooter';
import BlogHeader from '../../Components/Headers/BlogHeader';
import DashboardDrawersModals from '../../Components/Dashboard/DashboardDrawersModals';
import '../../Stylesheets/Blog/NoteContent.css';

function NoteContainer() {
    const dispatch = useDispatch();
    const fetchingGetNoteInfo = useSelector((state) => state.BlogReducer.fetchingGetNoteInfo);
    const noteInfo = useSelector((state) => state.BlogReducer.noteInfo);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let noteId = urlParams.get('note');

        dispatch({ type: "GET_NOTE_INFO_REQUEST", noteId });
    }, [dispatch]);

    return (
        <>
            <BlogHeader />
            <div className="note-container">
                <Spin spinning={fetchingGetNoteInfo} indicator={<LoadingOutlined style={{ fontSize: '4em' }} />}>
                    {Object.keys(noteInfo).length > 0 &&
                        <>
                            <BlogMetaDecorator title={noteInfo.titulo} description={noteInfo.descripcion} img={noteInfo.imagen} />
                            <div className='note-header'>
                                <h2>{noteInfo.titulo}</h2>
                                <p>
                                    <span><ClockCircleOutlined /> {moment(noteInfo.fechaPublicacion, 'YYYY-MM-DD, h:mm:ss a').fromNow()}</span>
                                    <span>
                                        <Link to={`/blog?category=${noteInfo.categoriaNotaByIdCategoriaNota.idCategoriaNota}`}>
                                            <TagsFilled /> {noteInfo.categoriaNotaByIdCategoriaNota.categoriaNota}
                                        </Link>
                                    </span>
                                </p>
                            </div>
                            <div className="note-content">
                                {noteInfo.imagen && <img src={noteInfo.imagen} alt="img-nota" />}
                                <span dangerouslySetInnerHTML={{ __html: noteInfo.texto }} />
                            </div>
                        </>
                    }
                </Spin>
            </div>
            <DashboardFooter />
            <DashboardDrawersModals />
        </>
    )
}

export default NoteContainer