import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal } from 'antd';
import { FormOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function NewCategory({ isVisibleCreate, setIsVisibleCreate }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fetchingCreateCategory = useSelector((state) => state.AdminReducer.fetchingCreateCategory);

    useEffect(() => {
        form.resetFields();
    }, [form, dispatch])


    const onFinish = (values) => {
        if (!fetchingCreateCategory) {
            dispatch({ type: "CREATE_NOTE_CATEGORY_REQUEST", form: values });
            form.resetFields();
            setIsVisibleCreate(!isVisibleCreate);
        }
    };

    const handleOnCancel = () => {
        form.resetFields();
        setIsVisibleCreate(!isVisibleCreate);
    }

    return (
        <Modal title="Crear categoría"
            open={isVisibleCreate}
            onCancel={handleOnCancel}
            width={900}
            footer={[
                <Button key="back" onClick={handleOnCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="createBlogCategory" key="submit" type="primary"
                    loading={fetchingCreateCategory}
                >
                    Crear
                </Button>
            ]}
        >
            <Form
                destroyOnClose
                forceRender={true}
                id="createBlogCategory"
                name="createBlogCategory"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                initialValues={[]}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Nombre de la categoría (Máximo 50 caracteres)" />
                </Form.Item>
                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <TextArea placeholder="Descripción (Máximo 100 caracteres)" maxLength={100} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default NewCategory