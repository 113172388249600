import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Upload, Select, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { Option } = Select;
const { TextArea } = Input;

function NewNote({ isVisibleCreate, setIsVisibleCreate, clearFilters }) {
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const fetchingCreateNote = useSelector((state) => state.AdminReducer.fetchingCreateNote);
    const categories = useSelector((state) => state.AdminReducer.categories);

    useEffect(() => {
        dispatch({ type: "GET_NOTE_CATEGORIES_REQUEST" });
        form.resetFields();
    }, [dispatch, form]);

    const onFinish = (values) => {
        if (!fetchingCreateNote && image) {
            let text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dispatch({ type: "CREATE_NOTE_REQUEST", form: values, image, text });
            setIsVisibleCreate(!isVisibleCreate);
            form.resetFields();
            setImage(null);
            setEditorState(EditorState.createEmpty());
            clearFilters();
        }else{
            message.error('Agrega una imagen')
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false)
                setImage(url)
            });
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };

    const onHandleCancel = () => {
        setIsVisibleCreate(!isVisibleCreate);
        form.resetFields();
        setImage(null);
        setEditorState(EditorState.createEmpty());
    }

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            initialValues={[]}
            title="Crear nota"
            open={isVisibleCreate}
            onCancel={onHandleCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onHandleCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="createNote" key="submit" type="primary"
                    loading={fetchingCreateNote}
                >
                    Crear
                </Button>
            ]}
        >
            <Form
                id="createNote"
                name="createNote"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <Input maxLength={50} prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Titulo de la Nota (Máximo 50 caracteres)" />
                </Form.Item>
                <Form.Item
                    name="categoryId"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <Select
                        placeholder="Categoría"
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {categories.map(category =>
                            <Option value={category.idCategoriaNota} key={category.idCategoriaNota}>
                                {category.categoriaNota}
                            </Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <TextArea placeholder="Descripción (Máximo 100 caracteres)" maxLength={100} />
                </Form.Item>

                <Form.Item
                    name="text"
                >
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor editorHTML"
                        onEditorStateChange={onEditorStateChange}
                    />
                </Form.Item>

            </Form>
            <Upload.Dragger
                name="files"
                onChange={handleChange}
                multiple={false}
                showUploadList={false}
                className='dragger'
                beforeUpload={beforeUpload}
            >
                {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload.Dragger>
        </Modal>
    )
}

export default NewNote