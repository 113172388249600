import React, { Component } from 'react'
import { Row, Col } from 'antd'
import experienceLogo from '../../Assets/Logos/Solutions/experiencia_fixers.png'
import quatilyLogo from '../../Assets/Logos/Solutions/calidad_fixers.png'
import supportLogo from '../../Assets/Logos/Solutions/soporte_fixers.png'
import logo_24_7 from '../../Assets/Logos/Solutions/24_fixers.png'

const solutions = [
    {
        key: 1,
        title: "Experiencia",
        description0: "Prestadores de servicio",
        description1: "con años de experiencia",
        img: experienceLogo,
    },
    {
        key: 2,
        title: "Trabajamos las 24 horas",
        description0: "Soporte 24/7 y",
        description1: "gran oferta de servicios",
        img: logo_24_7,
    },
    {
        key: 3,
        title: "Calidad",
        description0: "Seguimiento del servicio",
        description1: "y control de calidad",
        img: quatilyLogo,
    },
    {
        key: 4,
        title: "¡Solucionamos de todo!",
        description0: "Ya no te tienes que estresar de",
        description1: "fallas o instalaciones.",
        img: supportLogo,
    }
]

export class DashboardSolutions extends Component {
    state = {
        width: 0,
        height: 0,
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        return (
            <div className='dashboard-content-solutions'>
                <h3>TODOS LOS SERVICIOS EN UNA PLATAFORMA</h3>
                <h2>Si de fallas o instalación se trata</h2>
                <h2><b><span>¡Fixers </span>tu mejor opción!</b></h2>

                {solutions[0] && (
                    this.state.width >= 768 ? (
                        <>
                            <Row justify='space-around' align='middle'>
                                {solutions.map((solution) =>
                                    <Col key={solution.key} span={4} className='solutions-logos' style={{maxWidth:'120px'}}>
                                        <img src={solution.img} alt="logo" />
                                    </Col>
                                )}
                            </Row>
                            <Row justify='space-around' align='middle'>
                                {solutions.map((solution) =>
                                    <Col key={solution.key} span={4}>
                                        <h2><b>{solution.title}</b></h2>
                                        <h3>{solution.description0}</h3>
                                        <h3>{solution.description1}</h3>
                                    </Col>
                                )}
                            </Row>
                        </>
                    ) : (
                        solutions.map((solution) =>
                            solution.key !== 4 ? (
                                <div key={solution.key}>
                                    <Row justify="space-around" align='middle' className='solutions-logos'>
                                        <Col span={24}>
                                            <img src={solution.img} alt="logo" />
                                        </Col>
                                    </Row>
                                    <Row justify='space-around' align='middle'>
                                        <Col span={24}>
                                            <h2><b>{solution.title}</b></h2>
                                            <h3>{solution.description0}</h3>
                                            <h3>{solution.description1}</h3>
                                        </Col>
                                    </Row>
                                    <div className='bottom-line'></div>
                                </div>
                            ) : (
                                <div key={solution.key}>
                                    <Row justify="space-around" align='middle' className='solutions-logos'>
                                        <Col span={24}>
                                            <img src={solution.img} alt="logo" />
                                        </Col>
                                    </Row>
                                    <Row justify='space-around' align='middle'>
                                        <Col span={24}>
                                            <h2><b>{solution.title}</b></h2>
                                            <h3>{solution.description0}</h3>
                                            <h3>{solution.description1}</h3>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        )
                    )
                )}

            </div>
        )
    }
}

export default DashboardSolutions