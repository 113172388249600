import React, { Component } from 'react'

import { connect } from 'react-redux';
import { Button, Row, Col } from 'antd';
import { RightOutlined } from '@ant-design/icons';

export class AdminMenu extends Component {
    render() {
        const { onChangeVistaAdmin, onShowMenuDrawer } = this.props;

        const handleClick = (vista) => {
            onChangeVistaAdmin(vista);
            onShowMenuDrawer()
        }

        const logout = () => {
            sessionStorage.clear();
            window.location.reload();
        }

        return (
            <>
                <div className='main-menu-categories-container'>
                    <Button type='link' onClick={() => handleClick('blog')}>
                        <Row justify='space-between'>
                            <Col><h3><b>Blog</b></h3></Col>
                            <Col><RightOutlined className='icon' /></Col>
                        </Row>
                    </Button>
                    <Button type='link' onClick={() => handleClick('content')}>
                        <Row justify='space-between'>
                            <Col><h3><b>Contenido</b></h3></Col>
                            <Col><RightOutlined className='icon' /></Col>
                        </Row>
                    </Button>
                    <Button type='link' onClick={() => handleClick('fixers')}>
                        <Row justify='space-between'>
                            <Col><h3><b>Fixers</b></h3></Col>
                            <Col><RightOutlined className='icon' /></Col>
                        </Row>
                    </Button>
                    <Button type='link' onClick={logout}>
                        <Row justify='space-between'>
                            <Col><h3><b>Cerrar Sesión</b></h3></Col>
                            <Col><RightOutlined className='icon' /></Col>
                        </Row>
                    </Button>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vistaAdmin: state.AdminReducer.vistaAdmin,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeVistaAdmin: (vistaAdmin) => {
            dispatch({ type: "CHANGE_VISTA_ADMIN", vistaAdmin })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu)