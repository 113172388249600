import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, Card, Row, Col, Pagination, Input } from 'antd';
import { EditTwoTone } from '@ant-design/icons';

import * as AdminActions from '../../../Store/Actions/AdminActions';
import UpdateOrder from './UpdateOrder';

const Search = Input.Search;

function FixersList() {
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [isVisibleEdit, setIsVisibleEdit] = useState(false)
    const [orderInfo, setOrderInfo] = useState([])

    const dispatch = useDispatch();
    const fetchingGetOrders = useSelector((state) => state.AdminReducer.fetchingGetOrders);
    const orders = useSelector((state) => state.AdminReducer.orders);

    useEffect(() => {
        dispatch({ type: AdminActions.GET_CLIENTS_ORDERS_REQUEST, page, search });
    }, [dispatch, page, search]);

    const onChangePage = (value) => {
        setPage(value)
    }

    const clearFilters = () => {
        setPage(1);
        setSearch(null);
        setSearchValue(null)
    }

    const handleOnSearchChange = (e) => {
        if (!e.target.value) {
            setSearch(null)
            setSearchValue(null)
        } else {
            setSearchValue(e.target.value)
        }
    }

    return (
        <>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Search
                        value={searchValue}
                        onChange={e => handleOnSearchChange(e)}
                        placeholder="Buscar por número de pedido"
                        onSearch={value => { setSearch(value); setPage(1) }}
                        allowClear
                    />
                </Col>

            </Row>
            <br />
            <Card title={
                <div className="header-list">ORDENES Y PEDIDOS</div>}>
                <List
                    itemLayout="horizontal"
                    loading={fetchingGetOrders}
                    locale={{ emptyText: 'No hay pedidos' }}
                    dataSource={orders.orders}
                    renderItem={item => (
                        <List.Item
                            key={`pedido-0${item.orderID}`}
                            actions={[
                                <span
                                    onClick={() => {
                                        setIsVisibleEdit(!isVisibleEdit);
                                        setOrderInfo(item);
                                    }}
                                ><EditTwoTone className="icon" /></span>
                            ]}
                        >
                            <List.Item.Meta
                                title={`ID pedido #${item.orderID}`}
                                description={`Total: ${item.price}, Estatus: ${item.status}`}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <br />
            <Pagination current={page} total={orders.count} onChange={onChangePage} />
            <br />
            <UpdateOrder
                isVisibleEdit={isVisibleEdit}
                setIsVisibleEdit={setIsVisibleEdit}
                orderInfo={orderInfo}
                setOrderInfo={setOrderInfo}
                clearFilters={clearFilters}
            />
        </>
    )
}

export default FixersList