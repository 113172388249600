import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { CheckCircleTwoTone, ClockCircleTwoTone, WarningTwoTone } from '@ant-design/icons'
import { useStripe } from "@stripe/react-stripe-js";

import * as DashboardActions from '../../Store/Actions/DashboardActions';

function PaymentStatus() {
    const cart = useSelector(state => state.DashboardReducer.cart);
    const [message, setMessage] = useState(null);
    const stripe = useStripe();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("succeeded");
                    dispatch({ type: DashboardActions.CREATE_USER_ORDER_REQUEST, cart })
                    break;
                case "processing":
                    setMessage("processing");
                    break;
                case "requires_payment_method":
                    setMessage("error");
                    break;
                default:
                    setMessage("wrong");
                    break;
            }
        });
    }, [stripe, cart, dispatch])

    return (
        <>
            {message === 'succeeded' &&
                <p><CheckCircleTwoTone twoToneColor="#52c41a" />  Su pago ha sido aprobado y su pedido está siendo revisado</p>
            }
            {message === 'processing' &&
                <p><ClockCircleTwoTone twoToneColor="yellow" />  Su pago está siendo procesado. Una vez que su pedido esté siendo revisado le informaremos vía e-mail.</p>
            }
            {message === 'error' &&
                <p><WarningTwoTone twoToneColor="red" />  Su pago no pudo ser procesado. Por favor intente de nuevo.</p>
            }
        </>
    )
}

export default PaymentStatus