import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Upload, Spin, Select, InputNumber, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined, DollarOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

function EditService({ isVisibleES, setIsVisibleES, serviceInfo, categories, clearFilters }) {
    const [image, setImage] = useState();
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const fetchingEditService = useSelector((state) => state.AdminReducer.fetchingEditService);

    useEffect(() => {
        if (serviceInfo.length !== 0 && serviceInfo.foto !== '') {
            setImage(serviceInfo.foto)
        }
        form.resetFields();
    }, [dispatch, form, serviceInfo]);

    const onFinish = (values) => {
        if (!fetchingEditService && image) {
            let serviceId = serviceInfo.idArticulo;
            dispatch({ type: "EDIT_SERVICE_REQUEST", form: values, serviceId, image });
            setIsVisibleES(!isVisibleES);
            form.resetFields();
            setImage(null)
            clearFilters();
        }
        else {
            message.error('Agrega una imagen.')
        }
    };

    const onCancel = () => {
        setIsVisibleES(!isVisibleES)
        form.resetFields();
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImage(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar servicio ${serviceInfo.articulo}`}
            open={isVisibleES}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="editService" key="submit" type="primary"
                    loading={fetchingEditService}
                >
                    Guardar
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditService} >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='editService'
                    initialValues={serviceInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        name="articulo"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Nombre del servicio (Máximo 50 caracteres)" />
                    </Form.Item>
                    <Form.Item
                        name="precio"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <InputNumber
                            min={10}
                            style={{ width: '100%' }}
                            prefix={<DollarOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Ingrese precio del servicio"
                        />
                    </Form.Item>
                    <Form.Item
                        name="idCategoriaArticulo"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Select
                            placeholder="Categoría"
                            allowClear
                            style={{ width: '100%' }}
                        >
                            {categories.map(category =>
                                <Option value={category.idCategoriaArticulo} key={category.idCategoriaArticulo}>
                                    {category.categoriaArticulo}
                                </Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="descripcion"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <TextArea placeholder="Descripción (Máximo 100 caracteres)" maxLength={100} />
                    </Form.Item>
                </Form>

                <Upload.Dragger
                    name="files"
                    onChange={handleChange}
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                >
                    {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload.Dragger>
            </Spin>
        </Modal>
    )
}

export default EditService