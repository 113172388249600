import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import { Button, Form, Input, Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons'

import loginImg from '../../Assets/Login/FIXERS_.png'
import '../../Stylesheets/Login/Login.css';

function ResetPasswordForm() {
  const [isEnabled, setisEnabled] = useState(false)

  const dispatch = useDispatch();
  const fetchingResetPassword = useSelector((state) => state.LoginReducer.fetchingResetPassword);
  const successResetPassword = useSelector((state) => state.LoginReducer.successResetPassword);

  if (successResetPassword) {
    dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin: 'login' })
  }

  const onFinish = (form) => {
    dispatch({ type: "RESET_PASSWORD_REQUEST", form });
  };

  return (
    <Row className='login-container' justify='space-around'>
      <Col xs={0} sm={0} md={0} lg={8} xl={10}>
        <img src={loginImg} alt="login-img" />
      </Col>
      <Col className='login-form-container' flex="auto">
        <h2>Recuperar contraseña</h2>
        <Form
          name='reset-password-form'
          onFinish={onFinish}
          initialValues={{ remember: false }}
          autoComplete="off"
          id='reset-password-form'
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Escribe tu correo electrónico' }, { type: 'email', message: 'Escribe un correo electrónico válido' }]}
          >
            <Input type="email" allowClear onChange={() => setisEnabled(true)} prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Correo electrónico" />
          </Form.Item>
        </Form>

        <Row style={{ width: '100%', maxWidth: '320px', margin: '0 auto' }} justify="space-between" align='middle'>
          <Col span={24}>
            <Button loading={fetchingResetPassword} className={isEnabled ? 'submit-btn' : 'disabled-btn'} form='reset-password-form' htmlType='submit' disabled={!isEnabled}>Recuperar contraseña</Button>
          </Col>
        </Row>

        <br />
        <p><Link to="/login" onClick={() => dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin: 'login' })} >Inicia sesión aquí</Link></p>
      </Col>
    </Row>
  )
}

export default ResetPasswordForm