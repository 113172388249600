import React from 'react'

import MetaDecorator from '../../Components/Util/MetaDecorator';
import LoginHeader from '../../Components/Headers/LoginHeader';
import OrdersContent from '../../Components/User Client/OrdersContent';
import fixersLogo from '../../Assets/Logos/fixers-logo.png';

function OrdersContainer() {
    return (
        <>
            <MetaDecorator title="Fixers || Mis Ordenes" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
            <LoginHeader />
            <OrdersContent />
        </>
    )
}

export default OrdersContainer