import React from 'react'

import MetaDecorator from '../../Components/Util/MetaDecorator';
import DashboardFooter from '../../Components/Footer/DashboardFooter';
import BlogHeader from '../../Components/Headers/BlogHeader';
import DashboardDrawersModals from '../../Components/Dashboard/DashboardDrawersModals';
import fixersLogo from '../../Assets/Logos/fixers-logo.png';
import BlogContent from './BlogContent';

function BlogContainer() {
    return (
        <>
            <MetaDecorator title="Fixers || Blog" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
            <BlogHeader />
            <BlogContent />
            <DashboardDrawersModals />
            <DashboardFooter />
        </>
    )
}

export default BlogContainer