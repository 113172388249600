import React from 'react'

import { LoginSocialFacebook } from 'reactjs-social-login'
import { Button } from 'antd'

function FacebookButton({ action, actionType }) {

    const onHandleOnResolve = (response) => {
        if (response.data) {
            let values = {}

            if (actionType === 'login') {
                values = {
                    "username": response.data.email,
                    "password": response.data.userID
                }
            } else if (actionType === 'sign-up') {
                values = {
                    "email": response.data.email,
                    "password": response.data.userID,
                    "name": { "firstName": `${response.data.short_name} ${response.data.middle_name}`, "lastName": response.data.last_name },
                    "phone": ""
                }
            }
            action(values)
        }
    }

    return (
        <LoginSocialFacebook
            appId='486671906566938'
            onResolve={(res) => onHandleOnResolve(res)}
            onReject={(err) => {
            }}
        >
            <Button className='facebook'>{actionType === 'login' ? 'Iniciar sesión con Facebook' : 'Registrarse con Facebook'}</Button>
        </LoginSocialFacebook>
    )
}

export default FacebookButton