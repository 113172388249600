import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { hydrate, render } from "react-dom";

import countapi from 'countapi-js';

import DashboardContainer from './Dashboard/DashboardContainer';
import ServiceByCategory from '../Components/Services/ServiceByCategory';
import ServiceSearchResults from '../Components/Services/ServiceSearchResults';
import LoginApp from './Login/LoginApp';
import BlogContainer from './Blog/BlogContainer';
import NoteContainer from './Blog/NoteContainer';
import AdminApp from './Admin/AdminApp';
import Alerta from '../Components/Alerta';
import CheckoutContainer from './Checkout/CheckoutContainer';
import CheckoutSuccessContainer from './Checkout/CheckoutSuccessContainer';
import ProfileContainer from './User Client/ProfileContainer';
import OrdersContainer from './User Client/OrdersContainer';
import ScrollToTop from '../Hooks/ScrollToTop';

const myNamespace = "op.painani.mx";
const MY_KEY = "5e1f7d77-1332-4877-802f-fa66c7089a62";

class App extends Component {
  hanldeCallbackResponse(response) {
    console.log('jwt: ', response.credential);
  }

  componentDidMount() {
    // /*global google*/
    // google.accounts.id.initialize({
    //   client_id: '722758664014-dmlfjoui2pu12790er1kiauibonnsp49.apps.googleusercontent.com',
    //   callback: this.hanldeCallbackResponse
    // });

    // google.accounts.id.renderButton(
    //   document.getElementsByClassName('google'),
    //   { theme: "outline", size: "large" }
    // );

    countapi.visits(myNamespace, MY_KEY).then((result) => {
      sessionStorage.setItem('visits', result.value)
    });
  }

  render() {
    const isAuthenticatedSession = sessionStorage.getItem('isAuthenticated');

    return (
      <>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={DashboardContainer} />
            <Route exact path="/category" component={ServiceByCategory} />
            <Route exact path="/login" component={LoginApp} />
            <Route exact path="/search" component={ServiceSearchResults} />
            <Route exact path="/blog" component={BlogContainer} />
            <Route exact path="/note" component={NoteContainer} />
            <Route exact path="/checkout" component={CheckoutContainer} />
            <Route exact path="/checkout-success" component={CheckoutSuccessContainer} />
            <Route exact path="/account/profile" component={ProfileContainer} />
            <Route exact path="/account/orders" component={OrdersContainer} />
            <PrivateRoute isAuthenticated={isAuthenticatedSession} path="/admin" component={AdminApp} />
          </Switch>
        </Router>
        <Alerta />
      </>
    )
  }

}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(App, rootElement);
} else {
  render(App, rootElement);
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated
        ? (<Component {...props} />)
        : (<Redirect to='/login' />)
    )}
  />
);

const mapStateToProps = state => {
  return {
    categoria: state.AdminReducer.categoria,
    cleanTitle: state.AdminReducer.cleanTitle,
    notaInfo: state.AdminReducer.notaInfo,
    isAuthenticated: state.LoginReducer.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRequestNota: (tituloPath) => {
      dispatch({ type: "GET_NOTA_ESPECIFICA_REQUEST", idNota: tituloPath });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
