import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Card, Button, List, Spin } from 'antd';

import Payment from '../../Components/PaymentMethods/Payment'
import * as dashboardActions from '../../Store/Actions/DashboardActions'
import '../../Stylesheets/Checkout/CheckoutContent.css';

function CheckoutContent() {
    const address = localStorage.getItem('address')

    const dispatch = useDispatch();
    const userData = useSelector(state => state.DashboardReducer.userData);
    const cart = useSelector(state => state.DashboardReducer.cart);
    const vistaAddress = useSelector(state => state.DashboardReducer.vistaAddress);
    const fetchingCreatePaymentIntent = useSelector(state => state.DashboardReducer.fetchingCreatePaymentIntent);

    useEffect(() => {
        dispatch({ type: dashboardActions.GET_USER_DATA_REQUEST })
    }, [vistaAddress, dispatch])

    const onShowAddressModal = () => {
        dispatch({ type: "SHOW_ADDRESS_MODAL", showAddressModal: true });
    }

    const handleTotal = () => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue.price;
        const sum = cart.reduce(reducer, 0);
        return sum;
    }

    return (
        <div className='checkout-container'>
            <Spin spinning={fetchingCreatePaymentIntent}>
                <Row justify='space-around'>
                    <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Card title="Dirección de entrega"
                            className='address-container'
                            extra={<Button onClick={onShowAddressModal} type='link'>{address ? 'cambiar' : '+ agregar'}</Button>}
                        >
                            <p><b>{address ? address : 'Agrega una dirección para completar tu pago.'}</b></p>
                        </Card>
                        <br />
                        <Card title="Lista de servicios">
                            <List
                                className='services-list'
                                itemLayout="horizontal"
                                dataSource={cart}
                                renderItem={item => (
                                    <List.Item
                                        key={item.index + item.key}>
                                        <List.Item.Meta
                                            avatar={<img alt='checkout-service-img' src={item.img} />}
                                            title={item.title}
                                            description={`$${item.price}`}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                        <br />
                    </Col>
                    <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 10 }}>
                        <div className='summary-container'>
                            <Card title="Resumen" >
                                {cart.map(item =>
                                    <div key={"summary-0" + item.index + item.title}>
                                        <Row align='middle' justify='space-between'>
                                            <Col>
                                                <span>{item.title}</span>
                                            </Col>
                                            <Col>
                                                <span>${item.price}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <hr />
                                <Row align='middle' justify='space-between'>
                                    <Col>
                                        <b>Total:</b>
                                    </Col>
                                    <Col>
                                        <span>${handleTotal()}</span>
                                    </Col>
                                </Row>

                            </Card>
                            <br />
                            <Card title="Pago" className='payment-method'>
                                <Payment cart={cart} userID={userData.idUsuario} />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

export default CheckoutContent