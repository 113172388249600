import * as loginActions from '../Actions/LoginActions'

const initialState = {
    //login
    fetchingLogin: false,
    // tokenLogin: null,
    errorLogin: false,
    // usuario: sessionStorage.getItem('userInfo'),
    isAuthenticated: sessionStorage.getItem('isAuthenticated') || false,
    // selectedPerfil: sessionStorage.getItem('perfilUsuario'),
    role: sessionStorage.getItem('role') || '',

    //signup
    fetchingSignup: false,
    errorSignUp: false,
    successSignUp: false,

    //modal
    showModalResetPassword: false,
    showModalSignUp: false,

    //reset password
    fetchingResetPassword: false,
    errorResetPassword: false,
    successResetPassword: false,

    vistaLogin: sessionStorage.getItem('vistaLogin'),

};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {

        //login
        case loginActions.LOGIN_REQUEST:
            return { ...state, fetchingLogin: true, errorLogin: false };
        case loginActions.LOGIN_SUCCESS:
            return { ...state, fetchingLogin: false, isAuthenticated: true, errorLogin: false, role: action.role };
        case loginActions.LOGIN_FAILURE:
            return { ...state, fetchingLogin: false, errorLogin: true };

        //sign up
        case loginActions.SIGN_UP_CLIENT_REQUEST:
            return { ...state, fetchingSignup: true, errorSignUp: false, successSignUp: false };
        case loginActions.SIGN_UP_CLIENT_SUCCESS:
            return { ...state, fetchingSignup: false, successSignUp: true };
        case loginActions.SIGN_UP_CLIENT_FAILURE:
            return { ...state, fetchingSignup: false, errorSignUp: true };

        //reset password
        case loginActions.RESET_PASSWORD_REQUEST:
            return { ...state, fetchingResetPassword: true, errorResetPassword: false, successResetPassword: false };
        case loginActions.RESET_PASSWORD_SUCCESS:
            return { ...state, fetchingResetPassword: false, successResetPassword: true };
        case loginActions.RESET_PASSWORD_FAILURE:
            return { ...state, fetchingResetPassword: false, errorResetPassword: true };

        //change vista
        case loginActions.CHANGE_VISTA_LOGIN:
            sessionStorage.setItem('vistaLogin', action.vistaLogin)
            return { ...state, vistaLogin: action.vistaLogin, };

        default:
            return state;
    }
};

export default LoginReducer;