import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import { Row, Col, Button } from 'antd';

function ServiceDetailContainer({ service }) {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.LoginReducer.isAuthenticated)

    const addAndClose = () => {
        dispatch({ type: "ADD_TO_CART", item: service });
        dispatch({ type: "SHOW_SERVICE_DETAIL", showServiceDetail: false, service: [] });
    }

    return (
        <div className='service-detail-content'>
            <Row justify='space-between' align='top'>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} >
                    <img src={service.img} alt="service-detail-img" />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }} >
                    <div className='service-detail'>
                        <p><b>${service.price}</b></p>
                        <p>{service.description}</p>
                    </div>
                </Col>
            </Row>
            <Row justify='space-between' align='bottom'>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <span>Subtotal: <b>${service.price}</b></span>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Button onClick={addAndClose} className='grey-btn'>
                        Agregar y seguir comprando
                    </Button>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Link to={isAuthenticated ? `/checkout` : `/login`}>
                        <Button className='green-btn' onClick={addAndClose}>
                            Agregar y agendar
                        </Button>
                    </Link>
                </Col>
            </Row>
        </div>
    )
}

export default ServiceDetailContainer