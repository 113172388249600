import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Spin } from 'antd';
import { FormOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function EditCategory({ isVisibleEdit, setIsVisibleEdit, categoryInfo }) {
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const fetchingEditCategory = useSelector((state) => state.AdminReducer.fetchingEditCategory);

    useEffect(() => {
        form.resetFields();
    }, [categoryInfo, form, dispatch])


    const onFinish = (form) => {
        let id = categoryInfo.idCategoriaNota;
        dispatch({ type: "EDIT_NOTE_CATEGORY_REQUEST", form, id });
        setIsVisibleEdit(!isVisibleEdit)
        form.resetFields();
    };

    const onCancel = () => {
        form.resetFields();
        setIsVisibleEdit(!isVisibleEdit)
    }

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar categoría "${categoryInfo.categoriaNota}"`}
            open={isVisibleEdit}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="editBlogCategory" key="submit" type="primary"
                    loading={fetchingEditCategory}
                >
                    Guardar
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditCategory} >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='editBlogCategory'
                    initialValues={categoryInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        name="categoriaNota"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Nombre de la categoría (Máximo 50 caracteres)" />
                    </Form.Item>
                    <Form.Item
                        name="descripcion"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <TextArea placeholder="Descripción (Máximo 100 caracteres)" maxLength={100} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default EditCategory