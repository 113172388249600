import * as dashboardActions from '../Actions/DashboardActions'

import { notification } from 'antd';
import { useLocalStorage } from '../../Hooks/useLocalStorage';

const initialState = {
    //vista
    vistaDashboard: sessionStorage.getItem('vistaDashboard'),
    vistaAddress: 'add-select',

    //Dahsboard drawers and modals
    showMenuDrawer: false,
    showCartDrawer: false,
    ShowSearchDrawer: false,
    showAddressModal: false,
    showServiceDetail: false,

    //categories
    dashboardCategoryInfo: sessionStorage.getItem('dashboardCategoryInfo'),
    dashboardCategories: [],
    fetchingDashboardCategories: false,

    //services
    servicesBycategoryId: [],
    fetchingServicesBycategoryId: [],
    totalServices: null,

    //brands
    brandsBycategoryId: [],
    fetchingBrandsBycategoryId: [],

    //service search by item
    servicesSearchResults: [],
    fetchingServicesSearchResults: false,
    totalServicesSearchResults: null,

    //top services
    topServices: [],
    fetchingGetTopServices: false,
    totalTopServices: null,

    //cart
    cart: JSON.parse(localStorage.getItem('CART')) || [],

    //service detail
    service: [],

    //account-profile
    userData: [],
    fetchingGetUserData: false,
    fetchingEditUserData: false,

    //account-orders
    orders: [],
    fetchingGetUserOrders: false,
    fetchingCreateUserOrder: false,

    //checkout
    fetchingCreateCheckoutSession: false,

    //stripe
    fetchingCreatePaymentIntent: false,
    clientSecret: null,
    
    address: localStorage.getItem('address') ? localStorage.getItem('address') : null
}

const DashboardReducer = (state = initialState, action) => {
    const { saveItem: saveCartItem } = useLocalStorage('CART', initialState);

    switch (action.type) {
        //change  dashboard vista
        case dashboardActions.CHANGE_VISTA_DASHBOARD:
            sessionStorage.setItem('dashboardCategoryInfo', JSON.stringify(action.dashboardCategoryInfo));
            return { ...state };
        case dashboardActions.CHANGE_VISTA_ADDRESS:
            return { ...state, vistaAddress: action.vistaAddress };
        case dashboardActions.SAVE_ADDRESS:
            localStorage.setItem('address', sessionStorage.getItem('address'));
            return { ...state, address: action.address};

        //drawers
        case dashboardActions.SHOW_MENU_DRAWER:
            return { ...state, showMenuDrawer: action.showMenuDrawer };
        case dashboardActions.SHOW_CART_DRAWER:
            return { ...state, showCartDrawer: action.showCartDrawer };
        case dashboardActions.SHOW_SEARCH_DRAWER:
            return { ...state, showSearchDrawer: action.showSearchDrawer };
        case dashboardActions.SHOW_ADDRESS_MODAL:
            return { ...state, showAddressModal: action.showAddressModal };
        case dashboardActions.SHOW_SERVICE_DETAIL:
            return { ...state, showServiceDetail: action.showServiceDetail, service: action.service };

        //categories
        case dashboardActions.GET_DASHBOARD_CATEGORIES_REQUEST:
            return { ...state, fetchingDashboardCategories: true };
        case dashboardActions.GET_DASHBOARD_CATEGORIES_SUCCESS:
            return { ...state, fetchingDashboardCategories: false, dashboardCategories: action.dashboardCategories };
        case dashboardActions.GET_DASHBOARD_CATEGORIES_FAILURE:
            return { ...state, fetchingDashboardCategories: false };

        //services by categorie id
        case dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_REQUEST:
            return { ...state, fetchingServicesBycategoryId: true };
        case dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_SUCCESS:
            return { ...state, servicesBycategoryId: action.servicesBycategoryId, totalServices: action.totalServices, fetchingServicesBycategoryId: false };
        case dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_FAILURE:
            return { ...state, fetchingServicesBycategoryId: false };

        //brands by categorie id
        case dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_REQUEST:
            return { ...state, fetchingBrandsBycategoryId: true };
        case dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_SUCCESS:
            return { ...state, brandsBycategoryId: action.brandsBycategoryId, fetchingBrandsBycategoryId: false };
        case dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_FAILURE:
            return { ...state, fetchingBrandsBycategoryId: false };

        //services search by item
        case dashboardActions.GET_SERVICES_SEARCH_REQUEST:
            return { ...state, fetchingServicesSearchResults: true };
        case dashboardActions.GET_SERVICES_SEARCH_SUCCESS:
            return { ...state, servicesSearchResults: action.servicesSearchResults, totalServicesSearchResults: action.totalServicesSearchResults, fetchingServicesSearchResults: false };
        case dashboardActions.GET_SERVICES_SEARCH_FAILURE:
            return { ...state, fetchingServicesSearchResults: false };

        //get top services
        case dashboardActions.GET_TOP_SERVICES_REQUEST:
            return { ...state, fetchingGetTopServices: true };
        case dashboardActions.GET_TOP_SERVICES_SUCCESS:
            return { ...state, topServices: action.topServices, totalTopServices: action.totalTopServices, fetchingGetTopServices: false };
        case dashboardActions.GET_TOP_SERVICES_FAILURE:
            return { ...state, fetchingGetTopServices: false };

        //cart
        case dashboardActions.ADD_TO_CART:
            let exists = state.cart.find(item => item.id === action.item.id)
            if (!exists) {
                notification['success']({
                    message: `Se agregó "${action.item.title}" a tu carrito.`,
                    placement: 'top',
                    duration: 3
                });
                saveCartItem([...state.cart, action.item])
                return { ...state, cart: [...state.cart, action.item] };
            } else {
                notification['warning']({
                    message: `"${action.item.title}" ya se encuentra en tu carrito.`,
                    placement: 'top',
                    duration: 3
                });
                return { ...state };
            }
        case dashboardActions.REMOVE_FROM_CART:
            notification['success']({
                message: `Se eliminó "${action.item.title}" de tu carrito.`,
                placement: 'top',
                duration: 3
            });
            saveCartItem(state.cart.filter(items => items.id !== action.item.id))
            return { ...state, cart: state.cart.filter(items => items.id !== action.item.id) };
        case dashboardActions.EMPTY_CART:
            notification['success']({
                message: `Se eliminaron todos los servicios de tu carrito.`,
                placement: 'top',
                duration: 3
            });
            saveCartItem([])
            return { ...state, cart: [] };

        //profile-account
        case dashboardActions.GET_USER_DATA_REQUEST:
            return { ...state, fetchingGetUserData: true };
        case dashboardActions.GET_USER_DATA_SUCCESS:
            return { ...state, fetchingGetUserData: false, userData: action.userData };
        case dashboardActions.GET_USER_DATA_FAILURE:
            return { ...state, fetchingGetUserData: false };
        case dashboardActions.EDIT_USER_DATA_REQUEST:
            return { ...state, fetchingEditUserData: true };
        case dashboardActions.EDIT_USER_DATA_SUCCESS:
            return { ...state, fetchingEditUserData: false, userData: action.userData };
        case dashboardActions.EDIT_USER_DATA_FAILURE:
            return { ...state, fetchingEditUserData: false };

        //profile-orders
        case dashboardActions.GET_USER_ORDERS_REQUEST:
            return { ...state, fetchingGetUserOrders: true };
        case dashboardActions.GET_USER_ORDERS_SUCCESS:
            return { ...state, fetchingGetUserOrders: false, orders: action.orders };
        case dashboardActions.GET_USER_ORDERS_FAILURE:
            return { ...state, fetchingGetUserOrders: false };

        case dashboardActions.CREATE_USER_ORDER_REQUEST:
            return { ...state, fetchingCreateUserOrder: true };
        case dashboardActions.CREATE_USER_ORDER_SUCCESS:
            return { ...state, fetchingCreateUserOrder: false };
        case dashboardActions.CREATE_USER_ORDER_FAILURE:
            return { ...state, fetchingCreateUserOrder: false };

        //checkout stripe
        case dashboardActions.CREATE_PAYMENT_INTENT_REQUEST:
            return { ...state, fetchingCreatePaymentIntent: true }
        case dashboardActions.CREATE_PAYMENT_INTENT_SUCCESS:
            return { ...state, fetchingCreatePaymentIntent: false, clientSecret: action.clientSecret };
        case dashboardActions.CREATE_PAYMENT_INTENT_FAILURE:
            return { ...state, fetchingCreatePaymentIntent: false }

        default:
            return state;
    }
};

export default DashboardReducer;