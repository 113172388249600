import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Card, Col, Row } from 'antd';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';

import MetaDecorator from '../../Components/Util/MetaDecorator';
import LoginHeader from '../../Components/Headers/LoginHeader';
import DashboardDrawersModals from '../../Components/Dashboard/DashboardDrawersModals';
import fixersLogo from '../../Assets/Logos/fixers-logo.png';
import PaymentStatus from '../../Components/PaymentMethods/PaymentStatus';
import '../../Stylesheets/Checkout/CheckoutContent.css';

const PUBLIC_KEY = 'pk_live_51MGtd3EfiCkMbzAU3GvsFQ799eqhOt5bPFfrWoE8zB8epuBCd5W0b6U52WOW4Z3X7V5gEFh5TD3Xx2BWvEiVUzND00iJDTJJYc'
const stripePromise = loadStripe(PUBLIC_KEY);

function CheckoutSuccessContainer() {
  const dispatch = useDispatch();
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  const options = {
    clientSecret,
  };

  const handleClick = () => {
    dispatch({ type: "EMPTY_CART" })
  }

  return (
    <>
      <MetaDecorator title="Fixers" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
      <LoginHeader />
      <div className='checkout-success-container'>
        <Row justify='space-between' align='stretch' gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card title="Estatus del pedido" bordered={false}>
              <Elements options={options} stripe={stripePromise}>
                <PaymentStatus />
              </Elements>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card title="Ver pedidos" bordered={false}>
              Para obtener más detalle ve a <Link to='/account/orders' onClick={handleClick}>mis pedidos</Link>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card title="Volver al inicio" bordered={false}>
              <Link to='/' onClick={handleClick}>Volver a la pantalla principal</Link>
            </Card>
          </Col>
        </Row>
      </div>
      <DashboardDrawersModals />
    </>
  )
}

export default CheckoutSuccessContainer