import { useState, useEffect } from 'react'
import axios from 'axios';

const useGoogleAddress = (address) => {
    const [map, setMap] = useState({});
    const API = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCtAae1VdKZ5h7m1CmyKQldt9A0UniM3Dk`;

    useEffect(() => {
        async function fetchAddress() {
            const response = await axios(API);
            if (response.status === 200)
                setMap(response.data.results[0].geometry.location)
        }
        fetchAddress();
    }, [API])

    return map
};

export { useGoogleAddress }