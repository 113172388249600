function useLocalStorage(itemName) {
    const saveItem = (newItem) => {
        localStorage.setItem(itemName, JSON.stringify(newItem));
    }

    return {
        saveItem,
    }
}

export { useLocalStorage };