import React from 'react'

import DashboardHero from '../../Components/Dashboard/DashboardHero'
import DashboardTechnologies from '../../Components/Dashboard/DashboardTechnologies'
import DashboardCategories from '../../Components/Dashboard/DashboardCategories'
import DashboardSolutions from '../../Components/Dashboard/DashboardSolutions'
import DashboardTopServices from '../../Components/Dashboard/DashboardTopServices'
// import WhatsAppAffix from '../../Components/Dashboard/WhatsAppAffix'

import '../../Stylesheets/Dashboard/DashboardContent.css'

function DashboardContent() {
    return (
        <div className='dashboard-content-container'>
            <DashboardHero />
            <DashboardTechnologies />
            <DashboardCategories />
            <DashboardSolutions />
            <DashboardTopServices />
            {/* <WhatsAppAffix /> */}
        </div>
    )
}

export default DashboardContent