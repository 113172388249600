import React from 'react'
import { useDispatch } from 'react-redux';

import { Button, Row, Col } from 'antd';
import { EditFilled } from '@ant-design/icons'

import Map from '../../../Maps/Map'
import * as DashboardActions from '../../../../Store/Actions/DashboardActions'
import { useGoogleAddress } from '../../../../Hooks/useGoogleAddress';

function ConfirmAddress() {
    const address = sessionStorage.getItem('address')
    const location = useGoogleAddress(address)
    const dispatch = useDispatch();

    const editAddress = () => {
        dispatch({ type: DashboardActions.CHANGE_VISTA_ADDRESS, vistaAddress: 'add-select' })
    }

    return (
        <div className='comfirm-location-content'>
            <Map data={location} />
            <br />
            <Row justify='space-between'>
                <Col span={22}>{address}</Col>
                <Col span={2}>
                    <Button onClick={editAddress} type='text' icon={<EditFilled />} />
                </Col>
            </Row>
        </div>
    )
}

export default ConfirmAddress