import * as blogActions from '../Actions/BlogActions'

const initialState = {
    //notes
    notes: [],
    fetchingGetNotes: false,

    //note
    noteInfo: [],
    fetchingGetNoteInfo: false,

    //blog categories
    categories: [],
    fetchingGetCategories: false,

}

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        //GET NOTES
        case blogActions.GET_BLOG_NOTES_REQUEST:
            return { ...state, fetchingGetNotes: true };
        case blogActions.GET_BLOG_NOTES_SUCCESS:
            return { ...state, fetchingGetNotes: false, notes: action.notes };
        case blogActions.GET_BLOG_NOTES_FAILURE:
            return { ...state, fetchingGetNotes: false };

        //GET NOTE INFO
        case blogActions.GET_NOTE_INFO_REQUEST:
            return { ...state, fetchingGetNoteInfo: true };
        case blogActions.GET_NOTE_INFO_SUCCESS:
            return { ...state, noteInfo: action.noteInfo, fetchingGetNoteInfo: false };
        case blogActions.GET_NOTE_INFO_FAILURE:
            return { ...state, fetchingGetNoteInfo: false };

        //GET BLOG CATEGORIES
        case blogActions.GET_BLOG_CATEGORIES_REQUEST:
            return { ...state, fetchingGetCategories: true };
        case blogActions.GET_BLOG_CATEGORIES_SUCCESS:
            return { ...state, fetchingGetCategories: false, categories: action.categories };
        case blogActions.GET_BLOG_CATEGORIES_FAILURE:
            return { ...state, fetchingGetCategories: false };

        default:
            return state;
    }
};

export default BlogReducer;