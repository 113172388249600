import React, { useState } from 'react'

import { Col, Row, Button, Drawer } from 'antd'
import { MenuOutlined, CloseCircleFilled } from '@ant-design/icons';

import AdminMenu from '../../Components/Admin/AdminMenu';
import FixersLogo from '../../Assets/Logos/logo_fixers_color.png'
import '../../Stylesheets/Admin/Admin.css';

function AdminHeader() {
  const [isVisible, setIsVisible] = useState(false)

  const onShowMenuDrawer = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <Row align='middle' justify='space-between' className='header' >
        <Col span={2}>
          <Button type="text"
            onClick={onShowMenuDrawer}
            icon={<MenuOutlined className='icon' />} />
        </Col>
        <Col flex="auto" type="flex" align="middle">
          <img src={FixersLogo} alt="logo" />
        </Col>
      </Row>

      <Drawer
        className='menu-drawer-container'
        title="Administrador"
        placement="left"
        closable={false}
        onClose={onShowMenuDrawer}
        open={isVisible}
        width={320}
        extra={
          <Button onClick={onShowMenuDrawer} type="text" icon={<CloseCircleFilled className='close-main-menu-icon' />} />
        }
      >
        <AdminMenu onShowMenuDrawer={onShowMenuDrawer} />
      </Drawer>
    </>
  )
}

export default AdminHeader