import React, { Component } from 'react'
import { connect } from 'react-redux'

import { List, Button, Card, Row, Col, Modal, Avatar } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import NewCategory from './NewCategory.jsx';
import EditCategory from './EditCategory';

const confirm = Modal.confirm;

export class ServiceCategoriesList extends Component {
    state = {
        isVisibleCC: false,
        isVisibleEC: false,
        categoryInfo: [],
    }

    componentDidMount() {
        this.props.onRequestGetServiceCategories();
    }

    render() {
        const { isVisibleEC, isVisibleCC, categoryInfo } = this.state;

        const {
            serviceCategories,
            fetchingGetServiceCategories,
            fetchingCreateServiceCategory,
            fetchingEditServiceCategory,
            onRequestDeleteServiceCategory
        } = this.props;

        const toggleNewCategoryModal = () => {
            this.setState({
                isVisibleCC: !isVisibleCC
            })
        }

        const toggleEditCategoryModal = (item) => {
            this.setState({
                isVisibleEC: !isVisibleEC,
                categoryInfo: item
            })
        }

        const showDeleteConfirm = (id, category) => {
            confirm({
                title: '¿Estás seguro que deseas eliminar la categoría  "' + category + '"?',
                content: '',
                okText: 'Sí',
                okType: 'primary',
                cancelText: 'No',
                onOk() {
                    onRequestDeleteServiceCategory(id);
                },
                onCancel() {
                },
            });
        };

        return (
            <>
                <Row type="flex" justify="end" align='middle'>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                        <Button style={{ width: '100%' }} type="primary" className="login-btn"
                            onClick={toggleNewCategoryModal}
                        >Nueva categoría</Button>
                    </Col>
                </Row>
                <br />
                <Card title={
                    <div className="header-list">CATEGORIAS DE SERVICIOS</div>}>
                    <List
                        itemLayout="horizontal"
                        loading={fetchingGetServiceCategories || fetchingCreateServiceCategory || fetchingEditServiceCategory}
                        locale={{ emptyText: 'Sin datos' }}
                        dataSource={serviceCategories}
                        renderItem={item => (
                            <List.Item
                                key={`service-category-0${item.idCategoriaArticulo}`}
                                actions={[
                                    <span
                                        onClick={() => showDeleteConfirm(item.idCategoriaArticulo, item.categoriaArticulo)}
                                    ><DeleteTwoTone className="icon" /></span>,
                                    <span
                                        onClick={() => toggleEditCategoryModal(item)}
                                    >
                                        <EditTwoTone className="icon" /></span>
                                ]}
                            >
                                <List.Item.Meta
                                    title={item.categoriaArticulo}
                                    avatar={<Avatar src={item.foto} />}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
                <NewCategory toggleNewCategoryModal={toggleNewCategoryModal} isVisibleCC={isVisibleCC} />
                <EditCategory toggleEditCategoryModal={toggleEditCategoryModal} isVisibleEC={isVisibleEC} categoryInfo={categoryInfo} />
            </>
        )
    }
}

const mapStateToProps = state => {

    return {
        serviceCategories: state.AdminReducer.serviceCategories,
        fetchingGetServiceCategories: state.AdminReducer.fetchingGetServiceCategories,
        fetchingCreateServiceCategory: state.AdminReducer.fetchingCreateServiceCategory,
        fetchingEditServiceCategory: state.AdminReducer.fetchingEditServiceCategory
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestGetServiceCategories: () => {
            dispatch({ type: "GET_SERVICE_CATEGORIES_REQUEST" });
        },
        onRequestDeleteServiceCategory: (id) => {
            dispatch({ type: "DELETE_SERVICE_CATEGORY_REQUEST", id });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCategoriesList)