//vista
export const CHANGE_VISTA_ADMIN = 'CHANGE_VISTA_ADMIN';

//service categories
export const GET_SERVICE_CATEGORIES_REQUEST = "GET_SERVICE_CATEGORIES_REQUEST";
export const GET_SERVICE_CATEGORIES_SUCCESS = "GET_SERVICE_CATEGORIES_SUCCESS";
export const GET_SERVICE_CATEGORIES_FAILURE = "GET_SERVICE_CATEGORIES_FAILURE";
//create service category
export const CREATE_SERVICE_CATEGORY_REQUEST = "CREATE_SERVICE_CATEGORY_REQUEST";
export const CREATE_SERVICE_CATEGORY_SUCCESS = "CREATE_SERVICE_CATEGORY_SUCCESS";
export const CREATE_SERVICE_CATEGORY_FAILURE = "CREATE_SERVICE_CATEGORY_FAILURE";
//edit service category
export const EDIT_SERVICE_CATEGORY_REQUEST = "EDIT_SERVICE_CATEGORY_REQUEST";
export const EDIT_SERVICE_CATEGORY_SUCCESS = "EDIT_SERVICE_CATEGORY_SUCCESS";
export const EDIT_SERVICE_CATEGORY_FAILURE = "EDIT_SERVICE_CATEGORY_FAILURE";
//delete service category
export const DELETE_SERVICE_CATEGORY_REQUEST = "DELETE_SERVICE_CATEGORY_REQUEST";
export const DELETE_SERVICE_CATEGORY_SUCCESS = "DELETE_SERVICE_CATEGORY_SUCCESS";
export const DELETE_SERVICE_CATEGORY_FAILURE = "DELETE_SERVICE_CATEGORY_FAILURE";

//get services
export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";
//create service
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";
//delete service 
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";
//edit service 
export const EDIT_SERVICE_REQUEST = "EDIT_SERVICE_REQUEST";
export const EDIT_SERVICE_SUCCESS = "EDIT_SERVICE_SUCCESS";
export const EDIT_SERVICE_FAILURE = "EDIT_SERVICE_FAILURE";
//change top service status
export const CHANGE_TOP_SERVICE_STATUS_REQUEST = "CHANGE_TOP_SERVICE_STATUS_REQUEST";
export const CHANGE_TOP_SERVICE_STATUS_SUCCESS = "CHANGE_TOP_SERVICE_STATUS_SUCCESS";
export const CHANGE_TOP_SERVICE_STATUS_FAILURE = "CHANGE_TOP_SERVICE_STATUS_FAILURE";
//add service to category
export const ADD_SERVICE_TO_CATEGORY_REQUEST = "ADD_SERVICE_TO_CATEGORY_REQUEST";
export const ADD_SERVICE_TO_CATEGORY_SUCCESS = "ADD_SERVICE_TO_CATEGORY_SUCCESS";
export const ADD_SERVICE_TO_CATEGORY_FAILURE = "ADD_SERVICE_TO_CATEGORY_FAILURE";
//remove service from category
export const REMOVE_SERVICE_FROM_CATEGORY_REQUEST = "REMOVE_SERVICE_FROM_CATEGORY_REQUEST";
export const REMOVE_SERVICE_FROM_CATEGORY_SUCCESS = "REMOVE_SERVICE_FROM_CATEGORY_SUCCESS";
export const REMOVE_SERVICE_FROM_CATEGORY_FAILURE = "REMOVE_SERVICE_FROM_CATEGORY_FAILURE";

//get brands
export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE';
//get brands by id
export const GET_BRAND_BY_ID_REQUEST = 'GET_BRAND_BY_ID_REQUEST';
export const GET_BRAND_BY_ID_SUCCESS = 'GET_BRAND_BY_ID_SUCCESS';
export const GET_BRAND_BY_ID_FAILURE = 'GET_BRAND_BY_ID_FAILURE';
//new brand
export const CREATE_NEW_BRAND_REQUEST = 'CREATE_NEW_BRAND_REQUEST';
export const CREATE_NEW_BRAND_SUCCESS = 'CREATE_NEW_BRAND_SUCCESS';
export const CREATE_NEW_BRAND_FAILURE = 'CREATE_NEW_BRAND_FAILURE';
//delete brand 
export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";
//edit brand
export const EDIT_BRAND_REQUEST = "EDIT_BRAND_REQUEST";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCCESS";
export const EDIT_BRAND_FAILURE = "EDIT_BRAND_FAILURE";
//add brand to category
export const ADD_BRAND_TO_CATEGORY_REQUEST = "ADD_BRAND_TO_CATEGORY_REQUEST";
export const ADD_BRAND_TO_CATEGORY_SUCCESS = "ADD_BRAND_TO_CATEGORY_SUCCESS";
export const ADD_BRAND_TO_CATEGORY_FAILURE = "ADD_BRAND_TO_CATEGORY_FAILURE";
//delete brand from category
export const DELETE_BRAND_FROM_CATEGORY_REQUEST = "DELETE_BRAND_FROM_CATEGORY_REQUEST";
export const DELETE_BRAND_FROM_CATEGORY_SUCCESS = "DELETE_BRAND_FROM_CATEGORY_SUCCESS";
export const DELETE_BRAND_FROM_CATEGORY_FAILURE = "DELETE_BRAND_FROM_CATEGORY_FAILURE";

//get notes
export const GET_NOTES_REQUEST = "GET_NOTES_REQUEST";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAILURE = "GET_NOTES_FAILURE";
//create note
export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE';
//edit note
export const EDIT_NOTE_REQUEST = "EDIT_NOTE_REQUEST";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILURE = "EDIT_NOTE_FAILURE";
//delete note
export const DELETE_NOTE_REQUEST = "DELETE_NOTE_REQUEST";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILURE = "DELETE_NOTE_FAILURE";

//get blog categories
export const GET_NOTE_CATEGORIES_REQUEST = "GET_NOTE_CATEGORIES_REQUEST";
export const GET_NOTE_CATEGORIES_SUCCESS = "GET_NOTE_CATEGORIES_SUCCESS";
export const GET_NOTE_CATEGORIES_FAILURE = "GET_NOTE_CATEGORIES_FAILURE";
//crate blog category
export const CREATE_NOTE_CATEGORY_REQUEST = 'CREATE_NOTE_CATEGORY_REQUEST';
export const CREATE_NOTE_CATEGORY_SUCCESS = 'CREATE_NOTE_CATEGORY_SUCCESS';
export const CREATE_NOTE_CATEGORY_FAILURE = 'CREATE_NOTE_CATEGORY_FAILURE';
//edit blog category
export const EDIT_NOTE_CATEGORY_REQUEST = "EDIT_NOTE_CATEGORY_REQUEST";
export const EDIT_NOTE_CATEGORY_SUCCESS = "EDIT_NOTE_CATEGORY_SUCCESS";
export const EDIT_NOTE_CATEGORY_FAILURE = "EDIT_NOTE_CATEGORY_FAILURE";
//delete blog category
export const DELETE_NOTE_CATEGORY_REQUEST = "DELETE_NOTE_CATEGORY_REQUEST";
export const DELETE_NOTE_CATEGORY_SUCCESS = "DELETE_NOTE_CATEGORY_SUCCESS";
export const DELETE_NOTE_CATEGORY_FAILURE = "DELETE_NOTE_CATEGORY_FAILURE";

//users
//get fixers
export const GET_FIXERS_REQUEST = 'GET_FIXERS_REQUEST';
export const GET_FIXERS_SUCCESS = 'GET_FIXERS_SUCCESS';
export const GET_FIXERS_FAILURE = 'GET_FIXERS_FAILURE';
//new fixer
export const CREATE_FIXER_REQUEST = 'CREATE_FIXER_REQUEST';
export const CREATE_FIXER_SUCCESS = 'CREATE_FIXER_SUCCESS';
export const CREATE_FIXER_FAILURE = 'CREATE_FIXER_FAILURE';
//edit fixer
export const EDIT_FIXER_REQUEST = 'EDIT_FIXER_REQUEST';
export const EDIT_FIXER_SUCCESS = 'EDIT_FIXER_SUCCESS';
export const EDIT_FIXER_FAILURE = 'EDIT_FIXER_FAILURE';
//edit fixer
export const EDIT_FIXER_STATUS_REQUEST = 'EDIT_FIXER_STATUS_REQUEST';
export const EDIT_FIXER_STATUS_SUCCESS = 'EDIT_FIXER_STATUS_SUCCESS';
export const EDIT_FIXER_STATUS_FAILURE = 'EDIT_FIXER_STATUS_FAILURE';
//get clients
export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';
//new client
// export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
// export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
// export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';
//edit client
export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';

//orders
//get orders
export const GET_CLIENTS_ORDERS_REQUEST = 'GET_CLIENTS_ORDERS_REQUEST';
export const GET_CLIENTS_ORDERS_SUCCESS = 'GET_CLIENTS_ORDERS_SUCCESS';
export const GET_CLIENTS_ORDERS_FAILURE = 'GET_CLIENTS_ORDERS_FAILURE';
//edit order
export const EDIT_CLIENT_ORDER_REQUEST = 'EDIT_CLIENT_ORDER_REQUEST';
export const EDIT_CLIENT_ORDER_SUCCESS = 'EDIT_CLIENT_ORDER_SUCCESS';
export const EDIT_CLIENT_ORDER_FAILURE = 'EDIT_CLIENT_ORDER_FAILURE';