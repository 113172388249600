import React, { useEffect, useState } from "react";

import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Tooltip } from "antd";

function CheckoutForm({ clientSecret }) {
    const stripe = useStripe();
    const elements = useElements();
    const address = localStorage.getItem('address')

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, clientSecret]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.protocol}//${window.location.hostname}/checkout-success`
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id='payment-element' options={paymentElementOptions} />
            {address ? <button disabled={isLoading || !stripe || !elements || !address} id="submit">
                <span id="button-text">
                    {isLoading ? "Pagando..." : "Pagar"}
                </span>
            </button> :
                <Tooltip title='Agrega una dirección para completar tu pago.'>
                    <button disabled={isLoading || !stripe || !elements || !address}>
                        <span id="button-text">
                            {isLoading ? "Pagando..." : "Pagar"}
                        </span>
                    </button>
                </Tooltip>
            }
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}

export default CheckoutForm