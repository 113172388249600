import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, Button, Card, Row, Col, Avatar, Pagination, Input, Select, Modal, Tooltip, Switch } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

import NewService from './NewService';
import EditService from './EditService';

const confirm = Modal.confirm;
const Search = Input.Search;
const { Option } = Select;

function ServicesList() {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [search, setSearch] = useState(null)
    const [inputValue, setInputValue] = useState(null);
    const [isVisibleCS, setIsVisibleCS] = useState(false)
    const [isVisibleES, setIsVisibleES] = useState(false)
    const [serviceInfo, setServiceInfo] = useState([])

    const dispatch = useDispatch();
    const fetchingGetServices = useSelector((state) => state.AdminReducer.fetchingGetServices);
    const services = useSelector((state) => state.AdminReducer.services);
    const categories = useSelector((state) => state.AdminReducer.serviceCategories)

    useEffect(() => {
        dispatch({ type: "GET_SERVICES_REQUEST", page, id, search });
        dispatch({ type: "GET_SERVICE_CATEGORIES_REQUEST" });
    }, [dispatch, page, id, search]);

    const onChange = (value) => {
        setId(value);
        setSearch(null);
        setInputValue(null);
        setPage(1);
    };

    const onChangePage = (value) => {
        setPage(value)
    }

    const onChangeStatus = (checked, id) => {
        dispatch({ type: "CHANGE_TOP_SERVICE_STATUS_REQUEST", checked, id });
    }

    const showDeleteConfirm = (serviceID, service) => {
        confirm({
            title: '¿Estás seguro que deseas eliminar el servicio  "' + service + '"?',
            content: '',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                dispatch({ type: "DELETE_SERVICE_REQUEST", id: serviceID, category: id })
                clearFilters();
            },
            onCancel() {
            },
        });
    };

    const handleOnSearchChange = (e) => {
        if (!e.target.value) {
            setSearch(null)
            setInputValue(null)
        } else {
            setInputValue(e.target.value)
        }
    }

    const clearFilters = () => {
        setInputValue(null);
        setSearch(null);
        setPage(1);
        setId(null);
    }

    return (
        <>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Search
                        value={inputValue}
                        onChange={e => handleOnSearchChange(e)}
                        placeholder="Buscar servicio"
                        onSearch={value => { setSearch(value); setId(null) }}
                        allowClear
                    />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Select
                        value={id}
                        allowClear
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Selecciona una categoría"
                        optionFilterProp="children"
                        onChange={onChange}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {categories.map(category =>
                            <Option value={category.idCategoriaArticulo} key={category.idCategoriaArticulo}>
                                {category.categoriaArticulo}
                            </Option>
                        )}
                    </Select>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Button style={{ width: '100%' }} type="primary" className="login-btn"
                        onClick={() => setIsVisibleCS(!isVisibleCS)}
                    >Nuevo servicio</Button>
                </Col>
            </Row>
            <br />
            <Card title={
                <div className="header-list">SERVICIOS</div>}>
                <List
                    itemLayout="horizontal"
                    loading={fetchingGetServices}
                    locale={{ emptyText: 'Sin servicios' }}
                    dataSource={services.data}
                    renderItem={item => (
                        <List.Item
                            key={`service-0${item.idArticulo}`}
                            actions={[
                                <span
                                    onClick={() => showDeleteConfirm(item.idArticulo, item.articulo)}
                                ><DeleteTwoTone className="icon" /></span>,
                                <span
                                    onClick={() => {
                                        setIsVisibleES(!isVisibleES);
                                        setServiceInfo(item);
                                    }}
                                ><EditTwoTone className="icon" /></span>,
                                <Tooltip title="Cambiar estatus de Top Servicio">
                                    <Switch checked={item.servicioTop} size='small' onChange={() => onChangeStatus(!item.servicioTop, item.idArticulo)} />
                                </Tooltip>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.articulo}
                                avatar={<Avatar src={item.foto} />}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <br />
            <Pagination current={page} total={services.count} onChange={onChangePage} />
            <br />
            <NewService
                clearFilters={clearFilters}
                isVisibleCS={isVisibleCS}
                setIsVisibleCS={setIsVisibleCS}
            />
            <EditService
                clearFilters={clearFilters}
                isVisibleES={isVisibleES}
                setIsVisibleES={setIsVisibleES}
                serviceInfo={serviceInfo}
                categories={categories}
            />
        </>
    )
}

export default ServicesList