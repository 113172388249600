import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import FixersLogo from '../../Assets/Logos/logo_fixers_color.png'

import '../../Stylesheets/Login/Login.css';

function LoginHeader() {
    return (
        <Row align='middle' justify='space-between' className='header' >
            <Col span={2}><Link to="/"><Button icon={<ArrowLeftOutlined className='icon' />} type='text' /></Link></Col>
            <Col flex="auto" type="flex" align="middle">
                <Link to='/'>
                    <img src={FixersLogo} alt="logo" />
                </Link>
            </Col>
        </Row>
    )
}

export default LoginHeader