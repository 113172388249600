import React, { useEffect } from 'react'

import { notification } from 'antd'

import LoginHeader from '../../Components/Headers/LoginHeader'
import LoginContainer from './LoginContainer'

function LoginApp() {
    useEffect(() => {
        notification.destroy();
    }, [])

    return (
        <>
            <LoginHeader />
            <LoginContainer />
        </>
    )
}

export default LoginApp