import axios from 'axios'

const endpoint = "https://sissa-fixer.herokuapp.com/";

//login
export function getLoginCALL(data) {
	return axios({
		method: "post",
		url: endpoint + "login",
		headers: { 'Content-Type': 'application/json' },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//sign up client
export function getSignUpClientCALL(data) {
	return axios({
		method: "post",
		url: endpoint + "users/cliente",
		headers: { 'Content-Type': 'application/json' },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//recover password
export function recuperarContraseñaCALL(email) {
	return axios({
		method: "patch",
		url: endpoint + "users/reset/" + email,
		headers: { 'Content-Type': 'application/json', }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//sign up client
export function getSignUpFixerCALL(data) {
	return axios({
		method: "post",
		url: endpoint + "users/cliente",
		headers: { 'Content-Type': 'application/json' },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}