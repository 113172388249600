import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Upload, Spin, Select, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { Option } = Select;
const { TextArea } = Input;

function EditNote({ isVisibleEdit, setIsVisibleEdit, noteInfo, categories, clearFilters }) {
    const [image, setImage] = useState();
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState()

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const fetchingEditNote = useSelector((state) => state.AdminReducer.fetchingEditNote);

    useEffect(() => {
        if (noteInfo.length !== 0) {
            if (noteInfo.img) {
                setImage(noteInfo.img)
            }

            if (noteInfo.text) {
                let text = noteInfo.text
                text = htmlToDraft(text);
                text = ContentState.createFromBlockArray(text.contentBlocks)
                setEditorState(EditorState.createWithContent(text));
            }
        } else {
            setEditorState(EditorState.createEmpty())
        }

        form.resetFields();
    }, [dispatch, form, noteInfo]);

    const onFinish = (values) => {
        if (image) {
            let id = noteInfo.id;
            let text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dispatch({ type: "EDIT_NOTE_REQUEST", form: values, id, image, text });
            setIsVisibleEdit(!isVisibleEdit)
            form.resetFields();
            clearFilters();
        }else{
            message.error('Agrega una imagen')
        }
    };

    const onCancel = () => {
        form.resetFields();
        setIsVisibleEdit(!isVisibleEdit)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImage(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar Nota "${noteInfo.title}"`}
            open={isVisibleEdit}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="editNote" key="submit" type="primary"
                    loading={fetchingEditNote}
                >
                    Guardar
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditNote} >
                <Form
                    name='editNote'
                    id='editNote'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={noteInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Input maxLength={50} prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Titulo de la nota (Máximo 50 caracteres)" />
                    </Form.Item>

                    <Form.Item
                        name={["category", "id"]}
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Select
                            placeholder="Categoría"
                            allowClear
                            style={{ width: '100%' }}
                        >
                            {categories.map(category =>
                                <Option value={category.idCategoriaNota} key={category.idCategoriaNota}>
                                    {category.categoriaNota}
                                </Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <TextArea placeholder="Descripción (Máximo 100 caracteres)" maxLength={100} />
                    </Form.Item>
                </Form>

                <div className="rdw-storybook-root">
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor editorHTML"
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>

                <Upload.Dragger
                    name="files"
                    onChange={handleChange}
                    multiple={false}
                    showUploadList={false}
                    className='dragger'
                    beforeUpload={beforeUpload}
                >
                    {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload.Dragger>
            </Spin>
        </Modal>
    )
}

export default EditNote