import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { Modal, Form, Input, Button, DatePicker } from 'antd'
import { MailOutlined, LockOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'

import * as AdminActions from '../../../Store/Actions/AdminActions';

function NewFixer({ isVisibleCreate, setIsVisibleCreate, clearFilters }) {
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const fetchingCreateFixer = useSelector(state => state.AdminReducer.fetchingCreateFixer)

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = (values) => {
        dispatch({ type: AdminActions.CREATE_FIXER_REQUEST, form: values })
        setIsVisibleCreate(!isVisibleCreate);
        clearFilters()
        form.resetFields();
    }

    const onHandleCancel = () => {
        setIsVisibleCreate(!isVisibleCreate);
        form.resetFields();
    }

    const disabledDate = (current) => {
        return current && current > moment().subtract(18, 'years');
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title="Registrar nuevo fixer"
            open={isVisibleCreate}
            onCancel={onHandleCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onHandleCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="signupFixer" key="submit" type="primary"
                    loading={fetchingCreateFixer}
                >
                    Registrar
                </Button>
            ]}
        >
            <Form
                id="signupFixer"
                name="signupFixer"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Escribe tu correo electrónico' }]}
                >
                    <Input allowClear prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Correo electrónico" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Escribe tu contraseña' }]}
                >
                    <Input.Password allowClear prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Contraseña" autoComplete="on" />
                </Form.Item>

                <Form.Item
                    name={['name', 'firstName']}
                    rules={[{ required: true, message: "Por favor favor ingresa tu nombre" }]}
                >
                    <Input allowClear prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                </Form.Item>

                <Form.Item
                    name={['name', 'lastName']}
                    rules={[{ required: true, message: "Por favor favor ingresa tu apellido" }]}
                >
                    <Input allowClear prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Apellido" />
                </Form.Item>

                <Form.Item
                    name="birthday"
                    rules={[{ required: true, message: "Por favor favor ingresa tu fecha de nacimiento" }]}
                >
                    <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} format='DD/MM/YYYY' placeholder="Fecha de nacimiento" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    rules={[{ required: true, message: "Por favor favor ingresa tu número de teléfono" }]}
                >
                    <Input allowClear prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} minLength={10} maxLength={10} placeholder="Número de teléfono" />
                </Form.Item>
                {/* <Button
                    loading={fetchingSignup}
                    type='primary'
                    htmlType='submit'>
                    Regístrar fixer
                </Button> */}
            </Form>
        </Modal>
    )
}

export default NewFixer