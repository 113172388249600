import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import { Card, Pagination, Spin, Tooltip, Empty } from 'antd';
import { LoadingOutlined, TagsFilled, ClockCircleOutlined } from '@ant-design/icons';

import '../../Stylesheets/Blog/BlogContent.css';
const { Meta } = Card;

function BlogContent() {
    const [page, setPage] = useState(1)
    const [id, setId] = useState();
    const [search, setSearch] = useState()

    const dispatch = useDispatch();
    const fetchingGetNotes = useSelector((state) => state.BlogReducer.fetchingGetNotes);
    const notes = useSelector((state) => state.BlogReducer.notes);

    let urlParams = new URLSearchParams(window.location.search);
    let title = urlParams.get('title');
    let category = urlParams.get('category');

    useEffect(() => {
        if (title) {
            setSearch(title)
        } else if (category) {
            setId(category)
        } else {
            setId()
            setSearch()
        }

        dispatch({ type: "GET_BLOG_NOTES_REQUEST", page, id, search });
        dispatch({ type: "GET_BLOG_CATEGORIES_REQUEST" });
    }, [dispatch, page, id, search, category, title]);

    const onChangePage = (value) => {
        setPage(value)
    }

    return (
        <div className='blog-content'>
            <Spin spinning={fetchingGetNotes} indicator={<LoadingOutlined style={{ fontSize: '4em' }} />}>
                {notes.length > 0 ?
                    <>
                        {notes.map((note) =>
                            <Link key={note.key} to={`/note?note=${note.id}`}>
                                <Card
                                    hoverable
                                    cover={<>
                                        <p><ClockCircleOutlined /> {note.publicationDate}</p>
                                        <Tooltip placement="topLeft" title={note.title}><h3>{note.title}</h3></Tooltip>
                                        <img alt="cover" src={note.img} />
                                    </>}
                                >
                                    <Meta description={<span>{note.description}</span>} />
                                    <p><TagsFilled /> {note.category.name}</p>
                                </Card>
                            </Link>
                        )}
                        <Pagination current={page} total={notes.length} onChange={onChangePage} />
                    </> :
                    <Empty
                        description={
                            <span>{id ? <>No hay ninguna nota relacionada a esta categoría</> : <>No hay resultados para: <b>{search}</b></>}</span>
                        } />
                }
            </Spin>
        </div>
    )
}

export default BlogContent