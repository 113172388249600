import React from 'react'
import { useSelector } from 'react-redux'

import SelectAddress from './SelectAddress'
import ConfirmAddress from './ConfirmAddress'
import SaveAddress from './SaveAddress'

function AddressContainer() {
    const vistaAddress = useSelector(state => state.DashboardReducer.vistaAddress)

    const Content = () => {
        if (vistaAddress === 'add-select') {
            return (
                <SelectAddress />
            )
        }
        else if (vistaAddress === 'confirm') {
            return (
                <ConfirmAddress/>
            )
        }
        else if(vistaAddress === 'save'){
            return (
                <SaveAddress />
            )
        }
        else {
            return (
                <SelectAddress />
            )
        }
    }

    return (
        <Content />
    )
}

export default AddressContainer