import { all, fork } from "redux-saga/effects";
import * as LoginSaga from "./LoginSaga";
import * as DashboardSaga from './DashboardSaga';
import * as AdminSaga from './AdminSaga';
import * as BlogSaga from './BlogSaga';

export default function* rootSaga() {
  var allSagas = Object.assign(LoginSaga, DashboardSaga, AdminSaga, BlogSaga);

  yield all(
    [...Object.values(allSagas)].map(fork)
  );
}