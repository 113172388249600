import axios from 'axios'

const endpoint = "https://sissa-fixer.herokuapp.com/";

//get service categories
export function getServiceCategoriesCALL() {
	return axios({
		method: "get",
		url: endpoint + "categoriasArticulos",
		headers: { 'Content-Type': 'application/json,' },
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//create service category
export function createServiceCategoryCALL(token, data) {
	return axios({
		method: "post",
		url: endpoint + "categoriasArticulos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//edit service category
export function editServiceCategoryCALL(token, data) {
	return axios({
		method: "put",
		url: endpoint + "categoriasArticulos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//delete service category
export function deleteServiceCategoryCALL(token, id) {
	return axios({
		method: "delete",
		url: endpoint + "categoriasArticulos?idCategoriaArticulo=" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get all Services
export function getServicesCALL(page) {
	return axios({
		method: "get",
		url: endpoint + "articulos?page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json,' },
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//get Services by category id
export function getServicesByCategoryCALL(page, id) {
	return axios({
		method: "get",
		url: endpoint + "articulos?page=" + page + "&idCategoriaArticulo=" + id + "&offset=10",
		headers: { 'Content-Type': 'application/json,' },
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//search Service
export function getSearchServiceCALL(search) {
	return axios({
		method: "get",
		url: endpoint + "articulos?page=0&offset=10&articulo=" + search,
		headers: { 'Content-Type': 'application/json,' },
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//create service 
export function createServiceCALL(token, data) {
	return axios({
		method: "post",
		url: endpoint + "articulos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//delete service
export function deleteServiceCALL(token, id) {
	return axios({
		method: "delete",
		url: endpoint + "articulos?idArticulo=" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token }
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//edit service
export function editServiceCALL(token, data) {
	return axios({
		method: "put",
		url: endpoint + "articulos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response
	}).catch(error => {
		return error.response.data
	})
}
//change top service status
export function changeTopServiceStatusCALL(token, data) {
	return axios({
		method: "put",
		url: endpoint + "articulos/cambiarEstadoArticuloTop?idArticulo=" + data.id + "&estadoTop=" + data.status,
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//add service to category
export function addServiceToCategoryCALL(token, data) {
	return axios({
		method: "post",
		url: endpoint + "articulos/addCategoria",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//remove service from category
export function removeServiceFromCategoryCALL(token, id) {
	return axios({
		method: "delete",
		url: endpoint + "articulos/addCategoria/?id=" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//get all brands
export function getBrandsCALL(page) {
	return axios({
		method: "get",
		url: endpoint + "marcas?page=" + page + "&offset=10&order=idMarca&direction=ASC",
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get brand by id
export function getBrandByIDCALL(token, id) {
	return axios({
		method: "get",
		url: endpoint + "marcas/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get brands by category id
export function getBrandsBycategoryIdCALL(page, id) {
	return axios({
		method: "get",
		url: endpoint + "marcas?page=" + page + "&offset=10&order=idMarca&direction=ASC&idCategoriaArticulo=" + id,
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//create new brand
export function createNewBrandCALL(token, data) {
	return axios({
		method: "post",
		url: endpoint + "marcas",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//edit brand
export function editBrandCALL(token, data, id) {
	return axios({
		method: 'put',
		url: `${endpoint}marcas/${id}`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}
//delete new brand
export function deleteBrandCALL(token, id) {
	return axios({
		method: "delete",
		url: endpoint + "marcas/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//add brand to category
export function addBrandToCategoryCALL(token, data) {
	return axios({
		method: "post",
		url: endpoint + "marcas/categorias",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//delete brand from category
export function deleteBrandFromCategoryCALL(token, id) {
	return axios({
		method: "delete",
		url: endpoint + "marcas/categorias/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get notes
export function getNotesCALL(page) {
	return axios({
		method: 'get',
		url: endpoint + 'notas?page=' + page + '&offset=10&idTipoNota=1',
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get notes by category id
export function getNotesByCategoryIdCALL(page, id) {
	return axios({
		method: 'get',
		url: endpoint + 'notas?page=' + page + '&offset=10&idCategoriaNota=' + id + '&idTipoNota=1',
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get notes by title
export function getNotesByTitleCALL(page, title) {
	return axios({
		method: 'get',
		url: endpoint + 'notas/search?page=' + page + '&offset=10&titulo=' + title,
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//create note
export function createNoteCALL(token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'notas',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//edit Note
export function editNoteCALL(token, data) {
	return axios({
		method: 'put',
		url: endpoint + 'notas',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//delete note
export function deleteNoteCALL(token, id) {
	return axios({
		method: 'delete',
		url: endpoint + 'notas?idNota=' + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get blog categories
export function getCategoriesCALL() {
	return axios({
		method: 'get',
		url: endpoint + 'categoriasNotas',
		header: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//create blog category
export function createCategoryCALL(token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'categoriasNotas',
		headers: { 'Content-Type': 'application/json', 'authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//edit blog category
export function editCategoryCALL(token, data) {
	return axios({
		method: 'put',
		url: endpoint + 'categoriasNotas',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//delete blog category
export function deleteCategoryCALL(token, id) {
	return axios({
		method: 'delete',
		url: endpoint + 'categoriasNotas?idCategoriaNota=' + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get fixers
export function getFixersCALL(token, page) {
	return axios({
		method: 'get',
		url: `${endpoint}clientes/fixer?page=${page}&offset=10`,
		headers: { 'Content-Type': 'application/json', 'authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//get filtered fixers
export function getFilteredFixersCALL(token, page, name) {
	return axios({
		method: 'get',
		url: `${endpoint}clientes/fixer?page=${page}&offset=10&nameFixer=${name}`,
		headers: { 'Content-Type': 'application/json', 'authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//create fixer
export function createFixerCALL(data) {
	return axios({
		method: "post",
		url: endpoint + "users/cliente",
		headers: { 'Content-Type': 'application/json' },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//edit fixer
export function editFixerCALL(token, userID, clientID, data) {
	return axios({
		method: 'put',
		url: `${endpoint}clientes/${userID}?idCliente=${clientID}`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get clients
export function getClientsCALL(token) {
	return axios({
		method: 'get',
		url: `${endpoint}`,
		headers: { 'Content-Type': 'application/json', 'authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//edit client
export function editClientCALL(token, user, data) {
	return axios({
		method: 'put',
		url: `${endpoint}clientes/${user}?idClient=${user}`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//get orders
export function getClientsOrdersCALL(token, page) {
	return axios({
		method: 'get',
		url: `${endpoint}pedidos?page=${page}&offset=10`,
		headers: { 'Content-Type': 'application/json', 'authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//filter orders by id
export function getFilteredClientsOrdersCALL(token, page, orderID) {
	return axios({
		method: 'get',
		url: `${endpoint}pedidos?page=${page}&offset=10&idPedido=${orderID}`,
		headers: { 'Content-Type': 'application/json', 'authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//edit order
export function editClientOrderCALL(token, orderID, data) {
	return axios({
		method: 'put',
		url: `${endpoint}pedidos?idPedido=${orderID}`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}