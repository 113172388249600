import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Row, Col, Button, Input, Select } from 'antd';
import { MenuOutlined, SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { useWindowSize } from '../../Hooks/useWindowSize';
import '../../Stylesheets/Blog/BlogHeader.css';
import fixersLogo from '../../Assets/Logos/logo_fixers_color.png';
const { Option } = Select;

function BlogHeader() {
    const { width } = useWindowSize();
    const [searchValue, setSearchValue] = useState()
    const [showSearchDrawer, setShowSearchDrawer] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory();
    const categories = useSelector((state) => state.BlogReducer.categories);

    useEffect(() => {
        dispatch({ type: "GET_BLOG_CATEGORIES_REQUEST" });
    }, [dispatch]);

    const handleChange = e => {
        setSearchValue(e.target.value)
        
        if (!e.target.value) {
            history.push(`/blog`)
        }
    }

    const onSearch = (e) => {
        if (e.key === 'Enter' && searchValue) {
            history.push(`/blog?title=${searchValue}`)
        } else if (!searchValue) {
            history.push(`/blog`)
        }
    }

    const handleOnSearch = () => {
        if (searchValue) {
            history.push(`/blog?title=${searchValue}`)
        } else {
            history.push(`/blog`)
        }
    }

    const handleClick = () => {
        dispatch({ type: "CHANGE_VISTA_DASHBOARD", vistaDashboard: 'dashboard' })
    }

    const onChange = (value) => {
        if (value) {
            history.push(`/blog?category=${value}`)
        } else {
            history.push(`/blog`)
        }
    };

    return (
        <div className='header-blog'>
            {width < 1024 ? (
                <Row justify="space-between" align='middle' className='header-top-container'>
                    <Col span={showSearchDrawer ? (0) : (1)}>
                        <Button type="link" onClick={() => dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer: true })} icon={<MenuOutlined className='icon-header-blog' />} />
                    </Col>
                    {width >= 425 &&
                        <Col xs={{ span: showSearchDrawer ? (0) : (5) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 4 }} lg={{ span: 6 }}>
                            <Link to="/" onClick={handleClick} >
                                <img src={fixersLogo} alt="logo-header" className="logo-header" />
                            </Link>
                        </Col>
                    }

                    {width <= 767 ? ( //mobil
                        <>
                            <Col xs={{ span: showSearchDrawer ? (0) : (12) }} sm={{ span: showSearchDrawer ? (0) : (12) }} md={{ span: 0 }} lg={{ span: 0 }}>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Categoría"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {categories.map(category =>
                                        <Option value={category.idCategoriaNota} key={category.idCategoriaNota}>
                                            {category.categoriaNota}
                                        </Option>
                                    )}
                                </Select>
                            </Col>
                            <Col xs={{ span: showSearchDrawer ? (0) : (3) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 2 }} lg={{ span: 1 }}>
                                <Button className='search-button-header' onClick={() => { setShowSearchDrawer(true) }} icon={<SearchOutlined className='icon-header-blog' />} />
                            </Col>
                        </>
                    ) : ( //tablet
                        <>
                            <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    showSearch
                                    placeholder="Categoría"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {categories.map(category =>
                                        <Option value={category.idCategoriaNota} key={category.idCategoriaNota}>
                                            {category.categoriaNota}
                                        </Option>
                                    )}
                                </Select>
                            </Col>
                            <Col xs={{ span: showSearchDrawer ? (0) : (3) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 12 }} lg={{ span: 1 }} className="search-bar-container">
                                <Input
                                    value={searchValue}
                                    onChange={handleChange}
                                    onKeyDown={onSearch}
                                    bordered={false}
                                    placeholder="Buscar en el blog"
                                    suffix={<Button onClick={handleOnSearch} className='search-button-header' icon={<SearchOutlined className='icon-header-blog' />} />}
                                />
                            </Col>
                        </>
                    )}

                    <Col span={showSearchDrawer ? (24) : (0)} className='search-bar-container'>
                        <Row justify="space-around" align='middle'>
                            <Col span={1}><Button onClick={() => { setShowSearchDrawer(false) }} icon={<ArrowLeftOutlined className='icon-header-blog' />} type='text' /></Col>
                            <Col span={19}>
                                <Input
                                    value={searchValue}
                                    onChange={handleChange}
                                    onKeyDown={onSearch}
                                    bordered={false}
                                    placeholder="Buscar en el blog"
                                    suffix={<Button onClick={handleOnSearch} className='search-button-header' icon={<SearchOutlined className='icon-header-blog' />} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : ( //desktop
                <Row justify="space-between" align='middle' className='header-top-container'>
                    <Col span={1}>
                        <Button type="link" onClick={() => dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer: true })} icon={<MenuOutlined className='icon-header-blog' />} />
                    </Col>
                    <Col span={2} >
                        <Link to="/" onClick={handleClick} >
                            <img src={fixersLogo} alt="logo-header" className="logo-header" />
                        </Link>
                    </Col>
                    <Col span={4} className="location-container">
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            showSearch
                            placeholder="Categoría"
                            optionFilterProp="children"
                            onChange={onChange}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {categories.map(category =>
                                <Option value={category.idCategoriaNota} key={category.idCategoriaNota}>
                                    {category.categoriaNota}
                                </Option>
                            )}
                        </Select>
                    </Col>
                    <Col span={12} className="search-bar-container">
                        <Input
                            allowClear
                            value={searchValue}
                            onChange={handleChange}
                            onKeyDown={onSearch}
                            bordered={false}
                            placeholder="Buscar en el blog"
                            suffix={<Button onClick={handleOnSearch} className='search-button-header' icon={<SearchOutlined className='icon-header-blog' />} />}
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default BlogHeader