import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Input, Radio } from 'antd';
import { EnvironmentFilled } from '@ant-design/icons';

import * as DashboardActions from '../../../../Store/Actions/DashboardActions';

function SelectAddress() {
    const [autocomplete, setAutocomplete] = useState()
    const [inputValue, setInputValue] = useState()
    const address = localStorage.getItem('address')
    const dispatch = useDispatch();

    useEffect(() => {
        if (inputValue) {
            sessionStorage.setItem('address', inputValue)
            dispatch({ type: DashboardActions.CHANGE_VISTA_ADDRESS, vistaAddress: 'confirm' })
        }
    }, [inputValue, dispatch])

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        setInputValue(autocomplete.getPlace().formatted_address)
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCtAae1VdKZ5h7m1CmyKQldt9A0UniM3Dk',
        libraries: ['places']
    })

    return (
        <div className='select-address-content'>
            {isLoaded &&
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                >
                    <Input prefix={<EnvironmentFilled />} value={inputValue} type='text' placeholder='Escribe la dirección de entrega' />
                </Autocomplete>}
            {address &&
                <>
                    <br />
                    <Radio.Group value={0}>
                        <Radio value={0}>{address}</Radio>
                    </Radio.Group>
                </>
            }
        </div>
    )
}

export default SelectAddress