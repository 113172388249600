import * as adminActions from '../Actions/AdminActions'

const initialState = {
    //vistaAdmin
    vistaAdmin: sessionStorage.getItem('vistaAdmin'),

    //service categories
    fetchingGetServiceCategories: false,
    serviceCategories: [],
    fetchingCreateServiceCategory: false,
    fetchingEditServiceCategory: false,

    //get services
    fetchingGetServices: false,
    services: [],
    fetchingCreateService: false,
    fetchingEditService: false,
    fetchingChangeTopServiceStatus: false,
    fetchingEditServiceCategories: false,

    //brands
    brands: [],
    brandInfo: [],
    fetchingCreateNewBrand: false,
    fetchingEditBrand: false,
    fetchingEditBrandCategories: false,

    //notes
    notes: [],
    fetchingGetNotes: false,
    fetchingCreateNote: false,
    fetchingEditNote: false,

    //blog categories
    categories: [],
    fetchingGetCategories: false,
    fetchingCreateCategory: false,
    fetchingEditCategory: false,

    //users and orders
    fixers: [],
    fetchingCreateFixer: false,
    fetchingGetFixers: false,
    fetchingEditFixer: false,
    clients: [],
    fetchingGetUsers: false,
    fetchingCreateUser: false,
    fetchingEditUser: false,
    orders: [],
    fetchingGetOrders: false,
    fetchingEditOrder: false,
}

const AdminReducer = (state = initialState, action) => {
    switch (action.type) {
        //VISTA ADMIN
        case adminActions.CHANGE_VISTA_ADMIN:
            sessionStorage.setItem('vistaAdmin', action.vistaAdmin)
            return { ...state, vistaAdmin: action.vistaAdmin, };

        //SERVICE CATEGORIES
        case adminActions.GET_SERVICE_CATEGORIES_REQUEST:
            return { ...state, fetchingGetServiceCategories: true };
        case adminActions.GET_SERVICE_CATEGORIES_SUCCESS:
            return { ...state, fetchingGetServiceCategories: false, serviceCategories: action.serviceCategories };
        case adminActions.GET_SERVICE_CATEGORIES_FAILURE:
            return { ...state, fetchingGetServiceCategories: false };
        //CREATE SERVICE CATEGORY
        case adminActions.CREATE_SERVICE_CATEGORY_REQUEST:
            return { ...state, fetchingCreateServiceCategory: true };
        case adminActions.CREATE_SERVICE_CATEGORY_SUCCESS:
            return { ...state, fetchingCreateServiceCategory: false };
        case adminActions.CREATE_SERVICE_CATEGORY_FAILURE:
            return { ...state, fetchingCreateServiceCategory: false };
        //EDIT SERVICE CATEGORY
        case adminActions.EDIT_SERVICE_CATEGORY_REQUEST:
            return { ...state, fetchingEditServiceCategory: true };
        case adminActions.EDIT_SERVICE_CATEGORY_SUCCESS:
            return { ...state, fetchingEditServiceCategory: false };
        case adminActions.EDIT_SERVICE_CATEGORY_FAILURE:
            return { ...state, fetchingEditServiceCategory: false };


        //GET SERVICES
        case adminActions.GET_SERVICES_REQUEST:
            return { ...state, fetchingGetServices: true };
        case adminActions.GET_SERVICES_SUCCESS:
            return { ...state, fetchingGetServices: false, services: action.services };
        case adminActions.GET_SERVICES_FAILURE:
            return { ...state, fetchingGetServices: false };
        //CREATE NEW SERVICE 
        case adminActions.CREATE_SERVICE_REQUEST:
            return { ...state, fetchingCreateService: true };
        case adminActions.CREATE_SERVICE_SUCCESS:
            return { ...state, fetchingCreateService: false };
        case adminActions.CREATE_SERVICE_FAILURE:
            return { ...state, fetchingCreateService: false };
        //EDIT SERVICE
        case adminActions.EDIT_SERVICE_REQUEST:
            return { ...state, fetchingEditService: true };
        case adminActions.EDIT_SERVICE_SUCCESS:
            return { ...state, fetchingEditService: false };
        case adminActions.EDIT_SERVICE_FAILURE:
            return { ...state, fetchingEditService: false };
        //EDIT SERVICE (TOP SERVICE)
        case adminActions.CHANGE_TOP_SERVICE_STATUS_REQUEST:
            return { ...state, fetchingChangeTopServiceStatus: true };
        case adminActions.CHANGE_TOP_SERVICE_STATUS_SUCCESS:
            return { ...state, fetchingChangeTopServiceStatus: false };
        case adminActions.CHANGE_TOP_SERVICE_STATUS_FAILURE:
            return { ...state, fetchingChangeTopServiceStatus: false };
        //EDIT SERVICE CATEGORIES
        case adminActions.ADD_SERVICE_TO_CATEGORY_REQUEST:
            return { ...state, fetchingEditServiceCategories: true };
        case adminActions.ADD_SERVICE_TO_CATEGORY_SUCCESS:
            return { ...state, fetchingEditServiceCategories: false };
        case adminActions.ADD_SERVICE_TO_CATEGORY_FAILURE:
            return { ...state, fetchingEditServiceCategories: false };
        case adminActions.REMOVE_SERVICE_FROM_CATEGORY_REQUEST:
            return { ...state, fetchingEditServiceCategories: true };
        case adminActions.REMOVE_SERVICE_FROM_CATEGORY_SUCCESS:
            return { ...state, fetchingEditServiceCategories: false };
        case adminActions.REMOVE_SERVICE_FROM_CATEGORY_FAILURE:
            return { ...state, fetchingEditServiceCategories: false };


        //BRANDS
        case adminActions.GET_BRANDS_REQUEST:
            return { ...state, fetchingGetBrands: true };
        case adminActions.GET_BRANDS_SUCCESS:
            return { ...state, fetchingGetBrands: false, brands: action.brands };
        case adminActions.GET_BRANDS_FAILURE:
            return { ...state, fetchingGetBrands: false };
        //GET BRAND BY ID
        case adminActions.GET_BRAND_BY_ID_REQUEST:
            return { ...state, fetchingGetBrandByID: true, brandInfo: [] };
        case adminActions.GET_BRAND_BY_ID_SUCCESS:
            return { ...state, fetchingGetBrandByID: false, brandInfo: action.brandInfo };
        case adminActions.GET_BRAND_BY_ID_FAILURE:
            return { ...state, fetchingGetBrandByID: false };
        //CREATE NEW BRAND
        case adminActions.CREATE_NEW_BRAND_REQUEST:
            return { ...state, fetchingCreateNewBrand: true };
        case adminActions.CREATE_NEW_BRAND_SUCCESS:
            return { ...state, fetchingCreateNewBrand: false };
        case adminActions.CREATE_NEW_BRAND_FAILURE:
            return { ...state, fetchingCreateNewBrand: false };
        //EDIT BRAND
        case adminActions.EDIT_BRAND_REQUEST:
            return { ...state, fetchingEditBrand: true };
        case adminActions.EDIT_BRAND_SUCCESS:
            return { ...state, fetchingEditBrand: false };
        case adminActions.EDIT_BRAND_FAILURE:
            return { ...state, fetchingEditBrand: false };
        //EDIT BRAND CATEGORIES
        case adminActions.ADD_BRAND_TO_CATEGORY_REQUEST:
            return { ...state, fetchingEditBrandCategories: true };
        case adminActions.ADD_BRAND_TO_CATEGORY_SUCCESS:
            return { ...state, fetchingEditBrandCategories: false };
        case adminActions.ADD_BRAND_TO_CATEGORY_FAILURE:
            return { ...state, fetchingEditBrandCategories: false };
        case adminActions.DELETE_BRAND_FROM_CATEGORY_REQUEST:
            return { ...state, fetchingEditBrandCategories: true };
        case adminActions.DELETE_BRAND_FROM_CATEGORY_SUCCESS:
            return { ...state, fetchingEditBrandCategories: false };
        case adminActions.DELETE_BRAND_FROM_CATEGORY_FAILURE:
            return { ...state, fetchingEditBrandCategories: false };


        //GET NOTES
        case adminActions.GET_NOTES_REQUEST:
            return { ...state, fetchingGetNotes: true };
        case adminActions.GET_NOTES_SUCCESS:
            return { ...state, fetchingGetNotes: false, notes: action.notes };
        case adminActions.GET_NOTES_FAILURE:
            return { ...state, fetchingGetNotes: false };
        //CREATE NOTE
        case adminActions.CREATE_NOTE_REQUEST:
            return { ...state, fetchingCreateNote: true };
        case adminActions.CREATE_NOTE_SUCCESS:
            return { ...state, fetchingCreateNote: false };
        case adminActions.CREATE_NOTE_FAILURE:
            return { ...state, fetchingCreateNote: false };
        //EDIT NOTE
        case adminActions.EDIT_NOTE_REQUEST:
            return { ...state, fetchingEditNote: true };
        case adminActions.EDIT_NOTE_SUCCESS:
            return { ...state, fetchingEditNote: false };
        case adminActions.EDIT_NOTE_FAILURE:
            return { ...state, fetchingEditNote: false };


        //GET BLOG CATEGORIES
        case adminActions.GET_NOTE_CATEGORIES_REQUEST:
            return { ...state, fetchingGetCategories: true };
        case adminActions.GET_NOTE_CATEGORIES_SUCCESS:
            return { ...state, fetchingGetCategories: false, categories: action.categories };
        case adminActions.GET_NOTE_CATEGORIES_FAILURE:
            return { ...state, fetchingGetCategories: false };
        //CREATE CATEGORY
        case adminActions.CREATE_NOTE_CATEGORY_REQUEST:
            return { ...state, fetchingCreateCategory: true };
        case adminActions.CREATE_NOTE_CATEGORY_SUCCESS:
            return { ...state, fetchingCreateCategory: false };
        case adminActions.CREATE_NOTE_CATEGORY_FAILURE:
            return { ...state, fetchingCreateCategory: false };
        //EDIT CATEGORY
        case adminActions.EDIT_NOTE_CATEGORY_REQUEST:
            return { ...state, fetchingEditCategory: true };
        case adminActions.EDIT_NOTE_CATEGORY_SUCCESS:
            return { ...state, fetchingEditCategory: false };
        case adminActions.EDIT_NOTE_CATEGORY_FAILURE:
            return { ...state, fetchingEditCategory: false };


        //GET FIXERS
        case adminActions.GET_FIXERS_REQUEST:
            return { ...state, fetchingGetFixers: true };
        case adminActions.GET_FIXERS_SUCCESS:
            return { ...state, fetchingGetFixers: false, fixers: action.fixers };
        case adminActions.GET_FIXERS_FAILURE:
            return { ...state, fetchingGetFixers: false };
        //CREATE FIXER
        case adminActions.CREATE_FIXER_REQUEST:
            return { ...state, fetchingCreateFixer: true };
        case adminActions.CREATE_FIXER_SUCCESS:
            return { ...state, fetchingCreateFixer: false };
        case adminActions.CREATE_FIXER_FAILURE:
            return { ...state, fetchingCreateFixer: false };
        //EDIT FIXER
        case adminActions.EDIT_FIXER_REQUEST:
            return { ...state, fetchingEditFixer: true };
        case adminActions.EDIT_FIXER_SUCCESS:
            return { ...state, fetchingEditFixer: false };
        case adminActions.EDIT_FIXER_FAILURE:
            return { ...state, fetchingEditFixer: false };
        //EDIT FIXER STATUS
        case adminActions.EDIT_FIXER_STATUS_REQUEST:
            return { ...state, fetchingEditFixer: true };
        case adminActions.EDIT_FIXER_STATUS_SUCCESS:
            return { ...state, fetchingEditFixer: false };
        case adminActions.EDIT_FIXER_STATUS_FAILURE:
            return { ...state, fetchingEditFixer: false };


        //GET USERS ORDERS
        case adminActions.GET_CLIENTS_ORDERS_REQUEST:
            return { ...state, fetchingGetOrders: true };
        case adminActions.GET_CLIENTS_ORDERS_SUCCESS:
            return { ...state, fetchingGetOrders: false, orders: action.orders };
        case adminActions.GET_CLIENTS_ORDERS_FAILURE:
            return { ...state, fetchingGetOrders: false };
        //EDIT USER ORDER
        case adminActions.EDIT_CLIENT_ORDER_REQUEST:
            return { ...state, fetchingEditOrder: true };
        case adminActions.EDIT_CLIENT_ORDER_SUCCESS:
            return { ...state, fetchingEditOrder: false };
        case adminActions.EDIT_CLIENT_ORDER_FAILURE:
            return { ...state, fetchingEditOrder: false };


        //EDIT CLIENT
        case adminActions.EDIT_CLIENT_REQUEST:
            return { ...state, fetchingEditUser: true };
        case adminActions.EDIT_CLIENT_SUCCESS:
            return { ...state, fetchingEditUser: false };
        case adminActions.EDIT_CLIENT_FAILURE:
            return { ...state, fetchingEditUser: false };
        //GET CLIENTS
        case adminActions.GET_CLIENTS_REQUEST:
            return { ...state, fetchingGetUsers: true };
        case adminActions.GET_CLIENTS_SUCCESS:
            return { ...state, fetchingGetUsers: false, clients: action.clients };
        case adminActions.GET_CLIENTS_FAILURE:
            return { ...state, fetchingGetUsers: false };

        default:
            return state;
    }

}

export default AdminReducer;