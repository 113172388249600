import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

function CartContainer() {
    const dispatch = useDispatch();
    const cart = useSelector(state => state.DashboardReducer.cart);

    const removeFromCart = (item) => {
        dispatch({ type: "REMOVE_FROM_CART", item });
    }

    return (
        <div className='cart-content'>
            <List
                itemLayout="horizontal"
                locale={{ emptyText: 'Tu canasta está vacía.' }}
                dataSource={cart}
                renderItem={item => (
                    <List.Item
                        key={item.index + item.key}
                        actions={[
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => removeFromCart(item)}
                            ><DeleteTwoTone className="icon" /></span>,
                        ]}
                    >
                        <List.Item.Meta
                            title={item.title}
                            description={<b>${item.price}</b>}
                            avatar={<img src={item.img} alt='cart-item-img' />}
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}

export default CartContainer