//get notes
export const GET_BLOG_NOTES_REQUEST = "GET_BLOG_NOTES_REQUEST";
export const GET_BLOG_NOTES_SUCCESS = "GET_BLOG_NOTES_SUCCESS";
export const GET_BLOG_NOTES_FAILURE = "GET_BLOG_NOTES_FAILURE";

//get note info
export const GET_NOTE_INFO_REQUEST = "GET_NOTE_INFO_REQUEST";
export const GET_NOTE_INFO_SUCCESS = "GET_NOTE_INFO_SUCCESS";
export const GET_NOTE_INFO_FAILURE = "GET_NOTE_INFO_FAILURE";

//get blog categories
export const GET_BLOG_CATEGORIES_REQUEST = "GET_BLOG_CATEGORIES_REQUEST";
export const GET_BLOG_CATEGORIES_SUCCESS = "GET_BLOG_CATEGORIES_SUCCESS";
export const GET_BLOG_CATEGORIES_FAILURE = "GET_BLOG_CATEGORIES_FAILURE";