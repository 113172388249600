import { takeLatest, call, put } from 'redux-saga/effects'
import * as blogActions from '../Actions/BlogActions'
import * as alertActions from '../Actions/AlertActions'

import moment from 'moment';
import 'moment/locale/es';

import {
    getBlogNotesCALL,
    getBlogNotesByCategoryIdCALL,
    getBlogNotesByTitleCALL,
    getBlogCategoriesCALL,
    getNoteInfoCALL,
} from '../../Services/BlogServices'

//********************** GET NOTES *****************************
function* getBlogNotes(action) {
    try {
        let page = action.page - 1;
        let id = action.id;
        let search = action.search;

        var response;

        if (search) {
            search = search.toLowerCase()
            response = yield call(getBlogNotesByTitleCALL, page, search);
        } else if (id) {
            response = yield call(getBlogNotesByCategoryIdCALL, page, id);
        } else {
            response = yield call(getBlogNotesCALL, page);
        }

        if (response.status === 200) {
            let notes = [];

            response.data.data.forEach(note => {
                let title = note.titulo.toLowerCase().split(' ').join('-');

                function replaceSpecialChars(title) {
                    return title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                        .replace(/ *\([^)]*\) */g, "")
                        // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                        .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                        .replace(/%20/g, " ");
                }

                const noteKey = replaceSpecialChars(title);

                notes.push({
                    key: noteKey,
                    title: note.titulo,
                    id: note.idNota,
                    img: note.imagen,
                    description: note.descripcion,
                    text: note.texto,
                    category: {
                        id: note.categoriaNotaByIdCategoriaNota.idCategoriaNota,
                        name: note.categoriaNotaByIdCategoriaNota.categoriaNota,
                        description: note.categoriaNotaByIdCategoriaNota.descripcion
                    },
                    publicationDate: moment(note.fechaPublicacion, 'YYYY-MM-DD, h:mm:ss a').fromNow()
                });
            })

            yield put({ type: blogActions.GET_BLOG_NOTES_SUCCESS, notes });
        }

        else {
            yield put({ type: blogActions.GET_BLOG_NOTES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: blogActions.GET_BLOG_NOTES_FAILURE });
    }
}
export function* getBlogNotesSaga() {
    yield takeLatest(blogActions.GET_BLOG_NOTES_REQUEST, getBlogNotes);
}

//********************** GET NOTE INFO *****************************
function* getNoteInfo(action) {
    try {
        let id = action.noteId;

        const response = yield call(getNoteInfoCALL, id);

        if (response.status === 200) {
            const noteInfo = response.data.data[0];

            yield put({ type: blogActions.GET_NOTE_INFO_SUCCESS, noteInfo });
        } else {
            yield put({ type: blogActions.GET_NOTE_INFO_FAILURE });
        }

    } catch (error) {
        yield put({ type: blogActions.GET_NOTE_INFO_FAILURE });
    }
}
export function* getNoteInfoSaga() {
    yield takeLatest(blogActions.GET_NOTE_INFO_REQUEST, getNoteInfo);
}

//********************** GET BLOG CATEGORIES *****************************
function* getBlogCategories() {
    try {
        const response = yield call(getBlogCategoriesCALL);

        if (response.status === 200) {
            let categories = response.data

            yield put({ type: blogActions.GET_BLOG_CATEGORIES_SUCCESS, categories });
        }
        else {
            yield put({ type: blogActions.GET_BLOG_CATEGORIES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: blogActions.GET_BLOG_CATEGORIES_FAILURE });
    }
}
export function* getBlogCategoriesSaga() {
    yield takeLatest(blogActions.GET_BLOG_CATEGORIES_REQUEST, getBlogCategories);
}