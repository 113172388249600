import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Row, Col, Card, Form, Input, DatePicker, Button, Spin, InputNumber } from 'antd';
import { SettingOutlined, HistoryOutlined } from '@ant-design/icons'

import * as dashboardActions from '../../Store/Actions/DashboardActions'
import logo from '.././../Assets/Logos/fixers-logo.png';
import '../../Stylesheets/User Client/AccountContent.css';

function ProfileContent() {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.DashboardReducer.userData);
    const fetchingGetUserData = useSelector(state => state.DashboardReducer.fetchingGetUserData)
    const fetchingEditUserData = useSelector(state => state.DashboardReducer.fetchingEditUserData)

    useEffect(() => {
        dispatch({ type: dashboardActions.GET_USER_DATA_REQUEST })
    }, [dispatch])

    const onFinish = (values) => {
        dispatch({ type: dashboardActions.EDIT_USER_DATA_REQUEST, values, userData })
    };

    const disabledDate = (current) => {
        return current && current > moment().subtract(18, 'years');
    };

    return (
        <div className='account-container'>
            <Spin spinning={fetchingGetUserData || fetchingEditUserData}>
                <Row justify='space-between' align='top' gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <Card
                            className='menu-content'
                            cover={
                                <Row justify='space-around' align='middle'>
                                    <Col span={8}>
                                        <img src={logo} alt="profile-img" />
                                    </Col>
                                    <Col span={10}>
                                        <p><span>Mi perfil</span></p>
                                        <p><b>{userData.name}</b></p>
                                    </Col>
                                </Row>
                            }
                        >
                            <Link to='/account/profile'><Button icon={<SettingOutlined />}>Ajustes de cuenta</Button></Link>
                            <Link to='/account/orders' className='last'><Button icon={<HistoryOutlined />}>Últimas órdenes</Button></Link>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
                        <Card
                            className='account-content'
                            title="Información de tu cuenta"
                        >
                            {userData.length !== 0 &&
                                <Form
                                    name="profile"
                                    layout='vertical'
                                    labelCol={{
                                        span: 24,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    initialValues={userData}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    preserve={false}
                                >
                                    <Row justify='space-between'>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                            <Form.Item
                                                label="Nombre(s)"
                                                name="name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Escribe tu(s) nombre(s)',
                                                    },
                                                ]}
                                            >
                                                <Input maxLength={50} placeholder="Nombre(s)" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                            <Form.Item
                                                label="Apellido(s)"
                                                name="lastName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Escribe tu(s) apellido(s)',
                                                    },
                                                ]}
                                            >
                                                <Input maxLength={50} placeholder="Apellido(s)" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                            <Form.Item
                                                label='Correo Electrónico'
                                                name="email"
                                                rules={[{ type: 'email', message: 'Escribe un correo electrónico válido.' }, { required: true, message: 'Escribe tu correo electrónico' }]}
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                            <Form.Item
                                                label='Fecha de nacimiento'
                                                name="birthday"
                                                rules={[{ required: true, message: "Por favor favor ingresa tu fecha de nacimiento" }]}
                                            >
                                                <DatePicker disabledDate={disabledDate} format='DD/MM/YYYY' style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                            <Form.Item
                                                label='Número de teléfono'
                                                name="phone"
                                                rules={[{ required: true, message: "Por favor favor ingresa tu número de teléfono" }, { type: 'number', message: 'Este campo solo permite números' }]}
                                            >
                                                <InputNumber style={{ width: '100%' }} maxLength={10} placeholder='Número de teléfono' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Item>
                                                <Button
                                                    loading={fetchingEditUserData}
                                                    htmlType="submit">
                                                    Actualizar datos
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

export default ProfileContent