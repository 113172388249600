import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer from "./Reducer/IndexReducer";
import rootSaga from "./Saga/IndexSaga";

  // dev tools middleware
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

  // create the saga middleware
 const sagaMiddleware = createSagaMiddleware();
  // mount it on the Store
  const store = createStore(reducer, applyMiddleware(sagaMiddleware),reduxDevTools);

  export default store;
  // then run the saga
  sagaMiddleware.run(rootSaga);