import { takeLatest, call, put } from 'redux-saga/effects'
import * as dashboardActions from '../Actions/DashboardActions'
import * as alertActions from '../Actions/AlertActions'

import moment from 'moment/moment';

import {
    getDashboardCategoriesCALL,
    getBrandsBycategoryIdCALL,
    getServicesBycategoryIdCALL,
    getServicesSearchCALL,
    getTopServicesCALL,
    getUserDataCALL,
    editUserDataCALL,
    getUserOrdersCALL,
    createPaymentIntentCALL,
    createUserOrderCALL,
} from '../../Services/DashboardServices'

//GET DASHBOARD CATEGORIES
function* getCategoriesService() {
    try {
        const response = yield call(getDashboardCategoriesCALL);

        if (response.status === 200) {
            let dashboardCategories = [];

            for (let i in response.data) {
                let category = response.data[i].categoriaArticulo.toLowerCase().split(' ').join('-');

                function replaceSpecialChars(category) {
                    return category.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                        .replace(/ *\([^)]*\) */g, "")
                        // // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                        .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                        .replace(/%20/g, " ");
                }

                const categorieKey = replaceSpecialChars(category);

                dashboardCategories.push({
                    key: categorieKey,
                    name: response.data[i].categoriaArticulo,
                    id: response.data[i].idCategoriaArticulo,
                    active: response.data[i].activo,
                    img: response.data[i].foto
                });
            }

            yield put({ type: dashboardActions.GET_DASHBOARD_CATEGORIES_SUCCESS, dashboardCategories });
        }
        else {
            yield put({ type: dashboardActions.GET_DASHBOARD_CATEGORIES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.GET_DASHBOARD_CATEGORIES_FAILURE });
    }
}
export function* getCategoriesServiceSaga() {
    yield takeLatest(dashboardActions.GET_DASHBOARD_CATEGORIES_REQUEST, getCategoriesService);
}

//GET SERVICES BY CATEGORIE ID
function* getServicesBycategoryId(action) {
    try {
        for (let i = 0; action.page >= i; i++) {
            const response = yield call(getServicesBycategoryIdCALL, action.categoryId, i);
            if (response.status === 200) {
                let totalServices = response.data.count;
                if (i === 0) {
                    var servicesBycategoryId = [];

                    for (let i in response.data.data) {
                        let service = response.data.data[i].articulo.toLowerCase().split(' ').join('-');

                        function replaceSpecialChars(service) {
                            return service.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                                .replace(/ *\([^)]*\) */g, "")
                                // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                                .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                                .replace(/%20/g, " ");
                        }

                        const serviceKey = replaceSpecialChars(service);

                        servicesBycategoryId.push({
                            key: serviceKey,
                            title: response.data.data[i].articulo,
                            description: response.data.data[i].descripcion,
                            id: response.data.data[i].idArticulo,
                            active: response.data.data[i].activo,
                            img: response.data.data[i].foto,
                            price: response.data.data[i].precio
                        });
                    }

                }
                else {
                    servicesBycategoryId = [...servicesBycategoryId];
                    for (let i in response.data.data) {
                        let service = response.data.data[i].articulo.toLowerCase().split(' ').join('-');

                        function replaceSpecialChars(service) {
                            return service.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                                .replace(/ *\([^)]*\) */g, "")
                                // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                                .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                                .replace(/%20/g, " ");
                        }

                        const serviceKey = replaceSpecialChars(service);

                        servicesBycategoryId.push({
                            key: serviceKey,
                            title: response.data.data[i].articulo,
                            description: response.data.data[i].descripcion,
                            id: response.data.data[i].idArticulo,
                            active: response.data.data[i].activo,
                            img: response.data.data[i].foto,
                            price: response.data.data[i].precio
                        });
                    }
                }
                yield put({ type: dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_SUCCESS, servicesBycategoryId, totalServices });
            }
            else {
                yield put({ type: dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_FAILURE });
            }
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_FAILURE });
    }
}
export function* getServicesBycategoryIdSaga() {
    yield takeLatest(dashboardActions.GET_SERVICES_BY_CATEGORIE_ID_REQUEST, getServicesBycategoryId);
}

//********************** GET TOP SERVICES *****************************
function* getTopServices(action) {
    try {
        let page = action.page - 1;

        var response = yield call(getTopServicesCALL, page);

        if (response.status === 200) {
            let topServices = []

            response.data.data.forEach(topService => {
                let service = topService.articulo.toLowerCase().split(' ').join('-');

                function replaceSpecialChars(service) {
                    return service.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                        .replace(/ *\([^)]*\) */g, "")
                        // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                        .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                        .replace(/%20/g, " ");
                }

                const serviceKey = replaceSpecialChars(service);

                topServices.push({
                    key: serviceKey,
                    title: topService.articulo,
                    id: topService.idArticulo,
                    img: topService.foto,
                    price: topService.precio,
                    description: topService.descripcion
                });
            });


            const totalTopServices = response.data.count
            yield put({ type: dashboardActions.GET_TOP_SERVICES_SUCCESS, topServices, totalTopServices });
        }

        else {
            yield put({ type: dashboardActions.GET_TOP_SERVICES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.GET_TOP_SERVICES_FAILURE });
    }
}
export function* getTopServicesSaga() {
    yield takeLatest(dashboardActions.GET_TOP_SERVICES_REQUEST, getTopServices);
}

//GET BRANDS BY CATEGORIE ID
function* getBrandsBycategoryId(action) {
    try {
        const response = yield call(getBrandsBycategoryIdCALL, action.categoryId);
        if (response.status === 200) {
            let brandsBycategoryId = [];

            for (let i in response.data.info.content) {
                let brand = response.data.info.content[i].marca.toLowerCase().split(' ').join('-');

                function replaceSpecialChars(brand) {
                    return brand.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                        .replace(/ *\([^)]*\) */g, "")
                        // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                        .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                        .replace(/%20/g, " ");
                }

                const brandKey = replaceSpecialChars(brand);

                brandsBycategoryId.push({
                    key: response.data.info.content[i].idMarca + '-' + brandKey,
                    name: response.data.info.content[i].marca,
                    id: response.data.info.content[i].idMarca,
                    active: response.data.info.content[i].activo,
                    img: response.data.info.content[i].img,
                });
            }

            yield put({ type: dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_SUCCESS, brandsBycategoryId });
        }
        else {
            yield put({ type: dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_FAILURE });
    }
}
export function* getBrandsBycategoryIdSaga() {
    yield takeLatest(dashboardActions.GET_BRANDS_BY_CATEGORIE_ID_REQUEST, getBrandsBycategoryId);
}

//GET SERVICES BY CATEGORIE ID
function* getServicesSearch(action) {
    try {
        for (let i = 0; action.page >= i; i++) {

            const response = yield call(getServicesSearchCALL, action.searchValue, i);

            if (response.status === 200) {
                let totalServicesSearchResults = response.data.count;
                if (i === 0) {
                    var servicesSearchResults = [];

                    for (let i in response.data.data) {
                        let service = response.data.data[i].articulo.toLowerCase().split(' ').join('-');

                        function replaceSpecialChars(service) {
                            return service.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                                .replace(/ *\([^)]*\) */g, "")
                                // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                                .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                                .replace(/%20/g, " ");
                        }

                        const serviceKey = replaceSpecialChars(service);

                        servicesSearchResults.push({
                            key: serviceKey,
                            title: response.data.data[i].articulo,
                            description: response.data.data[i].descripcion,
                            id: response.data.data[i].idArticulo,
                            active: response.data.data[i].activo,
                            img: response.data.data[i].foto,
                            price: response.data.data[i].precio
                        });
                    }

                }
                else {
                    servicesSearchResults = [...servicesSearchResults];
                    for (let i in response.data.data) {
                        let service = response.data.data[i].articulo.toLowerCase().split(' ').join('-');

                        function replaceSpecialChars(service) {
                            return service.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                                .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                                .replace(/ *\([^)]*\) */g, "")
                                // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                                .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                                .replace(/%20/g, " ");
                        }

                        const serviceKey = replaceSpecialChars(service);

                        servicesSearchResults.push({
                            key: serviceKey,
                            title: response.data.data[i].articulo,
                            description: response.data.data[i].descripcion,
                            id: response.data.data[i].idArticulo,
                            active: response.data.data[i].activo,
                            img: response.data.data[i].foto,
                            price: response.data.data[i].precio
                        });
                    }
                }
                yield put({ type: dashboardActions.GET_SERVICES_SEARCH_SUCCESS, servicesSearchResults, totalServicesSearchResults });
            }
            else {
                yield put({ type: dashboardActions.GET_SERVICES_SEARCH_FAILURE });
            }
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.GET_SERVICES_SEARCH_FAILURE });
    }
}
export function* getServicesSearchSaga() {
    yield takeLatest(dashboardActions.GET_SERVICES_SEARCH_REQUEST, getServicesSearch);
}

//GET USER DATA
function* getUserData() {
    try {
        const token = sessionStorage.getItem('token')
        const response = yield call(getUserDataCALL, token)

        if (response.status === 200) {
            const clientID = response.data.idCliente;
            const userData = {
                name: response.data.name,
                lastName: response.data.lastName,
                phone: parseInt(response.data.phone),
                email: response.data.email,
                birthday: moment(response.data.birthday, 'YYYY-MM-DD'),
                userID: response.data.idUsuario,
                address: response.data.address,
                clientID: response.data.idCliente
            }

            yield call(getUserOrders, clientID)

            yield put({ type: dashboardActions.GET_USER_DATA_SUCCESS, userData })
        } else {
            yield put({ type: dashboardActions.GET_USER_DATA_FAILURE })
        }
    }
    catch (error) {
        yield put({ type: dashboardActions.GET_USER_DATA_FAILURE })
    }
}
export function* getUserDataSaga() {
    yield takeLatest(dashboardActions.GET_USER_DATA_REQUEST, getUserData)
}

//EDIT USER DATA
function* editUserData(action) {
    try {
        const token = sessionStorage.getItem('token')
        const userID = action.userData.userID
        const clientID = action.userData.clientID;

        const data = {
            "email": action.values.email,
            "name": action.values.name,
            "lastName": action.values.lastName,
            "phone": action.values.phone,
            "birthday": moment(action.values.birthday._d).add(1, 'days'),
            "address": action.userData.address
        };


        const response = yield call(editUserDataCALL, token, userID, clientID, data)

        if (response.status === 200) {
            const userData = response.data;
            const showSuccessMsg = true;
            const textMessage = `Se actualizó la información de ${action.values.email}`;

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: dashboardActions.EDIT_USER_DATA_SUCCESS, userData })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: dashboardActions.EDIT_USER_DATA_FAILURE })
        }
    }
    catch (error) {
        yield put({ type: dashboardActions.EDIT_USER_DATA_FAILURE })
    }
}
export function* editUserDataSaga() {
    yield takeLatest(dashboardActions.EDIT_USER_DATA_REQUEST, editUserData)
}

//GET USER ORDERS
function* getUserOrders(clientID) {
    try {
        const token = sessionStorage.getItem('token')

        const response = yield call(getUserOrdersCALL, clientID, token)

        if (response.status === 200) {
            let orders = [];

            response.data.data.forEach(order => {
                orders.push({
                    orderID: order.idPedido,
                    creationDate: moment(order.fechaPedido).format('LL'),
                    scheduleDate: order.fechaProgramada ? moment(order.fechaProgramada).format('LL') : 'Por asignar',
                    scheduleHour: order.horaProgramada ? (order.horaProgramada + ' Hrs') : 'Por asignar',
                    paymentMethod: order.tipoPago,
                    price: order.total,
                    status: order.estado,
                    fixer: order.fixerById ? `${order.fixerById.name} ${order.fixerById.lastName}` : 'Por asignar',
                    address: order.addressTest ? order.addressTest : 'No se registró dirección.'
                })
            });

            yield put({ type: dashboardActions.GET_USER_ORDERS_SUCCESS, orders })
        } else {
            yield put({ type: dashboardActions.GET_USER_ORDERS_FAILURE })
        }

    }
    catch (error) {
        yield put({ type: dashboardActions.GET_USER_ORDERS_FAILURE })
    }
}
export function* getUserOrdersSaga() {
    yield takeLatest(dashboardActions.GET_USER_ORDERS_REQUEST, getUserOrders)
}

//CREATE USER ORDER
function* createUserOrder(action) {
    try {
        const token = sessionStorage.getItem('token')
        const address = localStorage.getItem('address')

        const reducer = (accumulator, currentValue) => accumulator + currentValue.price;
        const total = action.cart.reduce(reducer, 0);

        const data = {
            "servicios": JSON.stringify(action.cart),
            "total": total,
            "addressTest": address,
        }

        const response = yield call(createUserOrderCALL, token, data)

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = 'Tu pedido se generó con éxito.';

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: dashboardActions.CREATE_USER_ORDER_SUCCESS })
        } else {
            const showErrorMsg = true;
            const textMessage = 'Lo sentimos, algo salió mal al generar tu pedido. Por favor recarga la pagina o contacta aun administrador';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: dashboardActions.CREATE_USER_ORDER_FAILURE })
        }
    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.CREATE_USER_ORDER_FAILURE })
    }
}
export function* createUserOrderSaga() {
    yield takeLatest(dashboardActions.CREATE_USER_ORDER_REQUEST, createUserOrder)
}

//STRIPE CREATE PAYMENT INTENT
function* createPaymentIntent(action) {
    try {
        const reducer = (accumulator, currentValue) => accumulator + currentValue.price;
        const total = action.cart.reduce(reducer, 0);
        const data = { "carrito": `total: ${total}.00` }
        const response = yield call(createPaymentIntentCALL, data)

        if (response.status === 200) {
            yield put({ type: dashboardActions.CREATE_PAYMENT_INTENT_SUCCESS, clientSecret: response.data.clientSecret })
        } else {
            const showErrorMsg = true;
            const textMessage = 'Error. Intente de nuevo.';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: dashboardActions.CREATE_PAYMENT_INTENT_FAILURE })
        }
    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: dashboardActions.CREATE_PAYMENT_INTENT_FAILURE })
    }
}
export function* createPaymentIntentSaga() {
    yield takeLatest(dashboardActions.CREATE_PAYMENT_INTENT_REQUEST, createPaymentIntent)
}