import React, { Component } from 'react';
import { connect } from "react-redux";

import { notification } from 'antd';

class AlertMessage extends Component {

  render() {

    const { showSuccessMsg, showErrorMsg, onShowSuccessMsg, onShowErrorMsg, textMessage } = this.props;

    function ShowMessage() {

      if (showSuccessMsg) {
        notification['success']({
          message: textMessage,
          placement: 'top',
          duration: 3
      });
        onShowSuccessMsg();
      }

      else if (showErrorMsg) {
        notification['error']({
          message: textMessage,
          placement: 'top',
          duration: 3
      });
        onShowErrorMsg();
      }

      return (
        <></>
      );
    }

    return (<ShowMessage />);
  }
}

const mapStateToProps = state => {
  return {
    showSuccessMsg: state.AlertReducer.showSuccessMsg,
    showErrorMsg: state.AlertReducer.showErrorMsg,
    textMessage: state.AlertReducer.textMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowSuccessMsg: () => {
      dispatch({ type: "SHOW_SUCCESS_MSG", showSuccessMsg: false, textMessage: null });
    },

    onShowErrorMsg: () => {
      dispatch({ type: "SHOW_ERROR_MSG", showErrorMsg: false, textMessage: null });
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
