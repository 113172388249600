import axios from 'axios'

const endpoint = "https://sissa-fixer.herokuapp.com/";

//categories
export function getDashboardCategoriesCALL() {
	return axios({
		method: "get",
		url: endpoint + "categoriasArticulos",
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//services by category id
export function getServicesBycategoryIdCALL(id, page) {
	return axios({
		method: "get",
		url: endpoint + "articulos?page=" + page + "&offset=10&idCategoriaArticulo=" + id,
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//services search by item
export function getServicesSearchCALL(search, page) {
	return axios({
		method: "get",
		url: endpoint + "articulos?page=" + page + "&offset=10&articulo=" + search,
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//get top services
export function getTopServicesCALL(page) {
	return axios({
		method: "get",
		url: endpoint + "articulos/articulosTop?page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//brands
export function getBrandsBycategoryIdCALL(id) {
	return axios({
		method: "get",
		url: endpoint + "marcas?page=0&offset=30&order=idMarca&direction=ASC&idCategoriaArticulo=" + id,
		headers: { 'Content-Type': 'application/json' },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//account-profile get user data
export function getUserDataCALL(token) {
	return axios({
		method: 'get',
		url: endpoint + 'clientes',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//account-profile edit user data
export function editUserDataCALL(token, user, client, data) {
	return axios({
		method: 'put',
		url: `${endpoint}clientes/${user}?idCliente=${client}`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//account-orders get orders
export function getUserOrdersCALL(userID, token) {
	return axios({
		method: 'get',
		url: endpoint + 'pedidos?page=0&offset=10&idClient=' + userID,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//checkout
export function createPaymentIntentCALL(data) {
	return axios({
		method: 'post',
		url: `${endpoint}pago/create-payment-intent`,
		headers: { "Content-Type": "application/json" },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error;
	})
}

//
export function createUserOrderCALL(token, data) {
	return axios({
		method: 'post',
		url: `${endpoint}pedidos`,
		headers: { "Content-Type": "application/json", 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error;
	})
}