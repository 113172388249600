import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoginForm from '../../Components/Login/LoginForm';
import SignUpForm from '../../Components/Login/SignUpForm';
import SignUpFixerForm from '../../Components/Login/SignUpFixerForm';
import ResetPasswordForm from '../../Components/Login/ResetPasswordForm';
import MetaDecorator from '../../Components/Util/MetaDecorator';
import fixersLogo from '../../Assets/Logos/logo_fixers_color.png'

export class LoginContainer extends Component {
    render() {
        const { vistaLogin } = this.props;

        const ContentApp = () => {
            if (vistaLogin === 'sign-up') {
                return (
                    <>
                        <MetaDecorator title="Fixers || Registro" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <SignUpForm />
                    </>
                )
            }
            else if (vistaLogin === 'recover-password') {
                return (
                    <>
                        <MetaDecorator title="Fixers || Recuperar contraseña" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <ResetPasswordForm />
                    </>
                )
            }
            else if (vistaLogin === 'sign-up-fixer') {
                return (
                    <>
                        <MetaDecorator title="Fixers || Recuperar contraseña" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <SignUpFixerForm />
                    </>
                )
            }
            else {
                return (
                    <>
                        <MetaDecorator title="Fixers || Iniciar sesión" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <LoginForm />
                    </>
                )
            }
        };

        return (
            <ContentApp />
        )
    }
}

const mapStateToProps = state => {
    return {
        vistaLogin: state.LoginReducer.vistaLogin,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)