import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, Button, Card, Row, Col, Modal } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

import NewCategory from './NewCategory';
import EditCategory from './EditCategory';

const confirm = Modal.confirm;

function CategoriesList() {
    const [isVisibleCreate, setIsVisibleCreate] = useState(false)
    const [isVisibleEdit, setIsVisibleEdit] = useState(false)
    const [categoryInfo, setCategoryInfo] = useState([])

    const dispatch = useDispatch();
    const fetchingGetCategories = useSelector((state) => state.AdminReducer.fetchingGetCategories);
    const categories = useSelector((state) => state.AdminReducer.categories);

    useEffect(() => {
        dispatch({ type: "GET_NOTE_CATEGORIES_REQUEST" });
    }, [dispatch]);

    const showDeleteConfirm = (id, name) => {
        confirm({
            title: '¿Estás seguro que deseas eliminar la categoría  "' + name + '"?',
            content: '',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                dispatch({ type: "DELETE_NOTE_CATEGORY_REQUEST", id });
            },
            onCancel() {
            },
        });
    };

    return (
        <>
            <Row type="flex" justify="space-between" align='middle' gutter={[0, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}></Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}></Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Button style={{ width: '100%' }} type="primary" className="login-btn"
                        onClick={() => setIsVisibleCreate(true)}
                    >Nueva Categoría</Button>
                </Col>
            </Row>
            <br />
            <Card title={
                <div className="header-list">CATEGORÍAS</div>}>
                <List
                    itemLayout="horizontal"
                    loading={fetchingGetCategories}
                    locale={{ emptyText: 'Sin categorías' }}
                    dataSource={categories}
                    renderItem={item => (
                        <List.Item
                            key={`blog-category-0${item.idCategoriaNota}`}
                            actions={[
                                <span
                                    onClick={() => showDeleteConfirm(item.idCategoriaNota, item.categoriaNota)}
                                ><DeleteTwoTone className="icon" /></span>,
                                <span
                                    onClick={() => {
                                        setIsVisibleEdit(!isVisibleEdit);
                                        setCategoryInfo(item);
                                    }}
                                ><EditTwoTone className="icon" /></span>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.categoriaNota}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <NewCategory setIsVisibleCreate={setIsVisibleCreate} isVisibleCreate={isVisibleCreate} />
            <EditCategory setIsVisibleEdit={setIsVisibleEdit} isVisibleEdit={isVisibleEdit} categoryInfo={categoryInfo} />
        </>
    )
}

export default CategoriesList