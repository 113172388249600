import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, Button, Card, Row, Col, Avatar, Pagination, Select, Modal } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

import * as AdminActions from '../../../Store/Actions/AdminActions';
import NewBrand from './NewBrand';
import EditBrand from './EditBrand';

const confirm = Modal.confirm;
const { Option } = Select;

function BrandsList() {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [isVisibleCNB, setIsVisibleCNB] = useState(false);
    const [isVisibleEB, setIsVisibleEB] = useState(false);

    const dispatch = useDispatch();
    const fetchingGetBrands = useSelector((state) => state.AdminReducer.fetchingGetBrands);
    const brands = useSelector((state) => state.AdminReducer.brands);
    const categories = useSelector((state) => state.AdminReducer.serviceCategories)

    useEffect(() => {
        dispatch({ type: "GET_BRANDS_REQUEST", page, id });
        dispatch({ type: "GET_SERVICE_CATEGORIES_REQUEST" });
    }, [dispatch, page, id]);

    const showDeleteConfirm = (brandId, brand) => {
        confirm({
            title: '¿Estás seguro que deseas eliminar la marca  "' + brand + '"?',
            content: '',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                dispatch({ type: "DELETE_BRAND_REQUEST", brandId });
                setId();
                setPage(1);
            },
            onCancel() {
            },
        });
    };

    const onChangePage = (value) => {
        setPage(value)
    }

    const onChange = (value) => {
        setPage(1)
        setId(value)
    };

    const clearFilters = () => {
        setPage(1)
        setId(null)
    }

    return (
        <>
            <Row type="flex" justify="space-between" align='middle' gutter={[0, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}></Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Select
                        value={id}
                        allowClear
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Selecciona una categoría"
                        optionFilterProp="children"
                        onChange={onChange}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {categories.map(category =>
                            <Option value={category.idCategoriaArticulo} key={category.idCategoriaArticulo}>
                                {category.categoriaArticulo}
                            </Option>
                        )}
                    </Select>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Button style={{ width: '100%' }} type="primary" className="login-btn"
                        onClick={() => setIsVisibleCNB(true)}
                    >Nueva marca</Button>
                </Col>
            </Row>
            <br />
            <Card title={
                <div className="header-list">MARCAS</div>}>
                <List
                    itemLayout="horizontal"
                    loading={fetchingGetBrands}
                    locale={{ emptyText: 'Sin marcas' }}
                    dataSource={brands.content}
                    renderItem={item => (
                        <List.Item
                            key={`service-category-0${item.idMarca}`}
                            actions={[
                                <span
                                    onClick={() => showDeleteConfirm(item.idMarca, item.marca)}
                                ><DeleteTwoTone className="icon" /></span>,
                                <span
                                    onClick={() => {
                                        dispatch({ type: AdminActions.GET_BRAND_BY_ID_REQUEST, id: item.idMarca })
                                        setIsVisibleEB(!isVisibleEB);
                                    }}
                                ><EditTwoTone className="icon" /></span>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.marca}
                                avatar={<Avatar src={item.img} />}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <br />
            <Pagination current={page} total={brands.totalElements} onChange={onChangePage} />
            <br />
            <NewBrand
                setIsVisibleCNB={setIsVisibleCNB}
                isVisibleCNB={isVisibleCNB}
                clearFilters={clearFilters}
            />
            <EditBrand
                setIsVisibleEB={setIsVisibleEB}
                isVisibleEB={isVisibleEB}
                categories={categories}
                clearFilters={clearFilters}
            />
        </>
    )
}

export default BrandsList