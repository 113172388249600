//Log in actions
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_GOOGLE_REQUEST = "LOGIN_GOOGLE_REQUEST";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_SUCCESS";
export const LOGIN_GOOGLE_FAILURE = "LOGIN_GOOGLE_FAILURE";

export const SIGN_UP_CLIENT_REQUEST = "SIGN_UP_CLIENT_REQUEST";
export const SIGN_UP_CLIENT_SUCCESS = "SIGN_UP_CLIENT_SUCCESS";
export const SIGN_UP_CLIENT_FAILURE = "SIGN_UP_CLIENT_FAILURE";

export const SIGN_UP_FIXER_REQUEST = "SIGN_UP_FIXER_REQUEST";
export const SIGN_UP_FIXER_SUCCESS = "SIGN_UP_FIXER_SUCCESS";
export const SIGN_UP_FIXER_FAILURE = "SIGN_UP_FIXER_FAILURE";

export const SIGN_UP_CLIENT_GOOGLE_REQUEST = "SIGN_UP_CLIENT_GOOGLE_REQUEST";
export const SIGN_UP_CLIENT_GOOGLE_SUCCESS = "SIGN_UP_CLIENT_GOOGLE_SUCCESS";
export const SIGN_UP_CLIENT_GOOGLE_FAILURE = "SIGN_UP_CLIENT_GOOGLE_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const SHOW_MODAL_LOGIN = "SHOW_MODAL_LOGIN";

export const SHOW_MODAL_SIGN_UP = "SHOW_MODAL_SIGN_UP";

export const SHOW_MODAL_RESET_PASSWORD = "SHOW_MODAL_RESET_PASSWORD";

export const CHANGE_VISTA_LOGIN = 'CHANGE_VISTA_LOGIN';

export const SHOW_ERROR_MSG = "SHOW_ERROR_MSG";
export const SHOW_SUCCESS_MSG = "SHOW_SUCCESS_MSG";