import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Form, Button, Input, Modal, Upload, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';

const NewCategory = ({ isVisibleCC, toggleNewCategoryModal, }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fetchingCreateServiceCategory = useSelector(state => state.AdminReducer.fetchingCreateServiceCategory);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [form])


    const onFinish = (values) => {
        if (!fetchingCreateServiceCategory && image) {
            dispatch({ type: "CREATE_SERVICE_CATEGORY_REQUEST", form: values, image });
            toggleNewCategoryModal();
            setImage(null)
        }else{
            message.error('Agrega una imagen.')
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false)
                setImage(url)
            });
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const onCancel = () => {
        toggleNewCategoryModal();
        form.resetFields();
        setImage(null);
    }

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title="Crear categoría"
            open={isVisibleCC}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="createCategory" key="submit" type="primary"
                    loading={fetchingCreateServiceCategory}
                >
                    Crear
                </Button>
            ]}
        >
            <Form
                id="createCategory"
                name="createCategory"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    name="categoryName"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Nombre de la categoría" />
                </Form.Item>
            </Form>
            <Upload.Dragger
                name="files"
                onChange={handleChange}
                multiple={false}
                showUploadList={false}
                className='dragger'
                beforeUpload={beforeUpload}
            >
                {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload.Dragger>
        </Modal>
    )
}

export default NewCategory