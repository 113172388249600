//change view
export const CHANGE_VISTA_DASHBOARD = 'CHANGE_VISTA_DASHBOARD';
export const CHANGE_VISTA_ADDRESS = 'CHANGE_VISTA_ADDRESS';
export const SAVE_ADDRESS = 'SAVE_ADDRESS';

//dashboard drawers and modals
export const SHOW_CART_DRAWER = 'SHOW_CART_DRAWER';
export const SHOW_SEARCH_DRAWER = 'SHOW_SEARCH_DRAWER';
export const SHOW_MENU_DRAWER = 'SHOW_MENU_DRAWER';
export const SHOW_ADDRESS_MODAL = 'SHOW_ADDRESS_MODAL';
export const SHOW_SERVICE_DETAIL = "SHOW_SERVICE_DETAIL";

//dashboard categories
export const GET_DASHBOARD_CATEGORIES_REQUEST = 'GET_DASHBOARD_CATEGORIES_REQUEST';
export const GET_DASHBOARD_CATEGORIES_SUCCESS = 'GET_DASHBOARD_CATEGORIES_SUCCESS';
export const GET_DASHBOARD_CATEGORIES_FAILURE = 'GET_DASHBOARD_CATEGORIES_FAILURE';

//dashboard services by categoy
export const GET_SERVICES_BY_CATEGORIE_ID_REQUEST = 'GET_SERVICES_BY_CATEGORIE_ID_REQUEST';
export const GET_SERVICES_BY_CATEGORIE_ID_SUCCESS = 'GET_SERVICES_BY_CATEGORIE_ID_SUCCESS';
export const GET_SERVICES_BY_CATEGORIE_ID_FAILURE = 'GET_SERVICES_BY_CATEGORIE_ID_FAILURE';
export const GET_MORE_SERVICES_BY_CATEGORIE_ID = 'GET_MORE_SERVICES_BY_CATEGORIE_ID';

//dashboard brands by category
export const GET_BRANDS_BY_CATEGORIE_ID_REQUEST = 'GET_BRANDS_BY_CATEGORIE_ID_REQUEST';
export const GET_BRANDS_BY_CATEGORIE_ID_SUCCESS = 'GET_BRANDS_BY_CATEGORIE_ID_SUCCESS';
export const GET_BRANDS_BY_CATEGORIE_ID_FAILURE = 'GET_BRANDS_BY_CATEGORIE_ID_FAILURE';

//dashboard services search
export const GET_SERVICES_SEARCH_REQUEST = 'GET_SERVICES_SEARCH_REQUEST';
export const GET_SERVICES_SEARCH_SUCCESS = 'GET_SERVICES_SEARCH_SUCCESS';
export const GET_SERVICES_SEARCH_FAILURE = 'GET_SERVICES_SEARCH_FAILURE';
export const GET_MORE_SERVICES_SEARCH = 'GET_MORE_SERVICES_SEARCH';

//get top services
export const GET_TOP_SERVICES_REQUEST = "GET_TOP_SERVICES_REQUEST";
export const GET_TOP_SERVICES_SUCCESS = "GET_TOP_SERVICES_SUCCESS";
export const GET_TOP_SERVICES_FAILURE = "GET_TOP_SERVICES_FAILURE";

//cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';

//account-profile
export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const EDIT_USER_DATA_REQUEST = 'EDIT_USER_DATA_REQUEST';
export const EDIT_USER_DATA_SUCCESS = 'EDIT_USER_DATA_SUCCESS';
export const EDIT_USER_DATA_FAILURE = 'EDIT_USER_DATA_FAILURE';

//account-orders
export const GET_USER_ORDERS_REQUEST = 'GET_USER_ORDERS_REQUEST';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';
export const GET_USER_ORDERS_FAILURE = 'GET_USER_ORDERS_FAILURE';

export const CREATE_USER_ORDER_REQUEST = 'CREATE_USER_ORDER_REQUEST';
export const CREATE_USER_ORDER_SUCCESS = 'CREATE_USER_ORDER_SUCCESS';
export const CREATE_USER_ORDER_FAILURE = 'CREATE_USER_ORDER_FAILURE';

export const UPDATE_USER_ORDER_REQUEST = 'UPDATE_USER_ORDER_REQUEST';
export const UPDATE_USER_ORDER_SUCCESS = 'UPDATE_USER_ORDER_SUCCESS';
export const UPDATE_USER_ORDER_FAILURE = 'UPDATE_USER_ORDER_FAILURE';

//checkout stripe
export const CREATE_PAYMENT_INTENT_REQUEST = 'CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAILURE = 'CREATE_PAYMENT_INTENT_FAILURE';
