import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tabs, Row, Col } from 'antd';

import MetaDecorator from '../../Components/Util/MetaDecorator';
import fixersLogo from '../../Assets/Logos/logo_fixers_color.png';
import CategoriesList from '../../Components/Admin/Categories/CategoriesList';
import NotesList from '../../Components/Admin/Notes/NotesList';
import ServiceCategoriesList from '../../Components/Admin/Services Categories/ServiceCategoriesList';
import ServicesList from '../../Components/Admin/Services/ServicesList';
import BrandsList from '../../Components/Admin/Brands/BrandsList';
import FixersList from '../../Components/Admin/Fixers/FixersList';
import OrdersList from '../../Components/Admin/Orders/OrdersList';

const { TabPane } = Tabs;

class AdminContainer extends Component {
    render() {
        const { vistaAdmin } = this.props;

        const ContentApp = () => {
            if (vistaAdmin === 'content') {
                return (
                    <>
                        <MetaDecorator title="Fixers || Admin Content" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <Tabs defaultActiveKey="3">
                            <TabPane tab="Categorias" key="3">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <ServiceCategoriesList />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Servicios" key="4">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <ServicesList />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Marcas" key="5">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <BrandsList />
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </>
                )
            }
            else if (vistaAdmin === 'fixers') {
                return (
                    <>
                        <MetaDecorator title="Fixers || Admin Usuarios & Pedidos" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <Tabs defaultActiveKey="6">
                            <TabPane tab="Fixers" key="6">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <FixersList />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Pedidos" key="7">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <OrdersList />
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tab="Clientes" key="8">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>

                                    </Col>
                                </Row>
                            </TabPane> */}
                        </Tabs>
                    </>
                )
            }
            else {
                return (
                    <>
                        <MetaDecorator title="Fixers || Admin Blog" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Notas" key="1">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <NotesList />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Categorías" key="2">
                                <Row type="flex" justify="space-around">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <CategoriesList />
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </>
                )
            }
        };

        return (
            <div className='admin-content'>
                <ContentApp />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaAdmin: state.AdminReducer.vistaAdmin,
        fetchingSignup: state.LoginReducer.fetchingSignup,
    };
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)