import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Upload, Select, InputNumber, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined, DollarOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

function NewService({ isVisibleCS, setIsVisibleCS, clearFilters }) {
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const fetchingCreateService = useSelector((state) => state.AdminReducer.fetchingCreateService);
    const categories = useSelector((state) => state.AdminReducer.serviceCategories);

    useEffect(() => {
        dispatch({ type: "GET_SERVICE_CATEGORIES_REQUEST" });
        form.resetFields()
    }, [dispatch, form]);

    const onFinish = (values) => {
        if (!fetchingCreateService && image) {
            dispatch({ type: "CREATE_SERVICE_REQUEST", form: values, image });
            setIsVisibleCS(!isVisibleCS);
            form.resetFields();
            setImage(null)
            clearFilters();
        }
        else{
            message.error('Agrega una imagen.')
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false)
                setImage(url)
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const onCancel = () => {
        setIsVisibleCS(false);
        form.resetFields();
        setImage(null)
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            initialValues={[]}
            title="Crear servicio"
            open={isVisibleCS}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="createService" key="submit" type="primary"
                    loading={fetchingCreateService}
                >
                    Crear
                </Button>
            ]}
        >
            <Form
                id="createService"
                name="createService"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    name="serviceName"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Nombre del servicio (Máximo 50 caracteres)" />
                </Form.Item>
                <Form.Item
                    name="price"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <InputNumber
                        min={10}
                        style={{ width: '100%' }}
                        prefix={<DollarOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Precio/costo del servicio"
                    />
                </Form.Item>
                <Form.Item
                    name="categoryId"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <Select
                        placeholder="Categoría"
                        allowClear
                        style={{ width: '100%' }}
                    >
                        {categories.map(category =>
                            <Option value={category.idCategoriaArticulo} key={category.idCategoriaArticulo}>
                                {category.categoriaArticulo}
                            </Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                >
                    <TextArea placeholder="Descripción (Máximo 100 caracteres)" maxLength={100} />
                </Form.Item>
            </Form>
            <Upload.Dragger
                name="files"
                onChange={handleChange}
                multiple={false}
                showUploadList={false}
                className='dragger'
                beforeUpload={beforeUpload}
            >
                {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload.Dragger>
        </Modal>
    )
}

export default NewService