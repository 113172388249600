import React from 'react'
import { useDispatch } from 'react-redux';

import { Row, Col, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { useWindowSize } from '../../Hooks/useWindowSize';

function ServiceItem({ image, title, description, price, item }) {
    const { width } = useWindowSize();
    const dispatch = useDispatch();

    const addToCart = (item) => {
        dispatch({ type: "ADD_TO_CART", item });
    }

    const showServiceDetail = (item) => {
        dispatch({ type: "SHOW_SERVICE_DETAIL", showServiceDetail: true, service: item });
    }

    return (
        <>
            {width > 425 ?
                <Row justify='space-between' align='middle' className='service-card'>
                    <Col span={8}>
                        <img onClick={() => showServiceDetail(item)} style={{ cursor: 'pointer' }} src={image} alt="card-img" />
                    </Col>
                    <Col span={16}>
                        <div onClick={() => showServiceDetail(item)} style={{ cursor: 'pointer' }}>
                            <h2>{title}</h2>
                            <p>{description}</p>
                            <b>${price}</b>
                        </div>
                        <Button onClick={() => addToCart(item)} type='text' shape='circle' size='middle' icon={<PlusOutlined />} />
                    </Col>
                </Row>
                :
                <Row justify='space-between' align='middle' className='service-card'>
                    <Col span={8}>
                        <img onClick={() => showServiceDetail(item)} style={{ cursor: 'pointer' }} src={image} alt="card-img" />
                    </Col>
                    <Col span={16}>
                        <div onClick={() => showServiceDetail(item)} style={{ cursor: 'pointer' }} className='service-card-text'>
                            <h3>{title}</h3>
                            <p>{description}</p>
                            <b>${price}</b>
                        </div>
                        <Button onClick={() => addToCart(item)} type='text' shape='circle' size='middle' icon={<PlusOutlined />} />
                    </Col>
                </Row>
            }
        </>
    )
}

export default ServiceItem