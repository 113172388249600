import React from 'react'

import MetaDecorator from '../../Components/Util/MetaDecorator';
import LoginHeader from '../../Components/Headers/LoginHeader';
import ProfileContent from '../../Components/User Client/ProfileContent';
import fixersLogo from '../../Assets/Logos/fixers-logo.png';

function ProfileContainer() {
    return (
        <>
            <MetaDecorator title="Fixers || Mi Cuenta" description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={fixersLogo} />
            <LoginHeader />
            <ProfileContent />
        </>
    )
}

export default ProfileContainer