import React, { Component } from 'react'
import { connect } from 'react-redux';

import { Card, Skeleton, Empty } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

import plomeria from '../../Assets/LandingPage/serviciosPlomeria.png'
import electricidad from '../../Assets/LandingPage/serviciosElectricidad.png'
import soporteTecnico from '../../Assets/LandingPage/serviciosSoporteTecnico.png'

const { Meta } = Card;

export class DashboardTopServices extends Component {
    state = {
        width: 0,
        height: 0,
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        this.props.onGetTopServices();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        const { topServices, fetchingGetTopServices, onShowServiceDetail } = this.props;

        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 1601 },
                items: 10
            },
            desktop: {
                breakpoint: { max: 1600, min: 1201 },
                items: 7
            },
            laptop: {
                breakpoint: { max: 1200, min: 1024 },
                items: 6
            },
            tablet: {
                breakpoint: { max: 1024, min: 768 },
                items: 4.25
            },
            mobileL: {
                breakpoint: { max: 425, min: 375 },
                items: 2.25
            },
            mobileM: {
                breakpoint: { max: 375, min: 320 },
                items: 2
            },
            mobileS: {
                breakpoint: { max: 320, min: 0 },
                items: 2
            }
        };

        const services = [
            { 'key': 0, 'name': "Soporte Técnico", "img": soporteTecnico, "price": "$300" },
            { 'key': 1, 'name': "Electricidad", "img": electricidad, "price": "$300" },
            { 'key': 2, 'name': "Plomería", "img": plomeria, "price": "$300" },
            { 'key': 3, 'name': "Soporte Técnico", "img": soporteTecnico, "price": "$300" },
            { 'key': 4, 'name': "Electricidad", "img": electricidad, "price": "$300" },
            { 'key': 5, 'name': "Plomería", "img": plomeria, "price": "$300" },
            { 'key': 6, 'name': "Soporte Técnico", "img": soporteTecnico, "price": "$300" },
            { 'key': 7, 'name': "Electricidad", "img": electricidad, "price": "$300" },
            { 'key': 8, 'name': "Plomería", "img": plomeria, "price": "$300" },
            { 'key': 9, 'name': "Soporte Técnico", "img": soporteTecnico, "price": "$300" },
            { 'key': 10, 'name': "Electricidad", "img": electricidad, "price": "$300" },
            { 'key': 11, 'name': "Plomería", "img": plomeria, "price": "$300" },
        ];

        const CustomButtonGroupAsArrows = ({ next, previous, goToSlide, ...rest }) => {
            const { carouselState: { currentSlide } } = rest;
            return (
                <div className="carousel-button-group">
                    <LeftOutlined className={currentSlide === 0 ? 'disable' : 'arrow-icon'} onClick={() => previous()} />
                    <RightOutlined className='arrow-icon' onClick={() => next()} />
                </div>
            );
        };

        return (
            <div className='dashboard-top-services'>
                <h2>Top de Servicios</h2>
                {fetchingGetTopServices ?
                    <Carousel
                        className='top-services-carousel'
                        keyboard={false}
                        responsive={responsive}
                        infinite={false}
                        arrows={false}
                        deviceType={this.props.deviceType}
                        customButtonGroup={this.state.width >= 768 ? (<CustomButtonGroupAsArrows />) : (<></>)}
                        renderButtonGroupOutside={this.state.width >= 768 ? true : false}
                    >
                        {services.map((service) =>
                            <Card
                                className='service-card'
                                bordered={false}
                                key={service.key}
                            >
                                <Skeleton.Image className='ant-skeleton-active' size='large' />
                                <Meta className='meta' style={{ marginTop: '10px' }} title={<span><Skeleton.Input active size='small' /></span>} />
                            </Card>
                        )}
                    </Carousel>
                    :
                    topServices.length > 0 ?
                        <Carousel
                            className='top-services-carousel'
                            keyboard={false}
                            responsive={responsive}
                            infinite={false}
                            arrows={false}
                            deviceType={this.props.deviceType}
                            customButtonGroup={this.state.width >= 768 ? (<CustomButtonGroupAsArrows />) : (<></>)}
                            renderButtonGroupOutside={this.state.width >= 768 ? true : false}
                        >
                            {topServices.map((service) =>
                                <Card
                                    hoverable
                                    onClick={() => onShowServiceDetail(service)}
                                    className='service-card'
                                    bordered={false}
                                    key={service.key}
                                    cover={<img alt={service.title} src={service.img} />}
                                >
                                    <Meta className='meta' description={<span>{service.title}</span>} />
                                    <Meta className='meta' description={<span>Aprox. {service.price}</span>} />
                                </Card>
                            )}
                        </Carousel>
                        :
                        <Empty
                            description={
                                <span>Los <b>servicios</b> no se cargaron correctamente. Recarga la página por favor.</span>
                            }
                        />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.DashboardReducer.vistaDashboard,
        topServices: state.DashboardReducer.topServices,
        fetchingGetTopServices: state.DashboardReducer.fetchingGetTopServices,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetTopServices: () => {
            dispatch({ type: "GET_TOP_SERVICES_REQUEST", page: 1 })
        },
        onShowServiceDetail: (service) => {
            dispatch({ type: "SHOW_SERVICE_DETAIL", showServiceDetail: true, service });
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardTopServices)