import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment';
import { Row, Col, DatePicker, Form, Button, Input, Modal, Spin, InputNumber } from 'antd';

import * as AdminActions from '../../../Store/Actions/AdminActions';

function EditFixer({ isVisibleEdit, setIsVisibleEdit, userInfo, clearFilters }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fetchingEditFixer = useSelector(state => state.AdminReducer.fetchingEditFixer);

    useEffect(() => {
        form.resetFields();
    }, [form, userInfo]);

    const onFinish = (values) => {
        dispatch({ type: AdminActions.EDIT_FIXER_REQUEST, form: values, fixer: userInfo });
        setIsVisibleEdit(!isVisibleEdit)
        clearFilters();
        form.resetFields();
    };

    const onCancel = () => {
        form.resetFields();
        setIsVisibleEdit(!isVisibleEdit)
    }

    const disabledDate = (current) => {
        return current && current > moment().subtract(18, 'years');
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar fixer "${userInfo.name}"`} //change to email/username
            open={isVisibleEdit}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="editFixer" key="submit" type="primary"
                    loading={fetchingEditFixer}
                >
                    Guardar cambios
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditFixer} >
                <Form
                    name="editFixer"
                    id='editFixer'
                    labelCol={{ span: 24, }}
                    wrapperCol={{ span: 24, }}
                    initialValues={userInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Row justify='space-between'>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label="Nombre(s)"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nombre del Fixer',
                                    },
                                ]}
                            >
                                <Input maxLength={50} placeholder="Nombre(s)" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label="Apellido(s)"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Apellido del Fixer',
                                    },
                                ]}
                            >
                                <Input maxLength={50} placeholder="Apellido(s)" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Correo Electrónico'
                                name="email"
                                rules={[{ type: 'email', message: 'Escribe un correo electrónico válido.' }, { required: true, message: 'E-mail del Fixer' }]}
                            >
                                <Input
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Fecha de nacimiento'
                                name="birthday"
                                rules={[{ required: true, message: "Fecha de nacimiento del Fixer" }]}
                            >
                                <DatePicker disabledDate={disabledDate} format='DD/MM/YYYY' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Número de teléfono'
                                name="phone"
                                rules={[{ required: true, message: "Número de teléfono o contacto del Fixer" }]}
                            >
                                <InputNumber style={{ width: '100%' }} maxLength={10} placeholder='Número de teléfono' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default EditFixer