import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Form, Input, Divider, Row, Col } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { gapi } from 'gapi-script';

import GoogleButton from './Buttons/GoogleButton';
import FacebookButton from './Buttons/FacebookButton';
import loginImg from '../../Assets/Login/FIXERS_.png'
import '../../Stylesheets/Login/Login.css';

export class LoginForm extends Component {
    state = {
        isEnabled: false
    }

    componentDidMount() {
        function start() {
            gapi.client.init({
                clientId: "722758664014-dmlfjoui2pu12790er1kiauibonnsp49.apps.googleusercontent.com",
                scope: ""
            })
        }

        gapi.load('client:auth2', start)
    }

    render() {
        const { onChangeVistaLogin, onRequestLogin, fetchingLogin, role, isAuthenticated } = this.props;

        if (isAuthenticated) {
            if (role === 'Admin') {
                return (<Redirect to='/admin' />)
            } else if (role === 'Particular') {
                return (<Redirect to='/' />)
            }
        }

        const onFinish = (values) => {
            onRequestLogin(values);
        };

        const handleOnChange = () => {
            this.setState({
                isEnabled: true
            })
        }

        return (
            <Row className='login-container' justify='space-around'>
                <Col xs={0} sm={0} md={0} lg={8} xl={10}>
                    <img src={loginImg} alt="login-img" />
                </Col>
                <Col className='login-form-container' flex="auto">
                    <h2>Iniciar sesión</h2>
                    <div className='login-buttons'>
                        <GoogleButton action={onRequestLogin} actionType={'login'} />
                        <FacebookButton action={onRequestLogin} actionType={'login'} />
                    </div>

                    <div style={{ width: '100%', maxWidth: '320px', margin: '0 auto' }}>
                        <Divider><span style={{ color: '#e6ebf1' }}>o</span></Divider>
                    </div>

                    <Form
                        name="login-form"
                        onFinish={onFinish}
                        initialValues={{ remember: false }}
                        autoComplete="off"
                        id="login-form"
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Escribe tu nombre de usuario' }]}
                        >
                            <Input onChange={handleOnChange} allowClear prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Correo electrónico" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Escribe tu contraseña' }]}
                        >
                            <Input.Password allowClear prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Contraseña" autoComplete="on" />
                        </Form.Item>
                    </Form>

                    <Row style={{ width: '100%', maxWidth: '320px', margin: '0 auto' }} justify="space-between" align='middle'>
                        <Col span={24}>
                            <Button loading={fetchingLogin} className={this.state.isEnabled ? 'submit-btn' : 'disabled-btn'} form='login-form' htmlType='submit' disabled={!this.state.isEnabled}>Iniciar sesión</Button>
                        </Col>
                    </Row>

                    <br />
                    <p><Link to="/login" onClick={() => onChangeVistaLogin('recover-password')} >¿Olvidaste tu contraseña?</Link></p>
                    <p>Aún no te has registrado? <Link to="/login" onClick={() => onChangeVistaLogin('sign-up')} >Regístrate aquí.</Link></p>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        vistaLogin: state.LoginReducer.vistaLogin,
        fetchingLogin: state.LoginReducer.fetchingLogin,
        usuario: state.LoginReducer.usuario,
        errorLogin: state.LoginReducer.errorLogin,
        isAuthenticated: state.LoginReducer.isAuthenticated,
        role: state.LoginReducer.role,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeVistaLogin: (vistaLogin) => {
            dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin })
        },
        onRequestLogin: (values) => {
            dispatch({ type: "LOGIN_REQUEST", values });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)