import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Card, Skeleton, Empty } from 'antd';
import { ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

import soporteTecnico from '../../Assets/Logos/Categories/soporte_tecnico_fixers.png'
import electricidad from '../../Assets/Logos/Categories/electricidad_fixers.png'
import plomeria from '../../Assets/Logos/Categories/plomeria_fixers.png'
import cctv from '../../Assets/Logos/Categories/cctv_fixers.png'

const { Meta } = Card;

export class DashboardCategories extends Component {
    state = {
        width: 0,
        height: 0,
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        this.props.onGetCategories();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        const { onChangeVistaDashboard, dashboardCategories, fetchingDashboardCategories } = this.props;

        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 1601 },
                items: 10
            },
            desktop: {
                breakpoint: { max: 1600, min: 1201 },
                items: 7
            },
            laptop: {
                breakpoint: { max: 1200, min: 1024 },
                items: 6
            },
            tablet: {
                breakpoint: { max: 1024, min: 768 },
                items: 4.25
            },
            mobileL: {
                breakpoint: { max: 425, min: 375 },
                items: 2.25
            },
            mobileM: {
                breakpoint: { max: 375, min: 320 },
                items: 2
            },
            mobileS: {
                breakpoint: { max: 320, min: 0 },
                items: 1.75
            }
        };

        const categories = [
            { 'key': 0, 'name': "Soporte Técnico", "foto": soporteTecnico, 'categorieInfo': 'soporte-tec' },
            { 'key': 1, 'name': "Electricidad", "foto": electricidad, 'categorieInfo': 'electricidad' },
            { 'key': 2, 'name': "Plomería", "foto": plomeria, 'categorieInfo': 'plomeria', },
            { 'key': 3, 'name': "Soporte Técnico", "foto": electricidad, 'categorieInfo': 'soporte-tec' },
            { 'key': 4, 'name': "Plomería", "foto": plomeria, 'categorieInfo': 'plomeria' },
            { 'key': 5, 'name': "CCTV", "foto": cctv, 'categorieInfo': 'cctv' },
            { 'key': 6, 'name': "Soporte Técnico", "foto": soporteTecnico, 'categorieInfo': 'soporte-tec' },
            { 'key': 7, 'name': "Electricidad", "foto": electricidad, 'categorieInfo': 'electricidad' },
            { 'key': 8, 'name': "Plomería", "foto": plomeria, 'categorieInfo': 'plomeria' },
            { 'key': 9, 'name': "Soporte Técnico", "foto": electricidad, 'categorieInfo': 'soporte-tec' },
            { 'key': 10, 'name': "Plomería", "foto": plomeria, 'categorieInfo': 'plomeria' },
            { 'key': 11, 'name': "CCTV", "foto": cctv, 'categorieInfo': 'cctv' },
        ]

        const CustomButtonGroupAsArrows = ({ next, previous, goToSlide, ...rest }) => {
            const { carouselState: { currentSlide } } = rest;
            return (
                <div className="carousel-button-group">
                    <LeftOutlined className={currentSlide === 0 ? 'disable' : 'arrow-icon'} onClick={() => previous()} />
                    <RightOutlined className='arrow-icon' onClick={() => next()} />
                </div>
            );
        };

        const clickCategorie = (e) => {
            onChangeVistaDashboard(e);
        }

        return (
            <div className='dashboard-content-categories'>
                <h2>Categorías</h2>
                {fetchingDashboardCategories ?
                    <Carousel
                        className='categories-carousel'
                        keyboard={false}
                        responsive={responsive}
                        infinite={false}
                        arrows={false}
                        deviceType={this.props.deviceType}
                        customButtonGroup={this.state.width >= 768 ? (<CustomButtonGroupAsArrows />) : (<></>)}
                        renderButtonGroupOutside={this.state.width >= 768 ? true : false}
                    >
                        {categories.map((categorie) =>
                            <Card
                                className='categorie-card'
                                key={categorie.key}
                            >
                                <Skeleton.Image className='ant-skeleton-active' size='large' />

                                <Meta className='meta' style={{ marginTop: '10px' }} title={<span><Skeleton.Input active size='small' /></span>} />
                            </Card>
                        )}
                    </Carousel> :
                    dashboardCategories.length > 0 ?
                        <Carousel
                            className='categories-carousel'
                            keyboard={false}
                            responsive={responsive}
                            infinite={false}
                            arrows={false}
                            deviceType={this.props.deviceType}
                            customButtonGroup={this.state.width >= 768 ? (<CustomButtonGroupAsArrows />) : (<></>)}
                            renderButtonGroupOutside={this.state.width >= 768 ? true : false}
                        >
                            {dashboardCategories.map((categorie) =>
                                <Link
                                    key={categorie.key}
                                    to={`/category?id=${categorie.id}`}>
                                    <Card
                                        className='categorie-card'
                                        hoverable
                                        cover={<img alt="categorie-img" src={categorie.img !== "" ? categorie.img : electricidad} />}
                                        onClick={() => clickCategorie(categorie)}
                                    >
                                        <Meta className='meta' title={<span>{categorie.name} <ArrowRightOutlined className='icon' /></span>} />
                                    </Card>
                                </Link>
                            )}
                        </Carousel>
                        :
                        <Empty
                            description={
                                <span>Las <b>categorías</b> no se cargaron correctamente. Recarga la página por favor.</span>
                            }
                        />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.DashboardReducer.vistaDashboard,
        dashboardCategories: state.DashboardReducer.dashboardCategories,
        fetchingDashboardCategories: state.DashboardReducer.fetchingDashboardCategories,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeVistaDashboard: (dashboardCategoryInfo) => {
            dispatch({ type: "CHANGE_VISTA_DASHBOARD", dashboardCategoryInfo })
        },
        onGetCategories: () => {
            dispatch({ type: "GET_DASHBOARD_CATEGORIES_REQUEST" })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardCategories)