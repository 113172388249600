import React from 'react'
import { Button } from 'antd';
import { GoogleLogin } from 'react-google-login';

function GoogleButton({ action, actionType }) {
    const responseGoogle = (response) => {
        if (response.profileObj) {
            let values = {
            }
            if (actionType === 'login') {
                values = {
                    "username": response.profileObj.email,
                    "password": response.profileObj.googleId
                }
            } else if (actionType === 'sign-up') {
                values = {
                    "email": response.profileObj.email,
                    "password": response.profileObj.googleId,
                    "name": { "firstName": response.profileObj.givenName, "lastName": response.profileObj.familyName },
                    "phone": ""
                }
            }
            action(values)
        }
    }

    return (
        <GoogleLogin
            clientId="722758664014-dmlfjoui2pu12790er1kiauibonnsp49.apps.googleusercontent.com"
            render={renderProps => (
                <Button onClick={renderProps.onClick} className='google'>
                    {actionType === 'login' ? 'Iniciar sesión con Google' : 'Registrarse con Google'}
                </Button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            isSignedIn={false}
        />
    )
}

export default GoogleButton