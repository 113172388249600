import { takeLatest, call, put } from 'redux-saga/effects'
import moment from 'moment'

import * as adminActions from '../Actions/AdminActions'
import * as alertActions from '../Actions/AlertActions'
import {
    //service categories
    getServiceCategoriesCALL,
    createServiceCategoryCALL,
    editServiceCategoryCALL,
    deleteServiceCategoryCALL,

    //services
    getServicesByCategoryCALL,
    getServicesCALL,
    getSearchServiceCALL,
    createServiceCALL,
    deleteServiceCALL,
    editServiceCALL,
    changeTopServiceStatusCALL,
    // addServiceToCategoryCALL,
    // removeServiceFromCategoryCALL,

    //brands
    getBrandsCALL,
    getBrandByIDCALL,
    getBrandsBycategoryIdCALL,
    createNewBrandCALL,
    editBrandCALL,
    deleteBrandCALL,
    addBrandToCategoryCALL,
    deleteBrandFromCategoryCALL,

    //notes
    getNotesCALL,
    getNotesByCategoryIdCALL,
    getNotesByTitleCALL,
    createNoteCALL,
    editNoteCALL,
    deleteNoteCALL,

    //blog categories
    getCategoriesCALL,
    createCategoryCALL,
    editCategoryCALL,
    deleteCategoryCALL,

    //users
    getFixersCALL,
    getFilteredFixersCALL,
    createFixerCALL,
    editFixerCALL,
    getClientsCALL,
    editClientCALL,

    //orders
    getClientsOrdersCALL,
    getFilteredClientsOrdersCALL,
    editClientOrderCALL,

} from '../../Services/AdminServices'

//********************** GET CATEGORIES *****************************
function* getServiceCategories() {
    try {
        const response = yield call(getServiceCategoriesCALL);
        const serviceCategories = response.data;

        if (response.status === 200) {
            yield put({ type: adminActions.GET_SERVICE_CATEGORIES_SUCCESS, serviceCategories });
        }

        else {
            yield put({ type: adminActions.GET_SERVICE_CATEGORIES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_SERVICE_CATEGORIES_FAILURE });
    }
}
export function* getServiceCategoriesSaga() {
    yield takeLatest(adminActions.GET_SERVICE_CATEGORIES_REQUEST, getServiceCategories);
}
//********************** CREATE CATEGORIE *****************************
function* createServiceCategory(action) {
    try {
        if (action.image !== '') {
            const token = sessionStorage.getItem('token');
            let img = action.image.split(',')[1];

            const data = {
                "categoriaArticulo": action.form.categoryName,
                "foto": img
            }

            const response = yield call(createServiceCategoryCALL, token, data);

            if (response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "La categoría se creó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.CREATE_SERVICE_CATEGORY_SUCCESS });
                yield put({ type: adminActions.GET_SERVICE_CATEGORIES_REQUEST });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.CREATE_SERVICE_CATEGORY_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para crear una categoría';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CREATE_SERVICE_CATEGORY_FAILURE });
    }
}
export function* createServiceCategorySaga() {
    yield takeLatest(adminActions.CREATE_SERVICE_CATEGORY_REQUEST, createServiceCategory);
}
//********************** EDIT CATEGORY *****************************
function* editServiceCategory(action) {
    try {

        if (action.image) {
            let img = action.image;
            if (img.includes("data:image")) {
                img = img.split(',')[1];
            }
            const token = sessionStorage.getItem('token');
            const data = {
                "idCategoriaArticulo": action.id,
                "categoriaArticulo": action.form.categoriaArticulo,
                "foto": img
            }

            const response = yield call(editServiceCategoryCALL, token, data);

            if (response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "La categoría se actualizó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.EDIT_SERVICE_CATEGORY_SUCCESS });
                yield put({ type: adminActions.GET_SERVICE_CATEGORIES_REQUEST });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.EDIT_SERVICE_CATEGORY_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para editar una categoría';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_SERVICE_FAILURE });
    }
}
export function* editServiceCategorySaga() {
    yield takeLatest(adminActions.EDIT_SERVICE_CATEGORY_REQUEST, editServiceCategory)
}
//********************** DELETE CATEGORY *****************************
function* deleteServiceCategory(action) {
    try {
        const token = sessionStorage.getItem('token');
        const id = action.id;
        let page = 0;
        const response = yield call(getServicesByCategoryCALL, page, id);

        if (response.status === 200) {
            let serviceCategoriesList = response.data;
            if (!serviceCategoriesList[0]) {
                const deleteCategoryResponse = yield call(deleteServiceCategoryCALL, token, id);

                if (deleteCategoryResponse.status === 200) {
                    const showSuccessMsg = true;
                    const textMessage = "La categoría se eliminó correctamente";

                    yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                    yield put({ type: adminActions.GET_SERVICE_CATEGORIES_REQUEST });
                    yield put({ type: adminActions.DELETE_SERVICE_CATEGORY_SUCCESS });
                }
                else {
                    const showErrorMsg = true;
                    const textMessage = "La categoría que quieres eliminar aún tiene servicios relacionados.";
                    yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: adminActions.DELETE_SERVICE_CATEGORY_FAILURE });
                }
            }
            else {
                const showErrorMsg = true;
                const textMessage = "La categoría que quieres eliminar aún tiene servicios relacionados.";
                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.DELETE_SERVICE_CATEGORY_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.DELETE_SERVICE_CATEGORY_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_SERVICE_CATEGORY_FAILURE });
    }
}
export function* deleteServiceCategorySaga() {
    yield takeLatest(adminActions.DELETE_SERVICE_CATEGORY_REQUEST, deleteServiceCategory);
}


//********************** GET SERVICES *****************************
function* getServices(action) {
    try {
        let page = action.page - 1;
        let id = action.id;
        let search = action.search;
        var response;

        if (id) {
            response = yield call(getServicesByCategoryCALL, page, id);
        } else {
            response = yield call(getServicesCALL, page);
        }

        if (search && search !== '') {
            response = yield call(getSearchServiceCALL, search);
        }

        if (response.status === 200) {
            const services = response.data;
            yield put({ type: adminActions.GET_SERVICES_SUCCESS, services });
        }
        else {
            yield put({ type: adminActions.GET_SERVICES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_SERVICES_FAILURE });
    }
}
export function* getServicesSaga() {
    yield takeLatest(adminActions.GET_SERVICES_REQUEST, getServices);
}
//********************** CREATE NEW SERVICE *****************************
function* createService(action) {
    try {
        if (action.image) {
            const token = sessionStorage.getItem('token');
            let img = action.image.split(',')[1];

            const data = {
                "articulo": action.form.serviceName,
                "foto": img,
                "descripcion": action.form.description,
                "precio": action.form.price,
                "stock": 20,
                "promo": false,
                "idProveedor": 1,
                "idUnidadMedida": 3,
                "idCategoriaArticulo": action.form.categoryId
            }

            const response = yield call(createServiceCALL, token, data);

            if (response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "El servicio se creó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.CREATE_SERVICE_SUCCESS });
                yield put({ type: adminActions.GET_SERVICES_REQUEST, page: 1 });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.CREATE_SERVICE_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para crear un nuevo servicio';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CREATE_SERVICE_FAILURE });
    }
}
export function* createServiceSaga() {
    yield takeLatest(adminActions.CREATE_SERVICE_REQUEST, createService);
}
//********************** DELETE SERVICE *****************************
function* deleteService(action) {
    try {
        const token = sessionStorage.getItem('token');
        const id = action.id;

        const response = yield call(deleteServiceCALL, token, id);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El servicio se eliminó correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.GET_SERVICES_REQUEST, page: 1 });
            yield put({ type: adminActions.DELETE_SERVICE_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.DELETE_SERVICE_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_SERVICE_FAILURE });
    }
}
export function* deleteServiceSaga() {
    yield takeLatest(adminActions.DELETE_SERVICE_REQUEST, deleteService);
}
//********************** EDIT SERVICE *****************************
function* editService(action) {
    try {
        if (action.image) {
            let img = action.image;

            if (img.includes("data:image")) {
                img = img.split(',')[1];
            }

            const token = sessionStorage.getItem('token');

            const data = {
                "idArticulo": action.serviceId,
                "articulo": action.form.articulo,
                "foto": img,
                "descripcion": action.form.descripcion,
                "precio": action.form.precio,
                "stock": 20,
                "idUnidadMedida": 3,
                "idCategoriaArticulo": action.form.idCategoriaArticulo
            }

            const response = yield call(editServiceCALL, token, data);

            if (response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "El servicio se actualizó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.EDIT_SERVICE_SUCCESS });
                yield put({ type: adminActions.GET_SERVICES_REQUEST, page: 1 });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.EDIT_SERVICE_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para editar una categoría';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_SERVICE_FAILURE });
    }
}
export function* editServiceSaga() {
    yield takeLatest(adminActions.EDIT_SERVICE_REQUEST, editService)
}
//********************** CHANGE TOP SERVICE STATUS *****************************
function* changeTopServiceStatus(action) {
    try {
        const token = sessionStorage.getItem('token');

        const data = {
            "id": action.id,
            "status": action.checked,
        }

        const response = yield call(changeTopServiceStatusCALL, token, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El servicio se actualizó correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.CHANGE_TOP_SERVICE_STATUS_SUCCESS });
            yield put({ type: adminActions.GET_SERVICES_REQUEST, page: 1 });
        }

        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.CHANGE_TOP_SERVICE_STATUS_FAILURE });
        }
    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CHANGE_TOP_SERVICE_STATUS_FAILURE });
    }
}
export function* changeTopServiceStatusSaga() {
    yield takeLatest(adminActions.CHANGE_TOP_SERVICE_STATUS_REQUEST, changeTopServiceStatus)
}
//********************** ADD SERVICE TO CATEGORY *****************************
// function* addServiceToCategory(action) {
//     try {
//         const token = sessionStorage.getItem('token');

//         const data = {
//             "idArticulo": action.serviceId,
//             "idCategoriaArticulo": action.categoryId
//         }

//         const response = yield call(addServiceToCategoryCALL, token, data);

//         if (response.status === 200 || response.status === 201) {
//             const showSuccessMsg = true;
//             const textMessage = "El servicio se agregó correctamente a la categoría.";
//             let page = action.page;

//             yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
//             yield put({ type: adminActions.GET_SERVICES_REQUEST, page });
//             yield put({ type: adminActions.ADD_SERVICE_TO_CATEGORY_SUCCESS });
//         }
//         else {
//             const showErrorMsg = true;
//             const textMessage = response.message;

//             yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
//             yield put({ type: adminActions.ADD_SERVICE_TO_CATEGORY_FAILURE });
//         }
//     } catch (error) {
//         const showErrorMsg = true;
//         const textMessage = error;

//         yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
//         yield put({ type: adminActions.ADD_SERVICE_TO_CATEGORY_FAILURE });
//     }
// }
// export function* addServiceToCategorySaga() {
//     yield takeLatest(adminActions.ADD_SERVICE_TO_CATEGORY_REQUEST, addServiceToCategory)
// }
// //********************** DELETE BRAND FROM CATEGORY *****************************
// function* removeServiceFromCategory(action) {
//     try {
//         const token = sessionStorage.getItem('token');
//         const id = action.serviceId;

//         const response = yield call(removeServiceFromCategoryCALL, token, id);

//         if (response.status === 200) {
//             const showSuccessMsg = true;
//             const textMessage = "El servicio se eliminó correctamente de la categoría";
//             let page = action.page;

//             yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
//             yield put({ type: adminActions.GET_SERVICES_REQUEST, page });
//             yield put({ type: adminActions.REMOVE_SERVICE_FROM_CATEGORY_SUCCESS });
//         }
//         else {
//             const showErrorMsg = true;
//             const textMessage = response.message;

//             yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
//             yield put({ type: adminActions.REMOVE_SERVICE_FROM_CATEGORY_FAILURE });
//         }
//     } catch (error) {
//         const showErrorMsg = true;
//         const textMessage = error;

//         yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
//         yield put({ type: adminActions.REMOVE_SERVICE_FROM_CATEGORY_FAILURE });
//     }
// }
// export function* removeServiceFromCategorySaga() {
//     yield takeLatest(adminActions.REMOVE_SERVICE_FROM_CATEGORY_REQUEST, removeServiceFromCategory)
// }


//********************** GET BRANDS *****************************
function* getBrands(action) {
    try {
        let page = action.page - 1;
        let id = action.id;
        var response;

        if (id) {
            response = yield call(getBrandsBycategoryIdCALL, page, id)
        }
        else {
            response = yield call(getBrandsCALL, page)
        }

        if (response.status === 200) {
            const brands = response.data.info;
            yield put({ type: adminActions.GET_BRANDS_SUCCESS, brands });
        }
        else {
            yield put({ type: adminActions.GET_BRANDS_FAILURE });
        }
    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_BRANDS_FAILURE });
    }
}
export function* getBrandsSaga() {
    yield takeLatest(adminActions.GET_BRANDS_REQUEST, getBrands)
}
//********************** GET BRAND BY ID *****************************
function* getBrandByID(action) {
    try {
        const token = sessionStorage.getItem('token')
        const id = action.id;

        const response = yield call(getBrandByIDCALL, token, id);

        if (response.status === 200 || response.status === 201) {
            const brandInfo = response.data.info
            yield put({ type: adminActions.GET_BRAND_BY_ID_SUCCESS, brandInfo });
        } else {
            yield put({ type: adminActions.GET_BRAND_BY_ID_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_BRAND_BY_ID_FAILURE });
    }
}
export function* getBrandByIDSaga() {
    yield takeLatest(adminActions.GET_BRAND_BY_ID_REQUEST, getBrandByID)
}
//********************** NEW BRAND *****************************
function* newBrand(action) {
    try {
        if (action.image) {
            const token = sessionStorage.getItem('token');
            let img = action.image.split(',')[1];

            const data = {
                "marca": action.form.brandName,
                "img": img
            }

            const response = yield call(createNewBrandCALL, token, data);

            if (response.status === 200 || response.status === 201) {
                const showSuccessMsg = true;
                const textMessage = "La marca se creó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.CREATE_NEW_BRAND_SUCCESS });
                yield put({ type: adminActions.GET_BRANDS_REQUEST, page: 1 });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.CREATE_NEW_BRAND_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para crear una nueva marca';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CREATE_NEW_BRAND_FAILURE });
    }
}
export function* newBrandSaga() {
    yield takeLatest(adminActions.CREATE_NEW_BRAND_REQUEST, newBrand)
}
//********************** EDIT BRAND *****************************
function* editBrand(action) {
    try {
        const id = action.brandId;
        let img = action.image;
        if (img.includes("data:image")) {
            img = img.split(',')[1];
        }
        const token = sessionStorage.getItem('token');
        const data = {
            "marca": action.form.marca,
            "img": img
        }

        const response = yield call(editBrandCALL, token, data, id)

        if (response.status === 200 || response.status === 201) {
            const showSuccessMsg = true;
            const textMessage = "La marca se actualizó correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.EDIT_BRAND_SUCCESS });
            yield put({ type: adminActions.GET_BRANDS_REQUEST, page: 1, });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.EDIT_BRAND_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_BRAND_FAILURE });
    }
}
export function* editBrandSaga() {
    yield takeLatest(adminActions.EDIT_BRAND_REQUEST, editBrand)
}
//********************** DELETE BRAND *****************************
function* deleteBrand(action) {
    try {
        const token = sessionStorage.getItem('token');
        const id = action.brandId;

        const response = yield call(deleteBrandCALL, token, id);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La marca se eliminó correctamente";
            let page = 1;

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.GET_BRANDS_REQUEST, page });
            yield put({ type: adminActions.DELETE_BRAND_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.DELETE_BRAND_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_BRAND_FAILURE });
    }
}
export function* deleteBrandSaga() {
    yield takeLatest(adminActions.DELETE_BRAND_REQUEST, deleteBrand)
}
//********************** ADD BRAND TO CATEGORY *****************************
function* addBrandToCategory(action) {
    try {
        const token = sessionStorage.getItem('token');

        const data = {
            "idMarca": action.brandId,
            "idCategoriaArticulo": action.categoryId
        }

        const response = yield call(addBrandToCategoryCALL, token, data);

        if (response.status === 200 || response.status === 201) {
            const showSuccessMsg = true;
            const textMessage = "La marca se agregó correctamente a la categoría.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.GET_BRAND_BY_ID_REQUEST, id: action.brandId });
            yield put({ type: adminActions.ADD_BRAND_TO_CATEGORY_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.ADD_BRAND_TO_CATEGORY_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.ADD_BRAND_TO_CATEGORY_FAILURE });
    }
}
export function* addBrandToCategorySaga() {
    yield takeLatest(adminActions.ADD_BRAND_TO_CATEGORY_REQUEST, addBrandToCategory)
}
//********************** DELETE BRAND FROM CATEGORY *****************************
function* deleteBrandFromCategory(action) {
    try {
        const token = sessionStorage.getItem('token');
        const id = action.brandId;

        const response = yield call(deleteBrandFromCategoryCALL, token, id);

        if (response.status === 200 || response.status === 201) {
            const showSuccessMsg = true;
            const textMessage = "La marca se eliminó correctamente de la categoría";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.GET_BRAND_BY_ID_REQUEST, id: action.id });
            yield put({ type: adminActions.DELETE_BRAND_FROM_CATEGORY_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.DELETE_BRAND_FROM_CATEGORY_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_BRAND_FROM_CATEGORY_FAILURE });
    }
}
export function* deleteBrandFromCategorySaga() {
    yield takeLatest(adminActions.DELETE_BRAND_FROM_CATEGORY_REQUEST, deleteBrandFromCategory)
}


//********************** GET NOTES *****************************
function* getNotes(action) {
    try {
        let page = action.page - 1;
        let id = action.id;
        let search = action.search;

        var response;

        if (search) {
            response = yield call(getNotesByTitleCALL, page, search);
        } else if (id) {
            response = yield call(getNotesByCategoryIdCALL, page, id);
        } else {
            response = yield call(getNotesCALL, page);
        }

        if (response.status === 200) {
            let notes = [];

            response.data.data.forEach(note => {
                let title = note.titulo.toLowerCase().split(' ').join('-');

                function replaceSpecialChars(title) {
                    return title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
                        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
                        .replace(/ *\([^)]*\) */g, "")
                        // .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
                        .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
                        .replace(/%20/g, " ");
                }

                const noteKey = replaceSpecialChars(title);

                notes.push({
                    key: noteKey,
                    title: note.titulo,
                    id: note.idNota,
                    img: note.imagen,
                    description: note.descripcion,
                    text: note.texto,
                    category: {
                        id: note.categoriaNotaByIdCategoriaNota.idCategoriaNota,
                        name: note.categoriaNotaByIdCategoriaNota.categoriaNota,
                        description: note.categoriaNotaByIdCategoriaNota.descripcion
                    }
                });
            })

            yield put({ type: adminActions.GET_NOTES_SUCCESS, notes });
        }
        else {
            yield put({ type: adminActions.GET_NOTES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_NOTES_FAILURE });
    }
}
export function* getNotesSaga() {
    yield takeLatest(adminActions.GET_NOTES_REQUEST, getNotes);
}
//********************** CREATE NOTE *****************************
function* createNote(action) {
    try {
        if (action.image) {
            const token = sessionStorage.getItem('token');
            let img = action.image.split(',')[1];

            const data = {
                "idCategoriaNota": action.form.categoryId,
                "idTipoNota": 1,
                "titulo": action.form.title,
                "imagen": img,
                "enlace": "",
                "descripcion": action.form.description,
                "texto": action.text,
                "video": ""
            }

            const response = yield call(createNoteCALL, token, data);

            if (response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "La nota se creó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.CREATE_NOTE_SUCCESS });
                yield put({ type: adminActions.GET_NOTES_REQUEST, page: 1 });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.CREATE_NOTE_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para crear una nueva nota';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CREATE_NOTE_FAILURE });
    }
}
export function* createNoteSaga() {
    yield takeLatest(adminActions.CREATE_NOTE_REQUEST, createNote);
}
//********************** EDIT NOTE *****************************
function* editNote(action) {
    try {
        if (action.image) {
            let img = action.image;

            if (img.includes("data:image")) {
                img = img.split(',')[1];
            }

            const token = sessionStorage.getItem('token');

            const data = {
                "idNota": action.id,
                "idCategoriaNota": action.form.category.id,
                "idTipoNota": 1,
                "titulo": action.form.title,
                "imagen": img,
                "enlace": "",
                "descripcion": action.form.description,
                "texto": action.text,
                "video": ""
            }

            const response = yield call(editNoteCALL, token, data);

            if (response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "La nota se actualizó correctamente";
                let page = 1;

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.EDIT_NOTE_SUCCESS });
                yield put({ type: adminActions.GET_NOTES_REQUEST, page });
            }

            else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.EDIT_NOTE_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Agrega una imagen para editar una nota';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        }

    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_NOTE_FAILURE });
    }
}
export function* editNoteSaga() {
    yield takeLatest(adminActions.EDIT_NOTE_REQUEST, editNote)
}
//********************** DELETE NOTE *****************************
function* deleteNote(action) {
    try {
        const token = sessionStorage.getItem('token');
        const id = action.id;

        const response = yield call(deleteNoteCALL, token, id);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La nota se eliminó correctamente.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.DELETE_NOTE_SUCCESS });
            yield put({ type: adminActions.GET_NOTES_REQUEST, page: 1 });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.DELETE_NOTE_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_NOTE_FAILURE });
    }
}
export function* deleteNoteSaga() {
    yield takeLatest(adminActions.DELETE_NOTE_REQUEST, deleteNote)
}


//********************** GET BLOG CATEGORIES *****************************
function* getCategories(action) {
    try {
        const response = yield call(getCategoriesCALL);

        if (response.status === 200) {
            let categories = response.data

            yield put({ type: adminActions.GET_NOTE_CATEGORIES_SUCCESS, categories });
        }
        else {
            yield put({ type: adminActions.GET_NOTE_CATEGORIES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_NOTE_CATEGORIES_FAILURE });
    }
}
export function* getCategoriesSaga() {
    yield takeLatest(adminActions.GET_NOTE_CATEGORIES_REQUEST, getCategories);
}
//********************** CREATE BLOG CATEGORY *****************************
function* createCategory(action) {
    try {
        const token = sessionStorage.getItem('token');

        const data = {
            "categoriaNota": action.form.name,
            "descripcion": action.form.description
        }

        const response = yield call(createCategoryCALL, token, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La categoría se creó correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.CREATE_NOTE_CATEGORY_SUCCESS });
            yield put({ type: adminActions.GET_NOTE_CATEGORIES_REQUEST });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.CREATE_NOTE_CATEGORY_FAILURE });
        }


    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CREATE_NOTE_CATEGORY_FAILURE });
    }
}
export function* createCategorySaga() {
    yield takeLatest(adminActions.CREATE_NOTE_CATEGORY_REQUEST, createCategory);
}
//********************** EDIT BLOG CATEGORY *****************************
function* editCategory(action) {
    try {
        const token = sessionStorage.getItem('token');

        const data = {
            "idCategoriaNota": action.id,
            "categoriaNota": action.form.categoriaNota,
            "descripcion": action.form.descripcion
        }

        const response = yield call(editCategoryCALL, token, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La categoría se actualizó correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.EDIT_NOTE_CATEGORY_SUCCESS });
            yield put({ type: adminActions.GET_NOTE_CATEGORIES_REQUEST });
        }

        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.EDIT_NOTE_CATEGORY_FAILURE });
        }

    }
    catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_NOTE_CATEGORY_FAILURE });
    }
}
export function* editCategorySaga() {
    yield takeLatest(adminActions.EDIT_NOTE_CATEGORY_REQUEST, editCategory)
}
//********************** DELETE BLOG CATEGORY *****************************
function* deleteCategory(action) {
    try {
        const token = sessionStorage.getItem('token');
        const id = action.id;

        const response = yield call(getNotesByCategoryIdCALL, 0, id);

        if (response.status === 200 && response.data.count === 0) {
            const delete_response = yield call(deleteCategoryCALL, token, id);

            if (delete_response.status === 200) {
                const showSuccessMsg = true;
                const textMessage = "La categoría se eliminó correctamente";

                yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: adminActions.GET_NOTE_CATEGORIES_REQUEST });
                yield put({ type: adminActions.DELETE_NOTE_CATEGORY_SUCCESS });
            }
            else {
                const showErrorMsg = true;
                const textMessage = delete_response.message;

                yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: adminActions.DELETE_NOTE_CATEGORY_FAILURE });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Esta categoría aún tiene notas vinculadas';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.DELETE_NOTE_CATEGORY_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.DELETE_NOTE_CATEGORY_FAILURE });
    }
}
export function* deleteCategorySaga() {
    yield takeLatest(adminActions.DELETE_NOTE_CATEGORY_REQUEST, deleteCategory);
}


//********************** GET FIXERS *****************************
function* getFixers(action) {
    try {
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const search = action.search;

        let response;

        if (search) {
            response = yield call(getFilteredFixersCALL, token, page, search);
        } else {
            response = yield call(getFixersCALL, token, page);
        }

        if (response.status === 200) {
            let fixers = [];

            response.data.data.forEach(fixer => {
                fixers.push({
                    name: fixer.name,
                    lastName: fixer.lastName,
                    fullName: `${fixer.name} ${fixer.lastName}`,
                    fixerID: fixer.idUsuario,
                    clientID: fixer.idCliente,
                    birthday: moment(fixer.birthday, 'YYYY-MM-DD'),
                    phone: fixer.phone,
                    email: fixer.email,
                    active: fixer.activo,
                    address: fixer.address
                })
            });

            yield put({ type: adminActions.GET_FIXERS_SUCCESS, fixers });
        } else {
            const showErrorMsg = true;
            const textMessage = "Los usuarios no se pudieron cargar. Intenta de nuevo.";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.GET_FIXERS_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_FIXERS_FAILURE });
    }
}
export function* getFixersSaga() {
    yield takeLatest(adminActions.GET_FIXERS_REQUEST, getFixers);
}
//********************** EDIT FIXER *****************************
function* editFixer(action) {
    try {
        const token = sessionStorage.getItem('token');
        const data = {
            "name": action.form.name,
            "lastName": action.form.lastName,
            "phone": action.form.phone,
            "birthday": moment(action.form.birthday._d).add(1, 'days'),
            "activo": action.fixer.active,
        };

        const clientID = action.fixer.clientID;
        const userID = action.fixer.fixerID;

        const response = yield call(editFixerCALL, token, userID, clientID, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = `El fixer "${action.form.email}" se actualizó correctamente`;

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.EDIT_FIXER_SUCCESS });
            yield put({ type: adminActions.GET_FIXERS_REQUEST, page: 1 });
        }

        else {
            const showErrorMsg = true;
            const textMessage = "El Fixer no se pudo actualizar";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.EDIT_FIXER_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_FIXER_FAILURE });
    }
}
export function* editFixerSaga() {
    yield takeLatest(adminActions.EDIT_FIXER_REQUEST, editFixer);
}
//********************** EDIT FIXER STATUS *****************************
function* editFixerStatus(action) {
    try {
        const token = sessionStorage.getItem('token');
        const data = {
            "name": action.fixer.name,
            "lastName": action.fixer.lastName,
            "phone": action.fixer.phone,
            "birthday": moment(action.fixer.birthday._d).add(1, 'days'),
            "activo": !action.fixer.active,
        };

        const clientID = action.fixer.clientID;
        const userID = action.fixer.fixerID;

        const response = yield call(editFixerCALL, token, userID, clientID, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = action.fixer.active ? `El estatus de ${action.fixer.name} cambio a activo` : `El estatus de ${action.fixer.name} cambio a inactivo`;

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.EDIT_FIXER_STATUS_SUCCESS });
            yield put({ type: adminActions.GET_FIXERS_REQUEST, page: 1 });
        }

        else {
            const showErrorMsg = true;
            const textMessage = "No se pudo cambiar el estatus del fixer.";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.EDIT_FIXER_STATUS_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_FIXER_STATUS_FAILURE });
    }
}
export function* editFixerStatusSaga() {
    yield takeLatest(adminActions.EDIT_FIXER_STATUS_REQUEST, editFixerStatus);
}
//********************** CREATE FIXER *****************************
function* createFixer(action) {
    try {
        let birthday = new Date();

        if (action.form.birthday) {
            birthday = action.form.birthday._d;
        }

        let data = {
            "name": action.form.name.firstName,
            "username": action.form.email,
            "password": action.form.password,
            "profiles": "Operador",
            "cliente": {
                "name": action.form.name.firstName,
                "lastName": action.form.name.lastName,
                "phone": action.form.phone,
                "birthday": (moment(birthday).format()).split('T')[0],
                "photo": ""
            }
        }

        const response = yield call(createFixerCALL, data);

        if (response.status === 200 || response.status === 201) {
            const showSuccessMsg = true;
            const textMessage = "Se registró nuevo Fixer. Se envió un email para verificar el correo electrónico.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.CREATE_FIXER_SUCCESS });
            yield put({ type: adminActions.GET_FIXERS_REQUEST, page: 1 });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.CREATE_FIXER_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.CREATE_FIXER_FAILURE, error });
    }
}
export function* createFixerSaga() {
    yield takeLatest(adminActions.CREATE_FIXER_REQUEST, createFixer);
}


//********************** GET CLIENTS *****************************
function* getClients() {
    try {
        const token = sessionStorage.getItem('token');
        const response = yield call(getClientsCALL, token);

        if (response.status === 200) {
            const fixers = response.data
            const showSuccessMsg = true;
            const textMessage = "Los usuarios tipo Cliente se cargaron correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.GET_CLIENTS_SUCCESS, fixers });
        }

        else {
            const showErrorMsg = true;
            const textMessage = "Los usuarios no se pudieron cargar. Intenta de nuevo";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.GET_CLIENTS_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_CLIENTS_FAILURE });
    }
}
export function* getClientsSaga() {
    yield takeLatest(adminActions.GET_CLIENTS_REQUEST, getClients);
}
//********************** EDIT CLIENT *****************************
function* editClient(action) {
    try {
        const token = sessionStorage.getItem('token')
        const data = {};

        const response = yield call(editClientCALL, token, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El Usuario se actualizó correctamente.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.EDIT_CLIENT_SUCCESS });
            yield put({ type: adminActions.GET_CLIENTS_REQUEST });
        }

        else {
            const showErrorMsg = true;
            const textMessage = "No se pudo actualizar.";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.EDIT_CLIENT_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_CLIENT_FAILURE });
    }
}
export function* editClientSaga() {
    yield takeLatest(adminActions.EDIT_CLIENT_REQUEST, editClient);
}


//********************** GET ORDERS *****************************
function* getClientsOrders(action) {
    try {
        const page = action.page - 1;
        const token = sessionStorage.getItem('token');
        const search = action.search;

        var response;

        if (search) {
            response = yield call(getFilteredClientsOrdersCALL, token, page, search);
        } else {
            response = yield call(getClientsOrdersCALL, token, page);
        }

        if (response.status === 200) {
            let orders = [];

            response.data.data.forEach(order => {
                orders.push({
                    orderID: order.idPedido,
                    creationDate: moment(order.fechaPedido, 'YYYY-MM-DD'),
                    scheduleDate: order.fechaProgramada ? moment(order.fechaProgramada, 'YYYY-MM-DD') : null,
                    scheduleHour: order.horaProgramada ? moment(order.horaProgramada, 'HH:mm') : null,
                    paymentMethod: order.tipoPago,
                    price: order.total,
                    status: order.estado,
                    userID: order.idUsuario,
                    services: order.servicios,
                    address: order.addressTest,
                    fixerID: order.idFixer !== 0 ? order.idFixer : null
                })
            });

            orders = { orders: orders, count: response.data.count }

            yield put({ type: adminActions.GET_CLIENTS_ORDERS_SUCCESS, orders });
        }

        else {
            const showErrorMsg = true;
            const textMessage = "Los pedidos no se pudieron cargar. Intenta de nuevo";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.GET_CLIENTS_ORDERS_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.GET_CLIENTS_ORDERS_FAILURE });
    }
}
export function* getClientsOrdersSaga() {
    yield takeLatest(adminActions.GET_CLIENTS_ORDERS_REQUEST, getClientsOrders);
}
//********************** EDIT ORDER *****************************
function* editClientOrder(action) {
    try {
        const token = sessionStorage.getItem('token')
        const data = {
            "servicios": action.orderInfo.services,
            "tipoPago": action.orderInfo.paymentMethod,
            "total": action.orderInfo.price,
            "fechaProgramada": moment(action.form.scheduleDate).format('YYYY-MM-DD'),
            "horaProgramada": moment(action.form.scheduleHour).format('HH:mm'),
            "idFixer": action.form.fixerID,
            "estado": action.form.status,
            "addressTest": action.orderInfo.address
        };

        const response = yield call(editClientOrderCALL, token, action.orderInfo.orderID, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El pedido se actualizó correctamente";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: adminActions.EDIT_CLIENT_ORDER_SUCCESS });
            yield put({ type: adminActions.GET_CLIENTS_ORDERS_REQUEST, page: 1 });
        }

        else {
            const showErrorMsg = true;
            const textMessage = "El pedido no se pudo actualizar. Intente nuevamente";

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: adminActions.EDIT_CLIENT_ORDER_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: adminActions.EDIT_CLIENT_ORDER_FAILURE });
    }
}
export function* editClientOrderSaga() {
    yield takeLatest(adminActions.EDIT_CLIENT_ORDER_REQUEST, editClientOrder);
}