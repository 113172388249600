import * as alertActions from '../Actions/AlertActions'

const initialState = {
    //admin messages
    showSuccessMsg: false,
    showErrorMsg: false,
    textMessage: null,

}

const AlertReducer = (state = initialState, action) => {
    switch (action.type) {

        //ADMIN MESSAGES
        case alertActions.SHOW_SUCCESS_MSG:
            return { ...state, showSuccessMsg: action.showSuccessMsg, typeMessage: "success", textMessage: action.textMessage };
        case alertActions.SHOW_ERROR_MSG:
            return { ...state, showErrorMsg: action.showErrorMsg, typeMessage: "error", textMessage: action.textMessage };

        default:
            return state;
    }

}

export default AlertReducer;