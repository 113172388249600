import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Upload, Spin, List, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined, DeleteOutlined, PlusCircleTwoTone } from '@ant-design/icons';

import * as AdminActions from '../../../Store/Actions/AdminActions';

const confirm = Modal.confirm;

function EditBrand({ isVisibleEB, setIsVisibleEB, clearFilters, categories }) {
    const [image, setImage] = useState();
    const [loading, setLoading] = useState(false)
    const [addedCategoriesArray, setAddedCategoriesArray] = useState([])
    const [othersCategoriesArray, setOthersCategoriesArray] = useState([])
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const fetchingEditBrand = useSelector((state) => state.AdminReducer.fetchingEditBrand);
    const fetchingEditBrandCategories = useSelector((state) => state.AdminReducer.fetchingEditBrandCategories);
    const fetchingGetBrandByID = useSelector(state => state.AdminReducer.fetchingGetBrandByID);
    const brandInfo = useSelector(state => state.AdminReducer.brandInfo)

    useEffect(() => {
        if (brandInfo.length !== 0) {
            if (brandInfo.img) {
                setImage(brandInfo.img)
            }

            if (brandInfo.categorias.length !== 0) {
                let array = categories.filter(category =>
                    !brandInfo.categorias.some(brandCategory => brandCategory.categoria.idCategoriaArticulo === category.idCategoriaArticulo)
                );

                setOthersCategoriesArray(brandInfo.categorias)
                setAddedCategoriesArray(array);
            } else {
                setAddedCategoriesArray(categories);
                setOthersCategoriesArray([])
            }
        }

        form.resetFields();
    }, [form, brandInfo, categories, dispatch]);

    const onFinish = (form) => {
        if (image) {
            let brandId = brandInfo.idMarca;
            dispatch({ type: "EDIT_BRAND_REQUEST", form, brandId, image });
            setIsVisibleEB(!isVisibleEB)
            clearFilters();
        } else {
            message.error('Agrega una imagen')
        }
    };

    const onCancel = () => {
        form.resetFields();
        setIsVisibleEB(!isVisibleEB)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImage(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const handleAddCategory = (category) => {
        let brandId = brandInfo.idMarca
        dispatch({ type: AdminActions.ADD_BRAND_TO_CATEGORY_REQUEST, brandId, categoryId: category.idCategoriaArticulo });
        clearFilters();
    }

    const handleRemoveCategory = (brand) => {
        let brandId = brand.idCategoriaMarca

        confirm({
            title: '¿Estás seguro que deseas eliminar la marca de la categoría  "' + brand.categoria.categoriaArticulo + '"?',
            content: '',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                dispatch({ type: AdminActions.DELETE_BRAND_FROM_CATEGORY_REQUEST, brandId, id: brand.idMarca });
                clearFilters();
            },
            onCancel() {
            },
        });
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar marca ${brandInfo.marca}`}
            open={isVisibleEB}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="editBrand" key="submit" type="primary"
                    loading={fetchingEditBrand || fetchingGetBrandByID || fetchingEditBrandCategories}
                >
                    Guardar
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditBrand || fetchingGetBrandByID || fetchingEditBrandCategories} >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='editBrand'
                    initialValues={brandInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        name="marca"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Nombre de la marca (Máximo 50 caracteres)" />
                    </Form.Item>
                </Form>

                <List
                    itemLayout="horizontal"
                    locale={{ emptyText: 'Esta marca no se ha vinculado a ninguna categoría' }}
                    dataSource={othersCategoriesArray}
                    renderItem={item => (
                        <List.Item
                            key={`brand-category-0${item.categoria.idCategoriaArticulo}`}
                            actions={[
                                <Button
                                    type='link'
                                    onClick={() => handleRemoveCategory(item)}
                                ><DeleteOutlined style={{ color: 'red' }} className="icon" /></Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.categoria.categoriaArticulo}
                            />
                        </List.Item>
                    )}
                />

                <List
                    itemLayout="horizontal"
                    locale={{ emptyText: 'Esta marca está vinculada a todas las categorías' }}
                    dataSource={addedCategoriesArray}
                    renderItem={item => (
                        <List.Item
                            key={`brand-category-02-${item.idCategoriaArticulo}`}
                            actions={[
                                <Button
                                    type='link'
                                    onClick={() => handleAddCategory(item)}
                                >{<PlusCircleTwoTone style={{ color: 'green' }} className="icon" />}</Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.categoriaArticulo}
                            />
                        </List.Item>
                    )}
                />

                <Upload.Dragger
                    name="files"
                    onChange={handleChange}
                    multiple={false}
                    showUploadList={false}
                    className='dragger'
                    beforeUpload={beforeUpload}
                >
                    {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload.Dragger>
            </Spin>
        </Modal>
    )
}

export default EditBrand