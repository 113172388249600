import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment';
import { Row, Col, DatePicker, Form, Button, Modal, Spin, TimePicker, Select } from 'antd';

import * as AdminActions from '../../../Store/Actions/AdminActions';

const { Option } = Select;

function UpdateOrder({ isVisibleEdit, setIsVisibleEdit, orderInfo, setOrderInfo, clearFilters }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fetchingEditOrder = useSelector(state => state.AdminReducer.fetchingEditOrder);
    const fixers = useSelector((state) => state.AdminReducer.fixers);

    useEffect(() => {
        dispatch({ type: AdminActions.GET_FIXERS_REQUEST, page: 1 });
        form.resetFields();
    }, [dispatch, orderInfo, form])

    const onFinish = (form) => {
        dispatch({ type: AdminActions.EDIT_CLIENT_ORDER_REQUEST, form, orderInfo });
        setIsVisibleEdit(false)
        form.resetFields(orderInfo);
        clearFilters();
    };

    const onCancel = () => {
        setIsVisibleEdit(!isVisibleEdit);
        form.resetFields();
        setOrderInfo([]);
    }

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };

    const disabledDateTime = () => ({
        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23]
    });

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar pedido #${orderInfo?.orderID}`}
            open={isVisibleEdit}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="UpdateOrder" key="submit" type="primary"
                    loading={fetchingEditOrder}
                >
                    Guardar cambios
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditOrder} >
                <Form
                    name="UpdateOrder"
                    id='UpdateOrder'
                    labelCol={{ span: 24, }}
                    wrapperCol={{ span: 24, }}
                    initialValues={orderInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Row justify='space-between'>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Fecha Agendada'
                                name="scheduleDate"
                                rules={[{ required: true, message: "Fecha Agendada para la visita del Fixer" }]}
                            >
                                <DatePicker disabledDate={disabledDate} format='YYYY-MM-DD' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Hora Agendada'
                                name="scheduleHour"
                                rules={[{ required: true, message: "Hora Agendada para la visita del Fixer" }]}
                            >
                                <TimePicker showNow={false} disabledTime={disabledDateTime} minuteStep={15} format='HH:mm' style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Estado del pedido'
                                name="status"
                                rules={[{ required: true, message: "Estado del pedido" }]}
                            >
                                <Select
                                    placeholder="Estado del pedido"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    <Option value='Generado'>
                                        Generado
                                    </Option>
                                    <Option value='Autorizado'>
                                        Autorizado
                                    </Option>
                                    {/* <Option value='Pendiente'>
                                        Pendiente
                                    </Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                            <Form.Item
                                label='Fixer asignado'
                                name="fixerID"
                                rules={[{ required: true, message: "Fixer asignado" }]}
                            >
                                <Select
                                    placeholder="Fixer asignado"
                                    allowClear
                                    style={{ width: '100%' }}
                                >
                                    {fixers.length !== 0 && fixers.map(fixer =>
                                        fixer.active && <Option key={`asigned-fixer-id-${fixer.fixerID}`} value={fixer.fixerID}>
                                            {fixer.fullName}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default UpdateOrder