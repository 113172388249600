import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, Button, Card, Row, Col, Avatar, Pagination, Select, Modal, Input } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

import NewNote from './NewNote';
import EditNote from './EditNote';

const confirm = Modal.confirm;
const Search = Input.Search;
const { Option } = Select;

function NotesList() {
    const [page, setPage] = useState(1)
    const [id, setId] = useState()
    const [search, setSearch] = useState();
    const [inputValue, setInputValue] = useState(null);
    const [isVisibleCreate, setIsVisibleCreate] = useState(false)
    const [isVisibleEdit, setIsVisibleEdit] = useState(false)
    const [noteInfo, setNoteInfo] = useState([])

    const dispatch = useDispatch();
    const fetchingGetNotes = useSelector((state) => state.AdminReducer.fetchingGetNotes);
    const notes = useSelector((state) => state.AdminReducer.notes);
    const categories = useSelector((state) => state.AdminReducer.categories);

    useEffect(() => {
        dispatch({ type: "GET_NOTES_REQUEST", page, id, search });
        dispatch({ type: "GET_NOTE_CATEGORIES_REQUEST" });
    }, [dispatch, page, id, search]);

    const showDeleteConfirm = (id, title) => {
        confirm({
            title: '¿Estás seguro que deseas eliminar la nota  "' + title + '"?',
            content: '',
            okText: 'Sí',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                dispatch({ type: "DELETE_NOTE_REQUEST", id });
                clearFilters();
            },
            onCancel() {
            },
        });
    };

    const onChangePage = (value) => {
        setPage(value)
    }

    const onChange = (value) => {
        setId(value)
        setSearch(null);
        setInputValue(null);
        setPage(1);
    };

    const handleOnChangeSearch = (e) => {
        if (!e.target.value) {
            setSearch(null)
            setInputValue(null)
        } else {
            setInputValue(e.target.value)
        }
    }

    const clearFilters = () => {
        setInputValue(null);
        setSearch(null);
        setPage(1);
        setId(null);
    }

    return (
        <>
            <Row type="flex" justify="space-between" align='middle' gutter={[0, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Search
                        value={inputValue}
                        onChange={handleOnChangeSearch}
                        placeholder="Buscar nota"
                        onSearch={value => { setSearch(value); setId(null) }}
                        allowClear
                    />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Select
                        value={id}
                        allowClear
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Selecciona una categoría"
                        optionFilterProp="children"
                        onChange={onChange}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {categories.map(category =>
                            <Option value={category.idCategoriaNota} key={category.idCategoriaNota}>
                                {category.categoriaNota}
                            </Option>
                        )}
                    </Select>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Button style={{ width: '100%' }} type="primary" className="login-btn"
                        onClick={() => setIsVisibleCreate(true)}
                    >Nueva Nota</Button>
                </Col>
            </Row>
            <br />
            <Card title={
                <div className="header-list">NOTAS</div>}>
                <List
                    itemLayout="horizontal"
                    loading={fetchingGetNotes}
                    locale={{ emptyText: 'Sin notas' }}
                    dataSource={notes}
                    renderItem={item => (
                        <List.Item
                            key={`blog-note-0${item.id}`}
                            actions={[
                                <span
                                    onClick={() => showDeleteConfirm(item.id, item.title)}
                                ><DeleteTwoTone className="icon" /></span>,
                                <span
                                    onClick={() => {
                                        setIsVisibleEdit(!isVisibleEdit);
                                        setNoteInfo(item);
                                    }}
                                ><EditTwoTone className="icon" /></span>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.title}
                                avatar={<Avatar src={item.img} />}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <br />
            <Pagination current={page} total={notes.length} onChange={onChangePage} />
            <br />
            <NewNote setIsVisibleCreate={setIsVisibleCreate} isVisibleCreate={isVisibleCreate} clearFilters={clearFilters} />
            <EditNote setIsVisibleEdit={setIsVisibleEdit} isVisibleEdit={isVisibleEdit} noteInfo={noteInfo} categories={categories} page={page} clearFilters={clearFilters} />
        </>
    )
}

export default NotesList