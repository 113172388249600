import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Row, Col, Button, Input, Dropdown, Menu } from 'antd';
import { MenuOutlined, SearchOutlined, ShoppingCartOutlined, EnvironmentFilled, CaretDownFilled, ArrowLeftOutlined, UserOutlined, PoweroffOutlined } from '@ant-design/icons';

import { useWindowSize } from '../../Hooks/useWindowSize';
import fixersLogo from '../../Assets/Logos/logo_fixers_color.png';
import '../../Stylesheets/Dashboard/DashboardHeader.css';

function DashboardHeader() {
    const { width } = useWindowSize();
    const [searchValue, setSearchValue] = useState()
    const [showSearchDrawer, setShowSearchDrawer] = useState(false);
    const address = useSelector(state => state.DashboardReducer.address);

    const dispatch = useDispatch();
    const cart = useSelector((state) => state.DashboardReducer.cart);
    const isAuthenticated = useSelector((state) => state.LoginReducer.isAuthenticated);
    const history = useHistory();

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('search');
        if (search) {

            setSearchValue(search);
        }
    }, [])


    const handleChange = (e) => {
        if (!e.target.value) {
            history.push('/')
        }
        setSearchValue(e.target.value)
    }

    const onSearch = (e) => {
        if (e.key === 'Enter' && searchValue) {
            history.push(`/search?search=${searchValue}`)
        }
    }

    const handleOnSearch = () => {
        if (searchValue) {
            history.push(`/search?search=${searchValue}`)
        }
    }

    const handleClick = () => {
        dispatch({ type: "CHANGE_VISTA_DASHBOARD", vistaDashboard: 'dashboard' })
    }

    const onShowAddressModal = () => {
        dispatch({ type: "SHOW_ADDRESS_MODAL", showAddressModal: true });
    }

    const onHandleLogout = () => {
        sessionStorage.clear();
        window.location.reload();
    }

    const menu = (
        <Menu mode="vertical">
            <Menu.Item key="account">
                <Link to='/account/profile'>
                    Mi cuenta
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="orders">
                <Link to='/account/orders'>
                    Mis pedidos
                </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={onHandleLogout} danger key="logout" icon={<PoweroffOutlined />}>
                Cerrar sesión
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='header-dashboard'>
            {width < 1024 ? (
                <>
                    <Row justify="space-between" align='middle' className='header-top-container'>
                        <Col span={showSearchDrawer ? (0) : (1)}>
                            <Button type="link" onClick={() => dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer: true })} icon={<MenuOutlined className='icon-header-dashboard' />} />
                        </Col>
                        {width >= 425 &&
                            <Col xs={{ span: showSearchDrawer ? (0) : (5) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 4 }} lg={{ span: 6 }}>
                                <Link to="/" onClick={handleClick} >
                                    <img src={fixersLogo} alt="logo-header" className="logo-header" />
                                </Link>
                            </Col>
                        }

                        {width <= 767 ? (
                            <Col xs={{ span: showSearchDrawer ? (0) : (3) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 2 }} lg={{ span: 1 }}>
                                <Button className='search-button-header' onClick={() => { setShowSearchDrawer(true) }} icon={<SearchOutlined className='icon-header-dashboard' />} />
                            </Col>
                        ) : (
                            <Col xs={{ span: showSearchDrawer ? (0) : (3) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 12 }} lg={{ span: 1 }} className="search-bar-container">
                                {/* tablet */}
                                <Input
                                    value={searchValue}
                                    onChange={handleChange}
                                    onKeyDown={onSearch}
                                    bordered={false}
                                    placeholder="Buscar Servicio"
                                    suffix={<Button onClick={handleOnSearch} className='search-button-header' icon={<SearchOutlined className='icon-header-dashboard' />} />}
                                />
                            </Col>
                        )}
                        <Col xs={{ span: showSearchDrawer ? (0) : (3) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 2 }} lg={{ span: 1 }}>
                            <Button className='cart-button-header' onClick={() => dispatch({ type: "SHOW_CART_DRAWER", showCartDrawer: true })} icon={<ShoppingCartOutlined className='icon-header-dashboard' />} > {cart.length}</Button>
                        </Col>
                        <Col xs={{ span: showSearchDrawer ? (0) : (5) }} sm={{ span: showSearchDrawer ? (0) : (3) }} md={{ span: 2 }} lg={{ span: 1 }}>
                            {isAuthenticated ?
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button type='text' icon={<UserOutlined className="icon-header-dashboard" />} />
                                </Dropdown> :
                                <Link to="/login" onClick={() => dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin: 'login' })} >Ingreso</Link>}
                        </Col>

                        <Col span={showSearchDrawer ? (24) : (0)} className='search-bar-container'>
                            <Row justify="space-around" align='middle'>
                                <Col span={1}><Button onClick={() => { setShowSearchDrawer(false) }} icon={<ArrowLeftOutlined className='icon-header-dashboard' />} type='text' /></Col>
                                <Col span={19}>
                                    {/* mobil */}
                                    <Input
                                        value={searchValue}
                                        onChange={handleChange}
                                        onKeyDown={onSearch}
                                        bordered={false}
                                        placeholder="Buscar Servicio"
                                        suffix={<Button onClick={handleOnSearch} className='search-button-header' icon={<SearchOutlined className='icon-header-dashboard' />} />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify='space-around' className="header-bottom-container">
                        <Col span={24} className="location-container">
                            <Button type='text' onClick={onShowAddressModal} icon={<EnvironmentFilled className="icon-header-dashboard" />}><span>{address !== null ? address : 'Ingresar mi ubicación'}<CaretDownFilled /></span></Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    {/* desktop */}
                    <Row justify="space-between" align='middle' className='header-top-container'>
                        <Col lg={{ span: 1 }}>
                            <Button type="link" onClick={() => dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer: true })} icon={<MenuOutlined className='icon-header-dashboard' />} />
                        </Col>
                        <Col lg={{ span: 2 }}>
                            <Link to="/" onClick={handleClick} >
                                <img src={fixersLogo} alt="logo-header" className="logo-header" />
                            </Link>
                        </Col>
                        <Col lg={{ span: 4 }} xxl={{ span: 3 }} className="location-container" >
                            <Button type='text' onClick={onShowAddressModal} icon={<EnvironmentFilled className="icon-header-dashboard" />}><span>{address !== null ? address : 'Ingresar mi ubicación'}<CaretDownFilled /></span></Button>
                        </Col>
                        <Col lg={{ span: 12 }} xxl={{ span: 15 }} className="search-bar-container">
                            <Input
                                allowClear
                                value={searchValue}
                                onChange={handleChange}
                                onKeyDown={onSearch}
                                bordered={false}
                                placeholder="Buscar Servicio"
                                suffix={<Button onClick={handleOnSearch} className='search-button-header' icon={<SearchOutlined className='icon-header-dashboard' />} />}
                            />
                        </Col>
                        <Col lg={{ span: 1 }}>
                            <Button className='cart-button-header' onClick={() => dispatch({ type: "SHOW_CART_DRAWER", showCartDrawer: true })} icon={<ShoppingCartOutlined className='icon-header-dashboard' />} > {cart.length}</Button>
                        </Col>
                        <Col lg={{ span: 1 }}>
                            {isAuthenticated ?
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button type='text' icon={<UserOutlined className="icon-header-dashboard" />} />
                                </Dropdown> :
                                <Link to="/login" onClick={() => dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin: 'login' })} >Ingreso</Link>}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default DashboardHeader