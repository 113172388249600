import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Button, Input, Modal, Upload, Spin, message } from 'antd';
import { FormOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';

function EditCategory(props) {
    const { isVisibleEC, toggleEditCategoryModal, categoryInfo } = props

    const [image, setImage] = useState();
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const fetchingEditServiceCategory = useSelector((state) => state.AdminReducer.fetchingEditServiceCategory);

    useEffect(() => {
        if (categoryInfo.length !== 0 && categoryInfo.foto !== '') {
            setImage(categoryInfo.foto)
        }
        form.resetFields();
    }, [form, categoryInfo]);

    const onFinish = (form) => {
        if (image) {
            let id = categoryInfo.idCategoriaArticulo;
            dispatch({ type: "EDIT_SERVICE_CATEGORY_REQUEST", form, id, image });
            toggleEditCategoryModal([]);
        } else {
            message.error('Agrega una imagen')
        }
    };

    const onCancel = () => {
        form.resetFields();
        toggleEditCategoryModal([]);
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImage(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> :
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>}
            <div style={{ marginTop: 8 }}>
                Haz click para agregar una imagen.
            </div>
        </div>
    );

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir imágenes JPG, JPEG y PNG.');
        }
        return isJpgOrPng;
    };

    return (
        <Modal
            destroyOnClose
            forceRender={true}
            title={`Editar categoría ${categoryInfo.categoriaArticulo}`}
            open={isVisibleEC}
            onCancel={onCancel}
            width={900}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button htmlType="submit" form="editServiceCategory" key="submit" type="primary"
                    loading={fetchingEditServiceCategory}
                >
                    Guardar
                </Button>
            ]}
        >
            <Spin spinning={fetchingEditServiceCategory} >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='editServiceCategory'
                    initialValues={categoryInfo}
                    onFinish={onFinish}
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        name="categoriaArticulo"
                        rules={[{ required: true, message: 'Favor de llenar el campo.', }]}
                    >
                        <Input prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Ingrese nombre categoria" />
                    </Form.Item>
                </Form>
                <Upload.Dragger
                    name="files"
                    className='dragger'
                    onChange={handleChange}
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                >
                    {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload.Dragger>
            </Spin>
        </Modal>
    )
}

export default EditCategory