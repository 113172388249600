import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import * as dashboardActions from '../../Store/Actions/DashboardActions'

const PUBLIC_KEY = 'pk_live_51MGtd3EfiCkMbzAU3GvsFQ799eqhOt5bPFfrWoE8zB8epuBCd5W0b6U52WOW4Z3X7V5gEFh5TD3Xx2BWvEiVUzND00iJDTJJYc'
const stripePromise = loadStripe(PUBLIC_KEY);

function Payment({ cart }) {
    const dispatch = useDispatch();
    const clientSecret = useSelector(state => state.DashboardReducer.clientSecret)

    useEffect(() => {
        dispatch({ type: dashboardActions.CREATE_PAYMENT_INTENT_REQUEST, cart })
    }, [dispatch, cart]);

    const appearance = {
        theme: 'flat',
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            {stripePromise && clientSecret &&
                (<Elements options={options} stripe={stripePromise}>
                    <CheckoutForm clientSecret={clientSecret}/>
                </Elements>)
            }
        </>
    )
}

export default Payment