//Archivo Saga que ejecuta las acciones del Login

import { takeLatest, call, put } from "redux-saga/effects";
import * as loginActions from '../Actions/LoginActions';
import * as alertActions from '../Actions/AlertActions';
import { getLoginCALL, getSignUpClientCALL, recuperarContraseñaCALL, getSignUpFixerCALL } from "../../Services/LoginServices";
import moment from "moment";


//********************** LOGIN *****************************
function* getLogin(action) {
    try {
        const responseLogin = yield call(getLoginCALL, action.values);

        if (responseLogin.status === 200) {
            sessionStorage.setItem('isAuthenticated', true);
            let token = responseLogin.data.Token;
            let role = responseLogin.data.Roles;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('role', role);

            const showSuccessMsg = true;
            const textMessage = "Sesión iniciada.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: loginActions.LOGIN_SUCCESS, token, role });
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'El nombre de usuario o correo electrónico y la contraseña no coinciden. Asegúrate de que sean correctos.';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: loginActions.LOGIN_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = 'Lo siento, inicio de sesión incorrecto. Intenta de nuevo o contacta al administrador.';

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: loginActions.LOGIN_FAILURE, error });
    }
}
export function* getLoginSaga() {
    yield takeLatest(loginActions.LOGIN_REQUEST, getLogin);
}

//********************** SIGN UP CLIENT*****************************
function* getSignUpClient(action) {
    try {
        let birthday = new Date();

        if (action.form.birthday) {
            birthday = action.form.birthday._d;
        }

        let data = {
            "name": action.form.name.firstName,
            "username": action.form.email,
            "password": action.form.password,
            "profiles": "Particular",
            "cliente": {
                "name": action.form.name.firstName,
                "lastName": action.form.name.lastName,
                "phone": action.form.phone,
                "birthday": (moment(birthday).format()).split('T')[0],
                "photo": ""
            }
        }
        const response = yield call(getSignUpClientCALL, data);

        if (response.status === 200 || response.status === 201) {
            const showSuccessMsg = true;
            const textMessage = "Se registró su cuenta. Se envió un email a su correo para verificar la dirección electrónica.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: loginActions.SIGN_UP_CLIENT_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = 'Este nombre de usuario o correo electrónico ya existe. Intenta con uno diferente.';

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: loginActions.SIGN_UP_CLIENT_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: loginActions.SIGN_UP_CLIENT_FAILURE, error });
    }
}
export function* getSignUpClientSaga() {
    yield takeLatest(loginActions.SIGN_UP_CLIENT_REQUEST, getSignUpClient);
}

//********************** RECUPERAR CONTRASEÑA *****************************
function* recuperarContraseña(action) {
    try {
        const email = action.form.email

        const response = yield call(recuperarContraseñaCALL, email);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se envió un email a su correo para reestablecer su contraseña.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: loginActions.SHOW_MODAL_RESET_PASSWORD, showModalResetPassword: true });
            yield put({ type: loginActions.RESET_PASSWORD_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: loginActions.RESET_PASSWORD_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: loginActions.RESET_PASSWORD_FAILURE });
    }
}
export function* recuperarContraseñaSaga() {
    yield takeLatest(loginActions.RESET_PASSWORD_REQUEST, recuperarContraseña);
}

//********************** SIGN UP FIXER*****************************
function* getSignUpFixer(action) {
    try {
        let birthday = new Date();

        if (action.form.birthday) {
            birthday = action.form.birthday._d;
        }

        let data = {
            "name": action.form.name.firstName,
            "username": action.form.email,
            "password": action.form.password,
            "profiles": "Operador",
            "cliente": {
                "name": action.form.name.firstName,
                "lastName": action.form.name.lastName,
                "phone": action.form.phone,
                "birthday": (moment(birthday).format()).split('T')[0],
                "photo": ""
            }
        }

        const response = yield call(getSignUpFixerCALL, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se registró nuevo Fixer. Se envió un email para verificar el correo electrónico.";

            yield put({ type: alertActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: loginActions.SIGN_UP_FIXER_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: loginActions.SIGN_UP_FIXER_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = error;

        yield put({ type: alertActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: loginActions.SIGN_UP_FIXER_FAILURE, error });
    }
}
export function* getSignUpFixerSaga() {
    yield takeLatest(loginActions.SIGN_UP_FIXER_REQUEST, getSignUpFixer);
}