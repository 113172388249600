import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, Button, Card, Row, Col, Pagination, Input, Tooltip, Switch } from 'antd';
import { EditTwoTone } from '@ant-design/icons';

import * as AdminActions from '../../../Store/Actions/AdminActions';
import NewFixer from './NewFixer';
import EditFixer from './EditFixer';

const Search = Input.Search;

function FixersList() {
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState()
    const [searchValue, setSearchValue] = useState()
    const [isVisibleCreate, setIsVisibleCreate] = useState(false)
    const [isVisibleEdit, setIsVisibleEdit] = useState(false)
    const [userInfo, setUserInfo] = useState([])

    const dispatch = useDispatch();
    const fetchingGetFixers = useSelector((state) => state.AdminReducer.fetchingGetFixers);
    const fetchingCreateFixer = useSelector(state => state.AdminReducer.fetchingCreateFixer);
    const fetchingEditFixer = useSelector(state => state.AdminReducer.fetchingEditFixer);

    const fixers = useSelector((state) => state.AdminReducer.fixers);

    useEffect(() => {
        dispatch({ type: AdminActions.GET_FIXERS_REQUEST, page, search });
    }, [dispatch, page, search]);

    const onChangePage = (value) => {
        setPage(value)
    }

    const onChangeStatus = (fixer) => {
        dispatch({ type: AdminActions.EDIT_FIXER_STATUS_REQUEST, fixer });
    }

    const clearFilters = () => {
        setPage(1);
        setSearch(null);
        setSearchValue(null)
    }

    const handleOnSearchChange = (e) => {
        if (!e.target.value) {
            setSearch(null)
            setSearchValue(null)
        } else {
            setSearchValue(e.target.value)
        }
    }

    return (
        <>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Search
                        value={searchValue}
                        onChange={e => handleOnSearchChange(e)}
                        placeholder="Buscar Fixer"
                        onSearch={value => setSearch(value)}
                        allowClear
                    />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                    <Button style={{ width: '100%' }} type="primary" className="login-btn"
                        onClick={() => setIsVisibleCreate(!isVisibleCreate)}
                    >Nuevo Fixer</Button>
                </Col>
            </Row>
            <br />
            <Card title={
                <div className="header-list">FIXERS</div>}>
                <List
                    itemLayout="horizontal"
                    loading={fetchingGetFixers || fetchingCreateFixer || fetchingEditFixer}
                    locale={{ emptyText: 'No hay fixers registrados' }}
                    dataSource={fixers}
                    renderItem={item => (
                        <List.Item
                            key={`fixer-0${item.userID}`}
                            actions={[
                                <span
                                    onClick={() => {
                                        setIsVisibleEdit(!isVisibleEdit);
                                        setUserInfo(item);
                                    }}
                                ><EditTwoTone className="icon" /></span>,
                                <Tooltip title="Cambiar estatus de usuario">
                                    <Switch checked={item.active} size='small' onChange={() => onChangeStatus(item)} />
                                </Tooltip>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.fullName}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <br />
            <Pagination current={page} total={fixers.length} onChange={onChangePage} />
            <NewFixer clearFilters={clearFilters} isVisibleCreate={isVisibleCreate} setIsVisibleCreate={setIsVisibleCreate} />
            <br />
            <EditFixer clearFilters={clearFilters} isVisibleEdit={isVisibleEdit} setIsVisibleEdit={setIsVisibleEdit} userInfo={userInfo} />
        </>
    )
}

export default FixersList