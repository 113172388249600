import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Row, Col, Spin } from 'antd';
import { RightOutlined } from '@ant-design/icons';

function DashboardMainMenu() {
    const dashboardCategories = useSelector(state => state.DashboardReducer.dashboardCategories)
    const fetchingDashboardCategories = useSelector(state => state.DashboardReducer.fetchingDashboardCategories)
    const isAuthenticated = useSelector(state => state.LoginReducer.isAuthenticated)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "GET_DASHBOARD_CATEGORIES_REQUEST" })
    }, [dispatch, isAuthenticated])

    const onChangeVistaDashboard = (dashboardCategoryInfo) => {
        dispatch({ type: "CHANGE_VISTA_DASHBOARD", dashboardCategoryInfo })
    }
    const onShowMenuDrawer = (showMenuDrawer) => {
        dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer });
    }
    const clickCategory = (e) => {
        onShowMenuDrawer(false);
        onChangeVistaDashboard(e);
    }
    const handleClick = (vistaLogin) => {
        dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin })
        dispatch({ type: "SHOW_MENU_DRAWER", showMenuDrawer: false });
    }

    return (
        <>
            {!isAuthenticated &&
                <div className='main-menu-btns-container'>
                    <Row justify='space-around'>
                        <Col><Button onClick={() => handleClick('login')}><Link to="/login">Ingreso</Link></Button></Col>
                        <Col><Button onClick={() => handleClick('sign-up')}><Link to="/login">Registro</Link></Button></Col>
                    </Row>
                </div>
            }
            <div className='main-menu-categories-container'>
                <h3>CATEGORIAS</h3>
                <Spin spinning={fetchingDashboardCategories}>
                    {dashboardCategories.map(category =>
                        <Link key={'menu-' + category.key} to={`/category?id=${category.id}`}>
                            <Button type='link' onClick={() => clickCategory(category)}>
                                <Row justify='space-between'>
                                    <Col><h3><b>{category.name}</b></h3></Col>
                                    <Col><RightOutlined className='icon' /></Col>
                                </Row>
                            </Button>
                        </Link>
                    )}
                </Spin>
            </div>
            <div className="main-menu-others-container">
                <Row>
                    <Col span={24}><Button onClick={() => onShowMenuDrawer(false)} type='link'><Link to="/nosotros">¿Quiénes somos?</Link></Button></Col>
                    <Col span={24}><Button onClick={() => onShowMenuDrawer(false)} type='link'><Link to="/">¿Quieres ser parte del equipo?</Link></Button></Col>
                    <Col span={24}><Button onClick={() => onShowMenuDrawer(false)} type='link'><Link to="/blog">Blog</Link></Button></Col>
                    <Col span={24}><Button onClick={() => onShowMenuDrawer(false)} type='link'><Link to="/terminos-condiciones">Términos y condiciones</Link></Button></Col>
                    <Col span={24}><Button onClick={() => onShowMenuDrawer(false)} type='link'><Link to="/politica-privacidad">Políticas de Privacidad</Link></Button></Col>
                </Row>
            </div>
        </>
    )
}

export default DashboardMainMenu