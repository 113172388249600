import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button, Spin, List, Empty } from 'antd';
import { LeftOutlined, RightOutlined, LoadingOutlined } from '@ant-design/icons';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

import MetaDecorator from '../Util/MetaDecorator';
import DashboardHeader from '../../Components/Headers/DashboardHeader';
import DashboardFooter from '../../Components/Footer/DashboardFooter';
import DashboardDrawersModals from '../Dashboard/DashboardDrawersModals';
import ServiceItem from './ServiceItem';
import '../../Stylesheets/Services/ServiceByCategorie.css';

export class ServiceByCategory extends Component {
    state = {
        width: 0,
        height: 0,
        currentPage: 0,
        id: null
    };

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        let categoryId = parseInt(urlParams.get('id'));

        this.setState({
            id: categoryId
        })

        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        this.props.onGetBrands(categoryId);
        this.props.onGetServices(categoryId, this.state.currentPage);
    }

    componentDidUpdate(previousProps, previousState, snapShot) {
        let urlParams = new URLSearchParams(window.location.search);
        let prev = parseInt(previousProps.location.search.split('=')[1])
        let current = parseInt(urlParams.get('id'));

        if (prev !== current) {
            this.setState({
                id: current
            })
            this.props.onGetBrands(current);
            this.props.onGetServices(current, this.state.currentPage);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        const { servicesBycategoryId, brandsBycategoryId, fetchingServicesBycategoryId, fetchingBrandsBycategoryId, totalServices, onGetServices } = this.props;

        const categoryInfo = JSON.parse(sessionStorage.getItem('dashboardCategoryInfo'))

        const responsiveBrands = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5
            },
            laptop: {
                breakpoint: { max: 1440, min: 1024 },
                items: 5
            },
            tablet: {
                breakpoint: { max: 1024, min: 768 },
                items: 4.25
            },
            mobileL: {
                breakpoint: { max: 425, min: 375 },
                items: 2.25
            },
            mobileM: {
                breakpoint: { max: 375, min: 320 },
                items: 2
            },
            mobileS: {
                breakpoint: { max: 320, min: 0 },
                items: 3
            }
        };

        const responsiveServices = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 3
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            laptop: {
                breakpoint: { max: 1440, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 768 },
                items: 2.25
            },
            mobileL: {
                breakpoint: { max: 425, min: 375 },
                items: 2.25
            },
            mobileM: {
                breakpoint: { max: 375, min: 320 },
                items: 2
            },
            mobileS: {
                breakpoint: { max: 320, min: 0 },
                items: 1.75
            }
        };

        const CustomButtonGroupAsArrows = ({ next, previous, goToSlide, ...rest }) => {
            const { carouselState: { currentSlide } } = rest;
            return (
                <div className="carousel-button-group">
                    <LeftOutlined className={currentSlide === 0 ? 'disable' : 'arrow-icon'} onClick={() => previous()} />
                    <RightOutlined className='arrow-icon' onClick={() => next()} />
                </div>
            );
        };

        const handleClick = () => {
            let page = this.state.currentPage;
            page++;
            this.setState({ currentPage: page })
            onGetServices(this.state.id, page);
        }

        const loadMore = <div className='load-more-services'><Button onClick={handleClick}>Ver más</Button></div>

        return (
            <>
                <MetaDecorator title={categoryInfo.name} description="Fixers. Todo tipo de mantenimiento, instalación y reparación." img={categoryInfo.img} />
                <DashboardHeader />
                <div className='service-by-categorie-container'>
                    <Spin spinning={fetchingBrandsBycategoryId || fetchingServicesBycategoryId} indicator={<LoadingOutlined style={{ fontSize: 50 }} />}>
                        <div className='service-by-categorie-title'>
                            <h2>{categoryInfo.name}</h2>
                        </div>
                        {brandsBycategoryId.length !== 0 ?
                            <Row justify='space-around' align='middle' className="service-by-categorie-brands">
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }}><h2>Marcas</h2></Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                    <Carousel
                                        autoPlay={true}
                                        className='brands-carousel'
                                        keyboard={false}
                                        responsive={responsiveBrands}
                                        infinite={true}
                                        arrows={false}
                                        deviceType={this.props.deviceType}
                                    >
                                        {brandsBycategoryId.map((brand) =>
                                            <div key={brand.key}>
                                                <img src={brand.img} alt="brand-img" />
                                            </div>
                                        )}
                                    </Carousel>
                                </Col>
                            </Row> :
                            <Row align='middle' className='service-by-categorie-brands'><Col span={24}><Empty description='No hay ninguna marca vinculada a esta categoría' /></Col></Row>
                        }
                        {servicesBycategoryId.length !== 0 ?
                            <div className="service-by-categorie-services">
                                <h2><img src={categoryInfo.img} alt="categorie-logo" /> Servicios</h2>
                                {this.state.width > 425 ? (
                                    <Carousel
                                        autoPlay={false}
                                        className='services-carousel'
                                        keyboard={false}
                                        responsive={responsiveServices}
                                        infinite={false}
                                        arrows={false}
                                        deviceType={this.props.deviceType}
                                        customButtonGroup={this.state.width >= 768 ? (<CustomButtonGroupAsArrows />) : (<></>)}
                                        renderButtonGroupOutside={this.state.width >= 768 ? true : false}
                                    >
                                        {servicesBycategoryId.map((service) =>
                                            <ServiceItem
                                                key={service.id + service.key}
                                                image={service.img ? service.img : categoryInfo.img}
                                                title={service.title}
                                                description={service.description}
                                                price={service.price}
                                                item={service}
                                            />
                                        )}
                                        {loadMore}
                                    </Carousel>
                                ) : (
                                    <List
                                        className='services-carousel'
                                        itemLayout="vertical"
                                        size='large'
                                        loadMore={servicesBycategoryId.length !== totalServices && loadMore}
                                        loading={fetchingServicesBycategoryId}
                                        dataSource={servicesBycategoryId}
                                        renderItem={service => (
                                            <List.Item key={service.id + service.key}>
                                                <ServiceItem
                                                    key={service.id + service.key}
                                                    image={service.img ? service.img : categoryInfo.img}
                                                    title={service.title}
                                                    description={service.description}
                                                    price={service.price}
                                                    item={service}
                                                />
                                            </List.Item>
                                        )}
                                    />)
                                }
                            </div> :
                            <Row align='middle' className='service-by-categorie-brands'><Col span={24}><Empty description='No hay ningún servicio vinculado a esta categoría' /></Col></Row>
                        }
                    </Spin>
                </div>
                <DashboardFooter />
                <DashboardDrawersModals />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        servicesBycategoryId: state.DashboardReducer.servicesBycategoryId,
        totalServices: state.DashboardReducer.totalServices,
        brandsBycategoryId: state.DashboardReducer.brandsBycategoryId,
        fetchingBrandsBycategoryId: state.DashboardReducer.fetchingBrandsBycategoryId,
        fetchingServicesBycategoryId: state.DashboardReducer.fetchingServicesBycategoryId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeVistaDashboard: (vistaDashboard) => {
            dispatch({ type: "CHANGE_VISTA_DASHBOARD", vistaDashboard })
        },
        onGetServices: (categoryId, page) => {
            dispatch({ type: "GET_SERVICES_BY_CATEGORIE_ID_REQUEST", categoryId, page })
        },
        onGetBrands: (categoryId) => {
            dispatch({ type: "GET_BRANDS_BY_CATEGORIE_ID_REQUEST", categoryId })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceByCategory)