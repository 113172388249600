import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Row, Col, Space, Collapse } from 'antd'
import { FacebookFilled, InstagramFilled, WhatsAppOutlined, CopyrightOutlined, DownOutlined } from '@ant-design/icons';

import { useWindowSize } from '../../Hooks/useWindowSize';
import fixersLogoBlanco from '../../Assets/Logos/logo_fixers_blanco.png'
import '../../Stylesheets/Dashboard/DashboardFooter.css'

const { Panel } = Collapse;

function DashboardFooter() {
    const { width } = useWindowSize();

    const visitsCounter = sessionStorage.getItem('visits')

    const redesSociales = [
        { "redSocial": "Facebook", "activo": true, "url": 'https://www.facebook.com/Fixers.pro?mibextid=LQQJ4d' },
        { "redSocial": "Instagram", "activo": true, "url": 'https://instagram.com/fixers.pro?igshid=YmMyMTA2M2Y=' },
        { "redSocial": "WhatsApp", "activo": true, "url": "https://wa.me/+525535524831" },
    ]

    useEffect(() => {
    }, [visitsCounter])

    return (
        <div className='dashboard-footer'>
            {width >= 576 ? (
                <>
                    <Row justify="space-between" align='middle' className='top-row contaier'>
                        <Col span={6} className='col'>Sobre Fixers</Col>
                        <Col span={6} className='col'><Link to="/nosotros">¿Quiénes somos?</Link></Col>
                        <Col span={6} className='col'>¿Quieres ser parte del equipo?</Col>
                        <Col span={6} className='col'><Link to="/blog">Blog</Link></Col>
                    </Row>
                    <Row justify="space-around" align='middle' className='middle-row contaier'>
                        <Col span={2} className='col'><img src={fixersLogoBlanco} alt="logoBN" className='logo-footer-dashboard' /></Col>
                        <Col span={6} className='col'><Link to="/terminos-condiciones">Términos y condiciones</Link></Col>
                        <Col span={6} className='col'><Link to="/politica-privacidad">Políticas de Privacidad</Link></Col>
                        <Col span={6} className='col'>
                            <Space direction='vertical' size='small'>
                                <Space direction='horizontal' size='middle' className='social-media-footer-dashboard'>
                                    {redesSociales.map(item =>
                                        <div key={item.redSocial}>
                                            {item.redSocial === 'Facebook' && item.activo === true &&
                                                <a href={item.url} rel="noreferrer" target="_blank"><FacebookFilled className="icon-footer-dashboard" /></a>
                                            }
                                            {item.redSocial === 'Instagram' && item.activo === true &&
                                                <a href={item.url} rel="noreferrer" target="_blank"><InstagramFilled className="icon-footer-dashboard" /></a>
                                            }
                                            {item.redSocial === 'WhatsApp' && item.activo === true &&
                                                <a href={item.url} rel="noreferrer" target="_blank"><WhatsAppOutlined className="icon-footer-dashboard" /></a>
                                            }
                                        </div>
                                    )}
                                </Space>
                                <span><CopyrightOutlined /> 2022 Fixers All rights reserved.</span>
                                <span style={{ fontSize: 'small' }}>This site has been visited {visitsCounter} times.</span>
                            </Space>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Collapse
                        ghost
                        expandIconPosition="end"
                        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                        className='footer-collapse'
                    >
                        <Panel
                            header={<span className='top-row'>Sobre Fixers</span>}
                            key="1"
                        >
                            <Row align='middle' className='middle-row'>
                                <Col span={24} className='col'><Link to="/nosotros">¿Quiénes somos?</Link></Col>
                                <Col span={24} className='col'>¿Quieres ser parte del equipo?</Col>
                                <Col span={24} className='col'><Link to="/blog">Blog</Link></Col>
                                <Col span={24} className='col'><Link to="/terminos-condiciones">Términos y condiciones</Link></Col>
                                <Col span={24} className='col'><Link to="/politica-privacidad">Políticas de Privacidad</Link></Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <Row justify="space-around" align='middle' className='bottom-row'>
                        <Col span={20} className='col'>
                            <Space direction='vertical' size='small'>
                                <Space direction='horizontal' size='middle' className='social-media-footer-dashboard'>
                                    {redesSociales.map(item =>
                                        <div key={item.redSocial}>
                                            {item.redSocial === 'Facebook' && item.activo === true &&
                                                <a href={item.url} rel="noreferrer" target="_blank"><FacebookFilled className="icon-footer-dashboard" /></a>
                                            }
                                            {item.redSocial === 'Instagram' && item.activo === true &&
                                                <a href={item.url} rel="noreferrer" target="_blank"><InstagramFilled className="icon-footer-dashboard" /></a>
                                            }
                                            {item.redSocial === 'WhatsApp' && item.activo === true &&
                                                <a href={item.url} rel="noreferrer" target="_blank"><WhatsAppOutlined className="icon-footer-dashboard" /></a>
                                            }
                                        </div>
                                    )}
                                </Space>
                                <span><CopyrightOutlined /> 2022 Fixers All rights reserved.</span>
                                <span style={{ fontSize: 'small' }}>This site has been visited {visitsCounter} times.</span>
                            </Space>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default DashboardFooter