import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Button, Form, Input, Divider, Row, Col, DatePicker } from 'antd';
import { MailOutlined, LockOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'
import { gapi } from 'gapi-script';

import GoogleButton from './Buttons/GoogleButton';
import FacebookButton from './Buttons/FacebookButton';
import loginImg from '../../Assets/Login/FIXERS_.png'
import '../../Stylesheets/Login/Login.css';

export class SignUpForm extends Component {
    state = {
        isEnabled: false
    }

    componentDidMount() {
        function start() {
            gapi.client.init({
                clientId: "722758664014-dmlfjoui2pu12790er1kiauibonnsp49.apps.googleusercontent.com",
                scope: ""
            })
        }

        gapi.load('client:auth2', start)
    }

    render() {
        const { onChangeVistaLogin, onRequestSignUpClient, fetchingSignup, successSignUp } = this.props;

        if (successSignUp) {
            onChangeVistaLogin();
        }

        const onFinish = (form) => {
            onRequestSignUpClient(form);
        };

        const handleOnChange = () => {
            this.setState({
                isEnabled: true
            })
        }

        const disabledDate = (current) => {
            return current && current > moment().subtract(18, 'years');
        };

        return (
            <>
                <Row className='login-container' justify='space-around'>
                    <Col xs={0} sm={0} md={0} lg={8} xl={10}>
                        <img src={loginImg} alt="login-img" />
                    </Col>
                    <Col className='login-form-container' flex="auto">
                        <h2>Regístrate</h2>
                        <div className='login-buttons'>
                            <GoogleButton action={onRequestSignUpClient} actionType={'sign-up'} />
                            <FacebookButton action={onRequestSignUpClient} actionType={'sign-up'} />
                        </div>

                        <div style={{ width: '100%', maxWidth: '320px', margin: '0 auto' }}>
                            <Divider><span style={{ color: '#e6ebf1' }}>o</span></Divider>
                        </div>

                        <Form
                            name="signup-form"
                            onFinish={onFinish}
                            initialValues={{ remember: false }}
                            autoComplete="off"
                            id="signup-form"
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Escribe tu correo electrónico' }]}
                            >
                                <Input onChange={handleOnChange} allowClear prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Correo electrónico" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Escribe tu contraseña' }]}
                            >
                                <Input.Password allowClear prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Contraseña" autoComplete="on" />
                            </Form.Item>

                            <Form.Item
                                name={['name', 'firstName']}
                                rules={[{ required: true, message: "Por favor favor ingresa tu nombre" }]}
                            >
                                <Input onChange={handleOnChange} allowClear prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                            </Form.Item>

                            <Form.Item
                                name={['name', 'lastName']}
                                rules={[{ required: true, message: "Por favor favor ingresa tu apellido" }]}
                            >
                                <Input onChange={handleOnChange} allowClear prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Apellido" />
                            </Form.Item>

                            <Form.Item
                                name="birthday"
                                rules={[{ required: true, message: "Por favor favor ingresa tu fecha de nacimiento" }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    placeholder="Fecha de nacimiento (Mayor a 18 años)"
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                rules={[{ required: true, message: "Por favor favor ingresa tu número de teléfono" }]}
                            >
                                <Input onChange={handleOnChange} allowClear prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} minLength={10} maxLength={10} placeholder="Número de teléfono" />
                            </Form.Item>

                        </Form>

                        <Row style={{ width: '100%', maxWidth: '320px', margin: '0 auto' }} justify="space-between" align='middle'>
                            <Col span={24}>
                                <Button
                                    loading={fetchingSignup}
                                    className={this.state.isEnabled ? 'submit-btn' : 'disabled-btn'} form='signup-form' htmlType='submit' disabled={!this.state.isEnabled}>Regístrarme</Button>
                            </Col>
                        </Row>

                        <br />
                        <p>¿Ya tienes una cuenta? <Link to="/login" onClick={onChangeVistaLogin} >Inicia sesión aquí</Link></p>
                        <p>Al registrarte estás aceptando los <Link to="/terminos-condiciones">Términos y Condiciones y Políticas de Privacidad</Link></p>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        vistaLogin: state.LoginReducer.vistaLogin,
        fetchingSignup: state.LoginReducer.fetchingSignup,
        successSignUp: state.LoginReducer.successSignUp,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeVistaLogin: () => {
            dispatch({ type: "CHANGE_VISTA_LOGIN", vistaLogin: 'login' })
        },
        onRequestSignUpClient: (form) => {
            dispatch({ type: "SIGN_UP_CLIENT_REQUEST", form });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm)